import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { DataApiService } from '../../../services/data-api.service';
import { NgForm } from '@angular/forms';
import { StateInterface } from '../../../models/state';
import { EvaluationInterface } from '../../../models/evaluation';
import { UserInterface } from '../../../models/user';
import { AuthService } from '../../../services/auth.service';
import {formatDate} from '@angular/common';
import { DataUserInterface } from '../../../models/dataUser';
import Swal from 'sweetalert2';
import { MensajesService } from '../../../services/mensajes.service';


@Component({
  selector: 'app-modal-task-act',
  templateUrl: './modal-task-act.component.html',
  styleUrls: ['./modal-task-act.component.css']
})
export class ModalTaskActComponent implements OnInit {

  constructor(public dataApi: DataApiService, public authService: AuthService, private msj: MensajesService) { }
  @ViewChild('btnClose', { static: false}) btnClose: ElementRef;
  @Input() userUid: string; // para pasar al modal el userid logueado
  @Input() isUpdate: boolean;
  @Input() customersId: string;
  @Input() customer: string;
  @Input() familyId: string;
  @Input() cod: string;
  @Input() service: string;
  @Input() fechainicial: string;
  @Input() fechafinal: string;
  @Input() groupId: string;
  @Input() group: string;
  @Input() subgroupId: string;
  @Input() subgroup: string;
  @Input() emailCust: string;
  @Input() dosageId: string;

  @Input() emailCC: string;
  @Input() nameCC: string;
  @Input() razon: string;

  public states: StateInterface[];
  public evaluations: EvaluationInterface[];
  public users: UserInterface[];
  public dataUsers: DataUserInterface[];
  public stateId: string;
  public estado: string = '';
  public nivelState: string;
  public evaluationId: string = '';
  public evaluacion: string = '';
  public userId: string;
  public isAdmin: any = null;
  public isError = false;
  public activo: boolean = true;

  public finalizado: string = ''; // formatDate(new Date(), 'yyyy/MM/dd, h:mm:ss a', 'en');
  public requestId: string = '0';

  public userUidName: string = '';
  public userUidEmail: string = '';
  public nivel: string = '2'; // nivel del funcionario

  ngOnInit() {
    this.getListState();
    this.getListUsers(this.nivel);
    this.getCurrentUser();
  }


  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
            this.isAdmin = Object.assign({}, userRole.roles).hasOwnProperty('admin');
        });
      }
    });
  }

  getListUsers(id: string) {
    this.dataApi.getNivelDataUserUid(id).subscribe(dataUsers => {
      if (dataUsers.length > 0) {
        this.dataUsers = dataUsers;
      }
    });
  }

  // carga el nombre del funcionario
  getFuncionario(id: string) {
    if (id != null) {
      this.dataApi.getAllDataUserUid(id).subscribe( data => {
        data.forEach(dat => {
          this.userUidName = dat.name;
        });
      });
      this.authService.getOneUser(id).subscribe( users => {
        this.userUidEmail = users.email;
        this.userId = users.id;
      });
    }
  }

  getListState() {
    this.dataApi.getSelectState().subscribe( states => {
      if (states) {
        this.states = states;
      }
    });
  }

  // A : Busco el estado de la tarea mas su nivel
  getOneState(idState: string) {
    if (idState !=  null) {
      this.dataApi.getOneState(idState).subscribe( state => {
        if (state) {
          this.estado = state.name;
          this.nivelState = state.nivelState;
          if (this.isUpdate === false) {
            if (this.nivelState === '1') {
              this.getEvaluationTask(this.nivelState);
            } else {
              this.onisError();
              this.evaluationId =  '';
              this.evaluacion = '';
            }
          }
        }
      });

    }
  }

  // C : BUSCA EL SEGUN EL NIVEL DEL ESTADO SU EVALUACION
  getEvaluationTask(nivel: string) {
    this.dataApi.getSelectEvaluationTask(nivel).subscribe( evaluation => {
      evaluation.forEach( eva => {
        this.evaluationId =  eva.id;
        this.evaluacion = eva.name;
      });
    });
  }

  resetForm(formDosa: NgForm) {
    formDosa.resetForm(); // para limpiar el formulario
  }

  onisError(): void {
    this.isError = true;
    setTimeout(() => {
      this.isError = false;
    }, 3000);
  }

  onSaveDosa(formDosa: NgForm): void {
      formDosa.value.userUid = this.userId; // funcionario
      formDosa.value.adminId = this.userUid; // Jefe de funcionario
      formDosa.value.userUidName = this.userUidName;
      formDosa.value.userUidEmail = this.userUidEmail;

      formDosa.value.customersId = this.customersId;
      formDosa.value.customer = this.customer;
      formDosa.value.emailCust = this.emailCust;

      formDosa.value.emailCC = this.emailCC;
      formDosa.value.nameCC = this.nameCC;
      formDosa.value.razon = this.razon;

      formDosa.value.groupId = this.groupId;
      formDosa.value.group = this.group;
      formDosa.value.subgroupId = this.subgroupId;
      formDosa.value.subgroup = this.subgroup;
      formDosa.value.familyId = this.familyId;
      formDosa.value.cod = this.cod;
      formDosa.value.service = this.service;
      formDosa.value.fechainicial = this.fechainicial;
      formDosa.value.fechafinal = this.fechafinal;
      this.stateId = 'O0k77hWryKKIbCPHdBn6';
      this.estado = 'En Proceso';
      this.evaluationId = '71U4A72QmvjK4bbAA9rl';
      this.evaluacion = 'Dentro del Rango';

      formDosa.value.stateId = this.stateId; // nombre del estado
      formDosa.value.estado = this.estado;
      formDosa.value.evaluationId = this.evaluationId;
      formDosa.value.evaluacion = this.evaluacion;

      this.requestId = '0';
      formDosa.value.requestId = this.requestId;
      formDosa.value.dosageId = this.dosageId;
      formDosa.value.activo = this.activo;
      formDosa.value.finalizado = this.finalizado;
      // console.log('DATOS: ', formDosa.value);
      this.dataApi.addTask(formDosa.value);
      this.msj.mensajeCorrecto('Agrego', 'Se agrego correctamente');
      formDosa.resetForm(); // para limpiar el formulario
      this.btnClose.nativeElement.click(); // cierra el modal
  }

}
