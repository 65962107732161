import { Component, OnInit } from '@angular/core';
import { DataApiService } from '../../../services/data-api.service';
import { EvaluationInterface } from '../../../models/evaluation';
import { NgForm } from '@angular/forms';
// Para ver los roles de ususarioa
import { AuthService } from '../../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserInterface } from '../../../models/user';

@Component({
  selector: 'app-list-evaluation',
  templateUrl: './list-evaluation.component.html',
  styleUrls: ['./list-evaluation.component.css']
})
export class ListEvaluationComponent implements OnInit {

  constructor(private dataApi: DataApiService, private authService: AuthService) { }
  public evaluations: EvaluationInterface[];
  public isAdmin: any = null;
  public userUid: string;
  public isError = false;
  pageActual: number = 1;

  ngOnInit() {
    this.getListEvaluation();
    this.getCurrentUser();
  }

  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
            this.isAdmin = Object.assign({}, userRole.roles).hasOwnProperty('admin');
        });
      }
    });
  }

  getListEvaluation() {
    this.dataApi.getAllEvaluation().subscribe( evaluations => {
      // console.log('STATE: ', states);
      this.evaluations = evaluations;
    });
  }

  onDeleteEvaluation(idEva: string) {
    const confirmacion = confirm('Estás seguro ?');
    if (confirmacion) {
      this.dataApi.getAllTasksEva(idEva).subscribe( evaluations => {
        if (evaluations.length > 0) {
          this.onisError();
        } else {
          this.dataApi.deleteEvaluation(idEva);
        }
      });
    }
  }

  // carga el update al modal
  onPreUpdateEvaluation(evaluation: EvaluationInterface) {
    // console.log('GROUP: ', group);
    this.dataApi.selectEvaluation = Object.assign({}, evaluation);
  }


  onisError(): void {
    this.isError = true;
    setTimeout(() => {
      this.isError = false;
    }, 3000);
  }


}
