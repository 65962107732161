import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { DataApiService } from '../../../services/data-api.service';
import { EvaluationInterface } from '../../../models/evaluation';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { MensajesService } from '../../../services/mensajes.service';


@Component({
  selector: 'app-modal-evaluation',
  templateUrl: './modal-evaluation.component.html',
  styleUrls: ['./modal-evaluation.component.css']
})
export class ModalEvaluationComponent implements OnInit {

  constructor(public dataApi: DataApiService,
              private msj: MensajesService) { }
  @ViewChild('btnClose', { static: false}) btnClose: ElementRef;
  @Input() userUid: string; // para pasar al modal el userid logueado

  ngOnInit() {
  }

  resetForm(formEva: NgForm) {
    formEva.resetForm(); // para limpiar el formulario
  }


  onSaveEvaluation(evaForm: NgForm): void {
    // new
    if (evaForm.value.id == null) {
      evaForm.value.userUid = this.userUid; // guarda en la coleecion de firebase
      this.dataApi.addEvaluation(evaForm.value);
      this.msj.mensajeCorrecto('Agrego', 'Se agrego correctamente');
    } else {
    // update
    this.dataApi.updateEvaluation(evaForm.value);
    this.msj.mensajeAdvertencia('Edito', 'Se edito correctamente');
    }
    evaForm.resetForm();
    this.btnClose.nativeElement.click(); // cierra el modal
  }

}
