import { Component, OnInit } from '@angular/core';
import { DataApiService } from '../../services/data-api.service';
import { BlogInterface } from '../../models/blog';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  message;
  constructor(private dataApi: DataApiService) { }
  public blogs: BlogInterface[];
  ngOnInit() {
    this.getAllBlog();
  }

  getAllBlog() {
    this.dataApi.getAllBlog().subscribe( blogs => {
       if (blogs) {
         this.blogs = blogs;
       }
    });
   }


}
