import { Component, OnInit } from '@angular/core';
import { DataApiService } from '../../../services/data-api.service';
import { CustomersInterface } from '../../../models/customers';
import { AuthService } from '../../../services/auth.service';
import { TaskInterface } from '../../../models/task';
import * as printJS from 'print-js';
import * as moment from 'moment';
moment.locale('es')
import 'moment/locale/bo';


@Component({
  selector: 'app-rpt-task-customers',
  templateUrl: './rpt-task-customers.component.html',
  styleUrls: ['./rpt-task-customers.component.css']
})
export class RptTaskCustomersComponent implements OnInit {

  constructor(public dataApi: DataApiService, private authService: AuthService) { }
  public tasks: TaskInterface[];
  public customers: CustomersInterface[];
  public cust: [];
  public isAdmin: any = null;
  public userUid: string;
  public customersId: string = '';
  public customer: string;
  public phone: string;
  public company: string;
  public isActive: boolean = false;
  public isRadioA: boolean = false;
  public isRadioB: boolean = false;
  public isRadioC: boolean = false;
  public fechainicial: string = '';
  public fechafinal: string = '';
  pageActual: number = 1;

  public isError = false;

  ngOnInit() {
    this.getCurrentUser();
    this.getListCustomer();
    this.getRadioA();
  }

  onisError(): void {
    this.isError = true;
    setTimeout(() => {
      this.isError = false;
    }, 3000);
  }

  fechaInicial(fechaIni: string) {
    if (fechaIni != null) {
      this.fechainicial = fechaIni;
    }
  }

  fechaFinal(fechaFin: string) {
    if (fechaFin != null) {
      this.fechafinal = fechaFin;
    }

  }

  getRadioA() {
      this.isRadioA =  true;
      this.isRadioB =  false;
      this.isRadioC =  false;
  }

  getRadioB() {
      this.isRadioB =  true;
      this.isRadioA =  false;
      this.isRadioC =  false;
  }

  getRadioC() {
      this.isRadioC =  true;
      this.isRadioB =  false;
      this.isRadioA =  false;
  }

  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
            this.isAdmin = Object.assign({}, userRole.roles).hasOwnProperty('admin');

        });
      }
    });
  }


  getListCustomer() {
    this.dataApi.getSelectCustomer().subscribe( customers => {
      this.customers = customers;
    });
  }

  getOneCust(idCustomers: string) {
    if (idCustomers != null) {
      this.customersId = idCustomers;
    }
  }

  getOneCustomer() {
     if (this.customersId != null) {
      if (this.fechainicial != null && this.fechafinal != null) {
        if (moment(this.fechainicial).format('L') > moment(this.fechafinal).format('L')) {
          this.onisError();
          return;
        }
        if (this.isRadioA) {
          this.dataApi.getAllTasksCusRep(this.customersId, this.fechainicial, this.fechafinal).subscribe(tasks => {
            if (tasks.length > 0) {
              this.isActive = true;
              this.tasks = tasks;
              this.oneCustomer(this.customersId);
            } else {
              this.isActive = false;
              this.tasks = tasks;
            }
          });
        }
        if (this.isRadioB) {
          this.dataApi.getAllTasksCusB(this.customersId, this.fechainicial, this.fechafinal, this.isRadioB).subscribe(tasks => {
            if (tasks.length > 0) {
              this.isActive = true;
              this.tasks = tasks;
              this.oneCustomer(this.customersId);
            } else {
              this.isActive = false;
              this.tasks = tasks;
            }
          });
        }
        if (this.isRadioC) {
          this.isRadioC = false;
          this.dataApi.getAllTasksCusB(this.customersId, this.fechainicial, this.fechafinal, this.isRadioC).subscribe(tasks => {
            if (tasks.length > 0) {
              this.isActive = true;
              this.tasks = tasks;
              this.oneCustomer(this.customersId);
            } else {
              this.isActive = false;
              this.tasks = tasks;
            }
          });
        }
      }
     }
  }

  oneCustomer(id: string) {
    this.dataApi.getOneCustomer(id).subscribe(cust => {
        this.customer = cust.company;
        this.phone = cust.mobil;
        this.company = cust.company;
    });
  }

  downloadPdf() {
    printJS({printable: this.tasks, properties: [
      {field: 'subgroup', displayName: 'Servicio'},
      {field: 'service', displayName: 'Código Descripción'},
      {field: 'estado', displayName: 'Estado'},
      {field: 'evaluacion', displayName: 'Evaluación'},
      {field: 'observacion', displayName: 'Observación'}
    ], type: 'json',
       documentTitle: 'Control de Trámites de ServiContable13 Srl.',
       header: '<h4 class="custom-h4">Trámite del Cliente: </h4>'
               + ' ' + this.customer + '<br>' + '<b>Empresa:</b> ' + this.company + '<br>'
               + '<b>Mobil:</b> ' + this.phone + '<br>'
               + '<b>Fecha Desde:</b> ' + this.fechainicial + '<b>Hasta: </b>' + this.fechafinal + '<br>',
       headerStyle: 'font-weight: 300;',
       font: 'Century Gothic',
       style: '.custom-h4 { color: blue; }',
       gridHeaderStyle: 'font-weight: bold; padding: 5px; border: 1px solid #dddddd;'});
  }


}
