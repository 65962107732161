import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataApiService } from '../../../services/data-api.service';
import { DataUserInterface } from '../../../models/dataUser';
import { AuthService } from '../../../services/auth.service';
import {formatDate} from '@angular/common';
import Swal from 'sweetalert2';
import { MensajesService } from '../../../services/mensajes.service';


@Component({
  selector: 'app-modal-data-user-det',
  templateUrl: './modal-data-user-det.component.html',
  styleUrls: ['./modal-data-user-det.component.css']
})
export class ModalDataUserDetComponent implements OnInit {

  constructor(public dataApi: DataApiService,
              public authService: AuthService,
              private msj: MensajesService) { }
  @ViewChild('btnClose', { static: false}) btnClose: ElementRef;
  @Input() userUid: string;
  @Input() email: string;
  @Input() isUpdate: boolean;
  @Input() isMovimiento: boolean;

  public dataUsers: DataUserInterface[];
  public userUidName: string = '';
  public isAdmin: any = null;
  public usersId: string;
  public datauserNam: string;
  public datauserId: string;
  public datestart: string = formatDate(new Date(), 'dd-MM-yyyy', 'en');
  public confirFuncionario: boolean = false;

  ngOnInit() {
    this.getListUsers();
  }

  getListUsers() {
    this.dataApi.getAllDataUser().subscribe( dataUsers => {
      if (dataUsers.length > 0 ) {
        this.dataUsers = dataUsers;
      }
    });
  }

    // buscar el nombre de funcionario y vcarga el select de cliente si tiene tarea
  getOneDataUserDet(iduserUid: string) {
      this.usersId = iduserUid;
      if (this.usersId != null) {
        this.dataApi.getAllDataUserUid(this.usersId).subscribe(dataUsers => {
          dataUsers.forEach(data => {
            this.confirFuncionario = true;
            this.datauserNam = data.name;
            this.datauserId = data.id;
          });
        });
      }
  }

  onSaveDataUserDet(formDataUserDet: NgForm): void {
    if (formDataUserDet.value.id == null) {
      // new
      formDataUserDet.value.userUid = this.userUid;
      formDataUserDet.value.datauserId = this.datauserId;
      formDataUserDet.value.datauserNam = this.datauserNam;
      formDataUserDet.value.datestart = this.datestart;
      this.dataApi.addDataUserDet(formDataUserDet.value);
      this.msj.mensajeCorrecto('Agrego', 'Se agrego correctamente');
    } else {
      // update
      if (this.confirFuncionario) {
        formDataUserDet.value.datauserId = this.datauserId;
        formDataUserDet.value.datauserNam = this.datauserNam;
      }
      this.dataApi.updateDataUserDet(formDataUserDet.value);
      this.msj.mensajeAdvertencia('Edito', 'Se edito correctamente');
    }
    formDataUserDet.resetForm(); // limpiar el formulario
    this.btnClose.nativeElement.click(); // cierra el modal
  }

  resetForm(formDataUserDet: NgForm) {
    formDataUserDet.resetForm(); // para limpiar el formulario
  }


}
