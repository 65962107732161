import { Component, OnInit, ViewChild, ElementRef, Input, OnChanges } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DataApiService } from '../../services/data-api.service';
import { AuthService } from '../../services/auth.service';
import { NgForm } from '@angular/forms';
import { TaskDetailsInterface } from '../../models/task_details';
import {formatDate} from '@angular/common';
import { MensajesService } from 'src/app/services/mensajes.service';

@Component({
  selector: 'app-obtervation-task',
  templateUrl: './obtervation-task.component.html',
  styleUrls: ['./obtervation-task.component.css']
})
export class ObtervationTaskComponent implements OnInit {
  @ViewChild('btnClose', { static: false}) btnClose: ElementRef;
  @Input() taskId: string; // paso el dato del id de task
  @Input() adminId: string; // paso el dato del id de task
  @Input() customersId: string;
  @Input() customer: string;
  @Input() cod: string;
  @Input() serviceId: string;
  @Input() service: string;
  @Input() state: string;
  @Input() email: string;
  @Input() emailCust: string;
  @Input() titulo: string;

  @Input() userUidName: string;
  @Input() emailCC: string;
  @Input() nameCC: string;
  @Input() razon: string;
  @Input() fechainicial: string;
  @Input() fechafinal: string;
  @Input() CodCtrl: number;

  public mensaje: string;

  constructor(public dataApi: DataApiService, private authService: AuthService, private msj: MensajesService) {   }

  public task: string; // lo ponemos como un objeto vacio
  public taskdetails: TaskDetailsInterface[];

  public isAdmin: any = null;
  public userUid: string;
  public date: string = formatDate(new Date(), 'dd-MM-yyyy', 'en');
  public nameAdd: string;
  pageActuales: number = 1;

  ngOnInit() {
    this.mensaje = '';
    this.getCurrentUser();
    this.getListALLDetail();
  }

  getListALLDetail() {
    this.dataApi.getAllTaskDetail().subscribe(taskdetails => {
      this.taskdetails = taskdetails;
    });
  }

  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
            this.isAdmin = Object.assign({}, userRole.roles).hasOwnProperty('admin');
            this.getAllDataUser(this.userUid);
        });
      }
    });
  }

  getAllDataUser(idDataUser: string) {
    this.dataApi.getAllDataUserUid(idDataUser).subscribe( dataUser => {
      dataUser.forEach( dat => {
         this.nameAdd = dat.name;
      });
    });
   }

  getListTaskDetails(id: string) {
    this.dataApi.getAllTaskDetailTaskid(id).subscribe(taskdetails => {
      if (taskdetails) {
        this.taskdetails = taskdetails;
      }
    });
  }

  resetForm(formRequest: NgForm) {
    formRequest.resetForm(); // para limpiar el formulario
  }


  onSaveTaskDetails(taskdetailsForm: NgForm): void {
      taskdetailsForm.value.userUid = this.userUid; // id del funcionario
      taskdetailsForm.value.adminId = this.adminId; // id del jefe funcionario
      taskdetailsForm.value.email = this.email;
      taskdetailsForm.value.taskId = this.taskId; // la tarea Id
      taskdetailsForm.value.customersId = this.customersId; // la cliente Id
      taskdetailsForm.value.customer = this.customer; // nombre del cliente
      taskdetailsForm.value.emailCust = this.emailCust;
      taskdetailsForm.value.cod = this.cod;
      taskdetailsForm.value.codigo_control = this.CodCtrl; // Pasa el codigo de control
      taskdetailsForm.value.codigo = this.CodCtrl + ''; // Pasa el codigo de control
      taskdetailsForm.value.serviceId = this.serviceId; // Id del servicio
      taskdetailsForm.value.service = this.service; // nombre del servicio
      taskdetailsForm.value.state = this.state; // nombre del estado
      taskdetailsForm.value.titulo = this.titulo; // titulo de la tarea

      taskdetailsForm.value.userUidName = this.nameAdd; // nombre del usuario que realizo la observacion
      taskdetailsForm.value.emailCC = this.emailCC;
      taskdetailsForm.value.nameCC = this.nameCC;
      taskdetailsForm.value.razon = this.razon;
      taskdetailsForm.value.fechainicial = this.fechainicial;
      taskdetailsForm.value.fechafinal = this.fechafinal;
      taskdetailsForm.value.date = this.date;
      taskdetailsForm.value.activo = true;
      if (taskdetailsForm.value.envio === undefined){
        taskdetailsForm.value.envio = false;
      }
      if (taskdetailsForm.value.description !== undefined) {
        this.dataApi.addTaskDet(taskdetailsForm.value);
        taskdetailsForm.resetForm();
        this.btnClose.nativeElement.click(); // cierra el modal
      }else {
        this.msj.mensajeAdvertencia('ADVERTENCIA', 'Falta el la descripción de la observación !!');
      }

  }

}
