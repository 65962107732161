import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NgForm } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';
import { MensajesService } from '../../../services/mensajes.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  constructor(private router: Router,
              private authService: AuthService,
              private storage: AngularFireStorage,
              private msj: MensajesService) { }
  @ViewChild('imageUser', { static: false }) inputImageUser: ElementRef;
  public name: '';
  public email: string = '';
  public password: string = '';
  public confimpassword: string = '';
  public msgError: string = '';

  public isAadmin: any = null;
  public userUid: string;
  public mostrar: boolean = false;
  public nivel: string;

  public flashmessages: FlashMessagesService;

  uploadPercent: Observable<number>;
  urlImage: Observable<string>;

  public isError = false;
  ngOnInit() {
    this.getCurrentUser();
  }

  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
            this.isAadmin = Object.assign({}, userRole.roles).hasOwnProperty('Aadmin');
            if (this.isAadmin === true) {
              this.mostrar = true;
              this.getNivelAdmin();
            } else {
              this.mostrar = false;
            }
        });
      } else {
        this.getNivelCliente();
      }
    });
  }

  getNivelAadmin() {
    this.nivel = '0';
    this.authService.nivelUser(this.nivel);
  }

  getNivelAdmin() {
    this.nivel = '1';
    this.authService.nivelUser(this.nivel);
  }

  getNivelCliente() {
    this.nivel = '3';
    this.authService.nivelUser(this.nivel);
  }

  getNivelEditor() {
    this.nivel = '2';
    this.authService.nivelUser(this.nivel);
  }

  onUpload(e) {
    // console.log('subir', e.target.files[0]);
    const id = Math.random().toString(36).substring(2);
    const file = e.target.files[0]; // el archivo que queremos
    const filePath = `uploads/profile_${id}`;
    const ref = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    // recuperar el id del fichero
    this.uploadPercent = task.percentageChanges();
    task.snapshotChanges().pipe(finalize(() => this.urlImage = ref.getDownloadURL())).subscribe();
  }

  onAddUser(formRegister: NgForm) {
    if (formRegister.valid) {
      this.authService.registerUser(this.email, this.password)
      .then((res) => {
          this.authService.isAuth().subscribe(user => {
            if (user) {
              user.updateProfile({
                displayName: '',
                photoURL: this.inputImageUser.nativeElement.value
              }).then(() => {
                this.msj.mensajeCorrecto('Agrego', 'Se agrego su usuario correctamente');
                this.onLoginRedirect();
              }).catch((error) => {
                console.log('err', error.message);
              });
            }
          });
      }).catch(err => {
        this.msj.mensajeError('ERROR', err);
        // this.msgError = res.error.error.details.messages.email;
        this.msgError = err.message;
        this.onisError();
      });
    }
  }

  onLoginGoogle(): void {
    this.authService.registerGoogleUser()
      .then((res) => {
        this.msj.mensajeCorrecto('Agrego', 'Se agrego su usuario correctamente');
        this.onLoginRedirect();
    }).catch(err => {
        this.msj.mensajeError('ERROR', err);
    });
  }

  onLoginFacebook(): void {
    this.authService.registerFacebookUser()
      .then((res) => {
        this.msj.mensajeCorrecto('Agrego', 'Se agrego su usuario correctamente');
        this.onLoginRedirect();
      }).catch(err => {
        this.msj.mensajeError('ERROR', err);
      });
  }

  onLoginRedirect(): void {
    this.router.navigate(['verificacionEmail']);
  }

  onisError(): void {
    this.isError = true;
    setTimeout(() => {
      this.isError = false;
    }, 4000);
  }

}
