import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterObsTodo'
})
export class FilterObsTodoPipe implements PipeTransform {

  transform(value: any, arg: any): any {
    if (arg === '' || arg.length < 3) return value;
    const resultPosts = [];
    for (const post of value) {
      if (post.userUidName.toLowerCase().indexOf(arg.toLowerCase()) > -1 ||
          post.cod.toLowerCase().indexOf(arg.toLowerCase()) > -1 ||
          post.razon.toLowerCase().indexOf(arg.toLowerCase()) > -1
       || post.service.toLowerCase().indexOf(arg.toLowerCase()) > -1 ) {
        resultPosts.push(post);
      }
    }
    return resultPosts;
  }

}
