import { Component, OnInit } from '@angular/core';
import { DataApiService } from '../../../services/data-api.service';
import { groupInterface } from '../../../models/group';
// Para ver los roles de ususarioa
import { AuthService } from '../../../services/auth.service';
import { SubgroupInterface } from '../../../models/subgroup';
import * as printJS from 'print-js';

@Component({
  selector: 'app-list-group',
  templateUrl: './list-group.component.html',
  styleUrls: ['./list-group.component.css']
})
export class ListGroupComponent implements OnInit {

  constructor(private dataApi: DataApiService, private authService: AuthService) { }
  public groups: groupInterface[];
  public subgroups: SubgroupInterface[];
  public isAadmin: any = null;
  public userUid: string;
  public isError = false;
  public isMovimiento: boolean = false;
  public isUpdate: boolean = false;

  pageActual: number = 1;
  ngOnInit() {
    this.isUpdate = false;
    this.getListGroup();
    this.getCurrentUser();
  }

  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
            this.isAadmin = Object.assign({}, userRole.roles).hasOwnProperty('Aadmin');
        });
      }
    });
  }

  nuevo() {
    this.isMovimiento = false;
    this.isUpdate = false;
  }

  getListGroup() {
    this.dataApi.getAllGroup().subscribe( groups => {
      this.groups = groups;
    });
  }

  validarUpdate(id: string) {
    this.dataApi.getSelectSubGroupOne(id).subscribe( subgroups => {
      if (subgroups.length > 0) {
        this.isMovimiento = true;
      } else {
       this.isMovimiento = false;
      }
    });
  }

  onDeleteGroup(idGroup: string) {
    const confirmacion = confirm('Estás seguro ?');
    if (confirmacion) {
      this.dataApi.getSelectSubGroupOne(idGroup).subscribe( subgroups => {
        if (subgroups.length > 0) {
          this.onisError();
        } else {
          this.dataApi.deleteGroup(idGroup);
        }
      });
    }
  }

  // carga el update al modal
  onPreUpdateGroup(group: groupInterface) {
        this.isUpdate = true;
        this.dataApi.selectGroup = Object.assign({}, group);

  }

  onisError(): void {
    this.isError = true;
    setTimeout(() => {
      this.isError = false;
    }, 3000);
  }
  downloadPdf() {
    printJS({printable: this.groups, properties: [
      {field: 'cod', displayName: 'Codigo'},
      {field: 'name', displayName: 'Nombre del Codigo'}
    ], type: 'json',
       documentTitle: 'Lista de Grupos',
       header: '<h4 class="custom-h4">Listado de Gruposs: </h4>' + '<br><br>',
       headerStyle: 'font-weight: 300;',
       font: 'Century Gothic',
       style: '.custom-h4 { color: blue; }',
       gridHeaderStyle: 'font-weight: bold; padding: 5px; border: 1px solid #dddddd;'});
  }

}
