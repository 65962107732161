import { Component, OnInit } from '@angular/core';
import { DataApiService } from '../../../services/data-api.service';
import { AuthService } from '../../../services/auth.service';
import { BlogInterface } from '../../../models/blog';


@Component({
  selector: 'app-list-blog',
  templateUrl: './list-blog.component.html',
  styleUrls: ['./list-blog.component.css']
})
export class ListBlogComponent implements OnInit {

  constructor(private dataApi: DataApiService, private authService: AuthService) { }
  public blogs: BlogInterface[];
  public isAadmin: any = null;
  public isAdmin: any = null;
  public isCliente: any = null;
  public isEditor: any = null;
  public userUid: string;

  pageActual: number = 1;

  ngOnInit() {
    this.getCurrentUser();
    this.getAllBlog();
  }

  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
          this.isAadmin = Object.assign({}, userRole.roles).hasOwnProperty('Aadmin');
          this.isAdmin = Object.assign({}, userRole.roles).hasOwnProperty('admin');
          this.isCliente = Object.assign({}, userRole.roles).hasOwnProperty('cliente');
          this.isEditor = Object.assign({}, userRole.roles).hasOwnProperty('editor');

        });
      }
    });
  }

  getAllBlog() {
    this.dataApi.getAllBlog().subscribe(blogs => {
      if (blogs) {
        this.blogs = blogs;
      }
    });
  }

  onDeleteBlog(idBlog: string) {
    const confirmacion = confirm('Estás seguro ?');
    if (confirmacion) {
      this.dataApi.deleteBlog(idBlog);
    }
  }

  // carga la informacion al modal para el update
  onPreUpdateBlog(blog: BlogInterface) {
    this.dataApi.selectBlog = Object.assign({}, blog);

  }


}
