import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ListTaskComponent } from './components/admin/list-task/list-task.component';
import { DetailsTaskComponent } from './components/details-task/details-task.component';
import { HeroComponent } from './components/hero/hero.component';
import { HomeComponent } from './components/home/home.component';
import { ModalComponent } from './components/modal/modal.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { OffersComponent } from './components/offers/offers.component';
import { LoginComponent } from './components/users/login/login.component';
import { ProfileComponent } from './components/users/profile/profile.component';
import { RegisterComponent } from './components/users/register/register.component';
import { Page404Component } from './components/page404/page404.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from './components/footer/footer.component';
import { environment } from '../environments/environment';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { TaskComponent } from './components/task/task.component';
import { NgxPaginationModule} from 'ngx-pagination';
import { ListGroupComponent } from './components/admin/list-group/list-group.component';
import { ListSubgroupComponent } from './components/admin/list-subgroup/list-subgroup.component';
import { ListFamilyComponent } from './components/admin/list-family/list-family.component';
import { ModalGroupComponent } from './components/modals/modal-group/modal-group.component';
import { ListCustomersComponent } from './components/admin/list-customers/list-customers.component';
import { ModalCustomerComponent } from './components/modals/modal-customer/modal-customer.component';
import { ModalSubgroupComponent } from './components/modals/modal-subgroup/modal-subgroup.component';
import { ModalFamilyComponent } from './components/modals/modal-family/modal-family.component';
import { ListStateComponent } from './components/admin/list-state/list-state.component';
import { ListEvaluationComponent } from './components/admin/list-evaluation/list-evaluation.component';
import { ModalStateComponent } from './components/modals/modal-state/modal-state.component';
import { ModalEvaluationComponent } from './components/modals/modal-evaluation/modal-evaluation.component';
import { ObtervationTaskComponent } from './components/obtervation-task/obtervation-task.component';
import { ListDosageComponent } from './components/admin/list-dosage/list-dosage.component';
import { ModalDosageComponent } from './components/modals/modal-dosage/modal-dosage.component';
import { FilterPipe } from './pipes/filter.pipe';
import { FiltertaskPipe } from './pipes/filtertask.pipe';
import { FilterObsPipe } from './pipes/filter-obs.pipe';
import { FilterFamilyPipe } from './pipes/filter-family.pipe';
import { ListActivityComponent } from './components/admin/list-activity/list-activity.component';
import { ModalActivityComponent } from './components/modals/modal-activity/modal-activity.component';
import { FilterActPipe } from './pipes/filter-act.pipe';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { ListRequestComponent } from './components/admin/list-request/list-request.component';
import { FilterRequestPipe } from './pipes/filter-request.pipe';
import { ModalRequestComponent } from './components/modals/modal-request/modal-request.component';
import { MessagingService } from './services/messaging.service';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { FilterObsTodoPipe } from './pipes/filter-obs-todo.pipe';
import { ModalTaskActComponent } from './components/modals/modal-task-act/modal-task-act.component';
import { PasswordValidationDirective } from './validations/password-validation.directive';
import { ListTaskGroupComponent } from './components/admin/list-task-group/list-task-group.component';
import { FilterGroupPipe } from './pipes/filter-group.pipe';
import { RptTaskCustomersComponent } from './components/reports/rpt-task-customers/rpt-task-customers.component';
import { RptTaskProceduresComponent } from './components/reports/rpt-task-procedures/rpt-task-procedures.component';

// graficos
import { ChartsModule } from 'ng2-charts';
import { LineComponent } from './components/graphic/line/line.component';
import { ListDataUserComponent } from './components/admin/list-data-user/list-data-user.component';
import { ModalDataUserComponent } from './components/modals/modal-data-user/modal-data-user.component';
import { ListSlopesComponent } from './components/admin/list-slopes/list-slopes.component';
import { ListSupervisorComponent } from './components/admin/list-supervisor/list-supervisor.component';
import { ModalSupervisorComponent } from './components/modals/modal-supervisor/modal-supervisor.component';
import { RptTaskListFuncComponent } from './components/reports/rpt-task-list-func/rpt-task-list-func.component';
import { RptEfectividadComponent } from './components/reports/rpt-efectividad/rpt-efectividad.component';
import { ListDataUserdetComponent } from './components/admin/list-data-userdet/list-data-userdet.component';
import { ModalDataUserDetComponent } from './components/modals/modal-data-user-det/modal-data-user-det.component';
import { PageOutsourcingComponent } from './components/pages/page-outsourcing/page-outsourcing.component';
import { PageServicioComponent } from './components/pages/page-servicio/page-servicio.component';
import { PagePayrollrhhComponent } from './components/pages/page-payrollrhh/page-payrollrhh.component';
import { PageReingenieriaComponent } from './components/pages/page-reingenieria/page-reingenieria.component';
import { ListBlogComponent } from './components/admin/list-blog/list-blog.component';
import { ModalBlogComponent } from './components/modals/modal-blog/modal-blog.component';
import { ModalSpotComponent } from './components/modals/modal-spot/modal-spot.component';

// mesageria en la aplicaión
import { FlashMessagesModule} from 'angular2-flash-messages';
import { FlashMessagesService } from 'angular2-flash-messages';
// multi select
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AsignRolesComponent } from './components/asign-roles/asign-roles.component';
import { FilterRolPipe } from './pipes/filter-rol.pipe';
// Cargando el login
import { NgxSpinnerModule } from 'ngx-spinner';
import { MensajesService } from './services/mensajes.service';
import { LayoutCargandoComponent } from './components/layout-cargando/layout-cargando.component';

// Directivas
import { ConfirmEqualValidatorDirective } from './components/shared/confirm-equal-validator.directive';
import { SendEmailComponent } from './components/users/send-email/send-email.component';
import { MnucatalogoComponent } from './components/mnucatalogo/mnucatalogo.component';
import { MnureporteComponent } from './components/mnureporte/mnureporte.component';
import { PrivacidadComponent } from './components/privacidad/privacidad.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';

import { ModalserviceComponent } from './shared/components/modalservice/modalservice.component';
import { NewServicedetComponent } from './components/servicedet/new-servicedet/new-servicedet.component';
import { NewServicedetModule } from './components/servicedet/new-servicedet/new-servicedet.module';
import { EditServicedetComponent } from './components/servicedet/edit-servicedet/edit-servicedet.component';
import { EditServicedetModule } from './components/servicedet/edit-servicedet/edit-servicedet.module';
import { AutocompleteLibModule } from "angular-ng-autocomplete";

import { ModalsubserviceComponent } from './shared/components/modalsubservice/modalsubservice.component';
import { NewSubserviceComponent } from './components/subservice/new-subservice/new-subservice.component';
import { NewSubserviceModule } from './components/subservice/new-subservice/new-subservice.module';
import { EditSubserviceComponent } from './components/subservice/edit-subservice/edit-subservice.component';
import { EditSubserviceModule } from './components/subservice/edit-subservice/edit-subservice.module';


@NgModule({
  declarations: [
    AppComponent,
    ListTaskComponent,
    DetailsTaskComponent,
    HeroComponent,
    HomeComponent,
    ModalComponent,
    NavbarComponent,
    OffersComponent,
    LoginComponent,
    ProfileComponent,
    RegisterComponent,
    Page404Component,
    FooterComponent,
    TaskComponent,
    ListGroupComponent,
    ListSubgroupComponent,
    ListFamilyComponent,
    ModalGroupComponent,
    ListCustomersComponent,
    ModalCustomerComponent,
    ModalSubgroupComponent,
    ModalFamilyComponent,
    ListStateComponent,
    ListEvaluationComponent,
    ModalStateComponent,
    ModalEvaluationComponent,
    ObtervationTaskComponent,
    ListDosageComponent,
    ModalDosageComponent,
    FilterPipe,
    FiltertaskPipe,
    FilterObsPipe,
    FilterFamilyPipe,
    ListActivityComponent,
    ModalActivityComponent,
    FilterActPipe,
    LandingPageComponent,
    ListRequestComponent,
    FilterRequestPipe,
    ModalRequestComponent,
    FilterObsTodoPipe,
    ModalTaskActComponent,
    PasswordValidationDirective,
    ListTaskGroupComponent,
    FilterGroupPipe,
    RptTaskCustomersComponent,
    RptTaskProceduresComponent,
    LineComponent,
    ListDataUserComponent,
    ModalDataUserComponent,
    ListSlopesComponent,
    ListSupervisorComponent,
    ModalSupervisorComponent,
    RptTaskListFuncComponent,
    RptEfectividadComponent,
    ListDataUserdetComponent,
    ModalDataUserDetComponent,
    PageOutsourcingComponent,
    PageServicioComponent,
    PagePayrollrhhComponent,
    PageReingenieriaComponent,
    ListBlogComponent,
    ModalBlogComponent,
    ModalSpotComponent,
    AsignRolesComponent,
    FilterRolPipe,
    LayoutCargandoComponent,
    ConfirmEqualValidatorDirective,
    SendEmailComponent,
    MnucatalogoComponent,
    MnureporteComponent,
    PrivacidadComponent,
    ModalserviceComponent,
    NewServicedetComponent,
    EditServicedetComponent,
    ModalsubserviceComponent,
    NewSubserviceComponent,
    EditSubserviceComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig, 'angular6-201'), // , 'angular6-201'
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AngularFireMessagingModule,
    NgxPaginationModule,
    ChartsModule,
    FlashMessagesModule,
    NgxSpinnerModule,
    NgMultiSelectDropDownModule.forRoot(),
    BrowserAnimationsModule,
    MaterialModule,
    NewServicedetModule,
    EditServicedetModule,
    AutocompleteLibModule,
    NewSubserviceModule,
    EditSubserviceModule

  ],

  entryComponents: [
    ModalserviceComponent,
    ModalsubserviceComponent
  ],
  providers: [AngularFireAuth, AngularFirestore, MessagingService, AsyncPipe, FlashMessagesService, MensajesService],
  bootstrap: [AppComponent]
})
export class AppModule { }
