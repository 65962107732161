import { Component, OnInit } from '@angular/core';
import { DataApiService } from '../../../services/data-api.service';
import { TaskInterface } from '../../../models/task';
import { AuthService } from '../../../services/auth.service';
import * as printJS from 'print-js';
import { TaskDetailsInterface } from '../../../models/task_details';
import Swal from 'sweetalert2';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { timestamp } from 'rxjs/operators';


@Component({
  selector: 'app-list-task',
  templateUrl: './list-task.component.html',
  styleUrls: ['./list-task.component.css']
})
export class ListTaskComponent implements OnInit {

  constructor(private dataApi: DataApiService, private authService: AuthService) {

   }
  public tasks: TaskInterface[];
  public taskdetails: TaskDetailsInterface[];
  public tasksCount: TaskInterface = {};
  public taskOb: TaskDetailsInterface = {};

  public isAadmin: any = null;
  public isAdmin: any = null;
  public isEditor: any = null;
  public userUid: string;
  public adminId: string;
  public task: string;
  public customer: string;
  public customersId: string;
  public familyId: string;
  public service: string;
  public state: string;
  public isUpdate: boolean = false;
  public isError = false;
  public email: string;
  public emailCust: string;
  public titulo: string;
  public descripcion: string;
  public Observacion: string; // observacion para mandar con movimiento
  public fechainicial: string;
  public fechafinal: string;
  public evaluacion: string;

  public serviceDet: string = '';
  public titutoDet: string = '';
  public customerDet: string = '';
  public userDet: string = '';
  public estadoDet: string = '';
  public evaluacionDet: string;
  public iniDet: string;
  public finDet: string;
  public descripcionDet: string = '';

  public userUidName: string = '';
  public userUidEmail: string = '';
  public emailCC: string = '';
  public nameCC: string = '';
  public razon: string = '';
  public isMovimiento: boolean = false;
  public mostrar: Boolean =  false;

  public isSolicitudTarea: boolean = false; // cuando se crea una nueva tarea desde una solicitud de la misma
  public observacion: string = ''; // pasa los datos de la fecha el nombre del cliente y su solicitud de la nueva tarea
  public requestId: string = '0';
  public isCustomersId: string;
  public isCustomer: string;
  public isEmailCust: string;
  public isEmailCC: string;
  public isNameCC: string;
  public isRazon: string;
  public fullService: string;
  public cod: string;
  public color: boolean;
  clave: string = '71U4A72QmvjK4bbAA9rl';
  rangoA: string = 'Fuera de Rango';
  rangoB: string = 'Dentro del Rango';
  public contador: number = 0;
  public codigo_control: number = 0;

  filterpost = '';
  pageActual: number = 1;

  ngOnInit() {
    this.mostrar = false;
    this.getCurrentUserProceso();
  }


  nuevo() {
    this.dataApi.selectTask = Object.assign({});
    this.isUpdate = false;
    this.isMovimiento = false;
    this.isSolicitudTarea = false;
    this.observacion = '';
    this.requestId = '0';
    this.isCustomersId = '';
    this.isCustomer = '';
    this.isEmailCust = '';
    this.isEmailCC = '';
    this.isNameCC = '';
    this.isRazon = '';
  }

  validarUpdate(id: string) {
    this.dataApi.getAllTaskDetailDelete(id).subscribe( tasks => {
      if (tasks.length > 0) {
        this.isMovimiento = true;
      } else {
        this.isMovimiento = false;
      }
    });

  }

  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
        this.email = auth.email;
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
            this.isAadmin = Object.assign({}, userRole.roles).hasOwnProperty('Aadmin');
            this.isAdmin = Object.assign({}, userRole.roles).hasOwnProperty('admin');
            this.isEditor = Object.assign({}, userRole.roles).hasOwnProperty('editor');
            if (this.isAadmin === true) {
              this.getAllDataUser(this.userUid); // todos los clientes
              this.getAllTasks();
            }
            if (this.isAdmin === true) {
              this.getAllDataUser(this.userUid); // todos los clientes
              this.getListTasksAdmin(this.userUid);
            }
            if (this.isEditor === true) {
              this.getAllDataUser(this.userUid);
              this.getListTasksFunc(this.userUid);
            }
        });
      }
    });
  }

  getCurrentUserProceso() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
        this.email = auth.email;
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
            this.isAadmin = Object.assign({}, userRole.roles).hasOwnProperty('Aadmin');
            this.isAdmin = Object.assign({}, userRole.roles).hasOwnProperty('admin');
            this.isEditor = Object.assign({}, userRole.roles).hasOwnProperty('editor');
            if (this.isAadmin === true) {
              this.getAllDataUser(this.userUid); // todos los clientes
              this.getAllTasksP();
            }
            if (this.isAdmin === true) {
              this.getAllDataUser(this.userUid); // todos los clientes
              this.getListTasksAdminP(this.userUid);
            }
            if (this.isEditor === true) {
              this.getAllDataUser(this.userUid);
              this.getListTasksFuncP(this.userUid);
            }
        });
      }
    });
  }

  getCurrentUserFinalizado() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
        this.email = auth.email;
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
            this.isAadmin = Object.assign({}, userRole.roles).hasOwnProperty('Aadmin');
            this.isAdmin = Object.assign({}, userRole.roles).hasOwnProperty('admin');
            this.isEditor = Object.assign({}, userRole.roles).hasOwnProperty('editor');
            if (this.isAadmin === true) {
              this.getAllDataUser(this.userUid); // todos los clientes
              this.getAllTasksF();
            }
            if (this.isAdmin === true) {
              this.getAllDataUser(this.userUid); // todos los clientes
              this.getListTasksAdminF(this.userUid);
            }
            if (this.isEditor === true) {
              this.getAllDataUser(this.userUid);
              this.getListTasksFuncF(this.userUid);
            }
        });
      }
    });
  }

  Todos() {
    this.getCurrentUser()
  }

  Proceso() {
   this.getCurrentUserProceso()
  }

  Finalizado() {
this.getCurrentUserFinalizado()
  }

  // es el administradort de todos (Aadmin)
  getAllTasks() {
    this.dataApi.getAllTasks().subscribe(tasks => {
      if (tasks) {
        this.tasks = tasks;
      }
    });
  }

 // carag todo sus tareas que asigno el jefe encargado ( admin)
  getListTasksAdmin(id: string) {
    this.dataApi.getAllTasksUserAdm(id).subscribe(tasks => {
      if (tasks) {
        this.tasks = tasks;
      }
    });
  }

 // carga todas su treas propias el encargado (editor)
  getListTasksFunc(id: string) {
    this.dataApi.getAllTasksUser(id).subscribe(tasks => {
      if (tasks) {
        this.tasks = tasks;
      }
    });
  }

  // es el administradort de todos (Aadmin)
  getAllTasksP() {
    this.dataApi.getAllTasksProceso().subscribe(tasks => {
      if (tasks) {
        this.tasks = tasks;
      }
    });
  }

 // carag todo sus tareas que asigno el jefe encargado ( admin)
  getListTasksAdminP(id: string) {
    this.dataApi.getAllTasksUserAdmProceso(id).subscribe(tasks => {
      if (tasks) {
        this.tasks = tasks;
      }
    });
  }

 // carga todas su treas propias el encargado (editor)
  getListTasksFuncP(id: string) {
    this.dataApi.getAllTasksUserProceso(id).subscribe(tasks => {
      if (tasks) {
        this.tasks = tasks;
      }
    });
  }

   // es el administradort de todos (Aadmin)
   getAllTasksF() {
    this.dataApi.getAllTasksFinalizado().subscribe(tasks => {
      if (tasks) {
        this.tasks = tasks;
      }
    });
  }

 // carag todo sus tareas que asigno el jefe encargado ( admin)
  getListTasksAdminF(id: string) {
    this.dataApi.getAllTasksUserAdmFin(id).subscribe(tasks => {
      if (tasks) {
        this.tasks = tasks;
      }
    });
  }

 // carga todas su treas propias el encargado (editor)
  getListTasksFuncF(id: string) {
    this.dataApi.getAllTasksUserFin(id).subscribe(tasks => {
      if (tasks) {
        this.tasks = tasks;
      }
    });
  }

  // Busca si tiene sus datos registrado del usuario encargado o administrador
  getAllDataUser(idDataUser: string) {
   this.dataApi.getAllDataUserUid(idDataUser).subscribe( dataUser => {
      if (dataUser.length > 0) {
        this.mostrar = false;
      } else {
        this.mostrar = true;
      }
   });
  }

  onOneTask(taskid: string) {
    this.dataApi.getOneTask(taskid).subscribe( task => {
         this.task = task.id;
         this.codigo_control =  task.codigo_control;
         this.adminId = task.adminId;
         this.customer = task.customer;
         this.customersId = task.customersId;
         this.familyId = task.familyId;
         this.service = task.service;
         this.state = task.estado;
         this.emailCust = task.emailCust;
         this.titulo = task.titulo;
         this.descripcion = task.descripcion;
         this.Observacion = task.observacion;
         this.userUidName = task.userUidName;
         this.userUidEmail = task.userUidEmail;
         this.nameCC = task.nameCC;
         this.emailCC = task.emailCC;
         this.razon = task.razon;
         this.fechainicial = task.fechainicial;
         this.fechafinal = task.fechafinal;
         this.fullService = task.group + ' - ' + task.subgroup + ' - ' + task.service;
         if (task.evaluationId == '71U4A72QmvjK4bbAA9rl') {
          this.evaluacion = this.CalcularEvaluacion(task.fechafinal);
         } else {
          this.evaluacion = task.evaluacion;
         }

         this.cod = task.cod;
    });
  }

  onOneTaskDet(taskid: string) {

  }

  count() {
    this.dataApi.getAllTasksCount().subscribe( task => {
      let i = 0;
      task.forEach(item =>{
        if (item) {
          i = i + 1;
          if (i <= 4) {
            this.tasksCount.id = item.id;
            this.tasksCount.codigo_control = i;
           //this.dataApi.updateTask(this.tasksCount);
          }
        }
      });
      Swal.fire('Listo!', 'Contador concluido.', 'success');
    });
  }

  countObs() {
    this.dataApi.getAllTasksCount().subscribe( task => {

      task.forEach(TAKS =>{
        if (TAKS) {
          this.dataApi.getAllTaskDetailDelete(TAKS.id).subscribe( Obs => {
            if (Obs.length > 0) {
              console.log('ENTRO');
              Obs.forEach(items => {
                 if (TAKS.id === items.taskId) {
                  this.taskOb.id = items.id;
                  this.taskOb.codigo_control = TAKS.codigo_control;
                  this.dataApi.updateTaskOb(this.taskOb);
                  console.log('ACTUALIZO!!!!!!');
                }
              });
            }
         });
        }
      });
      Swal.fire('Listo!', 'Obs concluido.', 'success');
    });
  }

  onDeleteBook(idTask: string) {
    const confirmacion = confirm('Estás seguro ?');
    if (confirmacion) {
      this.dataApi.getAllTaskDetailDelete(idTask).subscribe( tasks => {
        if (tasks.length > 0) {
          this.onisError();
        } else {
          this.dataApi.deleteTask(idTask);
        }
      });
    }
  }


  onValidate(task: TaskInterface) {
    Swal.fire({
      title: 'Taracoe',
      text: `Validar Tarea!`,
      imageUrl: 'https://firebasestorage.googleapis.com/v0/b/taracoe-3f6ba.appspot.com/o/FIAMImages%2Fpush.png?alt=media&token=01ee26c5-6e61-451e-9740-f3f2bd76acba',
      imageHeight: 100,
      imageWidth: 100,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#FF0000',
      confirmButtonText: 'Si, validar!',
     }).then ( result => {
       if (result.value) {
         if (task.validate === false) {
          task.validate = true;
          this.dataApi.updateTask(task);
         }

         Swal.fire('Validar!', 'La tarea ha sido validada para su finalización.', 'success');
       }
     });
  }
  onPrintDetail(idTaskDet: string) {
    if (idTaskDet != null) {
      this.dataApi.getOneTask(idTaskDet).subscribe( taskDet => {
        this.serviceDet = taskDet.service;
        this.titutoDet = taskDet.titulo;
        this.customerDet = taskDet.razon;
        this.userDet = taskDet.userUidName;
        this.estadoDet = taskDet.estado;
        this.evaluacionDet = taskDet.evaluacion;
        this.iniDet = taskDet.fechainicial;
        this.finDet = taskDet.fechafinal;
        this.descripcionDet = taskDet.descripcion;
      });
    }
    if (idTaskDet != null) {
      this.dataApi.getAllTaskDetailTaskid(idTaskDet).subscribe( taskdetails => {
          this.taskdetails = taskdetails;
          this.downloadDetPdf();
      });
    }
  }

  // carga la informacion al modal para el update
  onPreUpdateTask(task: TaskInterface) {
    this.isUpdate = true;
    this.dataApi.selectTask = Object.assign({}, task);
  }


  onisError(): void {
    this.isError = true;
    setTimeout(() => {
      this.isError = false;
    }, 3000);
  }

  // calcula la evaluacion de un tarea aun en proceso si esta fuera o noi de rang0
  CalcularEvaluacion(Fecha: string) {
    if (
      new Date(Fecha) > new Date()
    ) {
      this.color = true;
     return "Fuera del Rango"
    } else {
      this.color = false;
      return "Dentro del Rango"
    }

  }

  CalcularEvaluacionBool(Fecha: string) {
    if (
      new Date(Fecha) > new Date()
    ) {
     return false
    } else {
      return true
    }

  }


  downloadDetPdf() {
    printJS({printable: this.taskdetails, properties: [
      {field: 'date', displayName: 'Fecha'},
      {field: 'description', displayName: 'Descripción'}
    ], type: 'json',
       documentTitle: 'Control de Trámites de ServiContable13 Ltda.',
       header: '<h4 class="custom-h4">Trámite del Encargado Pendientes: </h4>'
      + '<b>Encargado:</b> ' + ' ' + this.userDet + '<br>' + '<b>Razón:</b> '
      + this.customerDet + '<br>' + '<b>Titulo:</b> ' + this.titutoDet + '<br><hr><br>' + '<b>Servicio: </b> '
      + this.serviceDet + '<br>' + '<b>Fecha Inicio: </b>' + this.iniDet + '<br>'
      + '<b>Fecha Final: </b>' + this.finDet + '<br>' + '<b>Descripción: </b>' +  this.descripcionDet + '<br><br>',
       headerStyle: 'font-weight: 300;',
       font: 'Century Gothic',
       style: '.custom-h4 { color: blue; }',
       gridHeaderStyle: 'font-weight: bold; padding: 5px; border: 1px solid #dddddd;'});
  }


  downloadPdf() {
    printJS({printable: this.tasks, properties: [
      {field: 'userUidName', displayName: 'Funcionario'},
      {field: 'razon', displayName: 'Razón'},
      {field: 'titulo', displayName: 'Título'},
      {field: 'service', displayName: 'Servicio'},
      {field: 'fechainicial', displayName: 'Inicio'},
      {field: 'fechafinal', displayName: 'Final'},
      {field: 'estado', displayName: 'Estado'},
      {field: 'evaluacion', displayName: 'Evaluación'}
    ], type: 'json',
       documentTitle: 'Control de Trámites de ServiContable13 Ltda.',
       header: '<h4 class="custom-h4">Trámite de Tareas: </h4>' + '<br><br>',
       headerStyle: 'font-weight: 300;',
       font: 'Century Gothic',
       style: '.custom-h4 { color: blue; }',
       gridHeaderStyle: 'font-weight: bold; padding: 5px; border: 1px solid #dddddd;'});
  }


}
