import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import { ServicesDetInterface } from '../models/servicesdet';

@Injectable({
  providedIn: 'root'
})
export class ServicesdetService {
  private servicedetCollection: AngularFirestoreCollection<ServicesDetInterface>;
  private servicedets: Observable<ServicesDetInterface[]>;
  private servicedetDoc: AngularFirestoreDocument<ServicesDetInterface>;
  private servicesdet: Observable<ServicesDetInterface>;

  constructor(
     // llama a firebase
     private afs: AngularFirestore
  ) {
    this.servicedetCollection = this.afs.collection<ServicesDetInterface>('servicedet');
  }

  public getAllServicedet(): Observable<ServicesDetInterface[]> {
    this.servicedetCollection = this.afs.collection<ServicesDetInterface>('servicedet');
    return this.servicedetCollection
      .snapshotChanges()
      .pipe(map(actions => actions.map(a => {
        const data = a.payload.doc.data() as ServicesDetInterface;
        const id = a.payload.doc.id;
        return { id, ...data};
      })));
  }

  getAllServiceTask(id: string) {
    this.servicedetCollection = this.afs.collection<ServicesDetInterface>('servicedet', ref => ref.where('taskId', '==', id));
    return this.servicedets = this.servicedetCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as ServicesDetInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }


  getAllSubFamily(id: string) {
    this.servicedetCollection = this.afs.collection<ServicesDetInterface>('servicedet', ref => ref.where('subfamilyId', '==', id));
    return this.servicedets = this.servicedetCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as ServicesDetInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }

  getOneSpeciaDet(id: string) {
    this.servicedetDoc = this.afs.doc<ServicesDetInterface>(`servicedet/${id}`);
    return this.servicesdet  = this.servicedetDoc.snapshotChanges().pipe(map(action => {
      if (action.payload.exists === false) {
        return null;
      } else {
        const data = action.payload.data() as ServicesDetInterface;
        data.id = action.payload.id;
        return data;
      }
    }));
  }

  public editServiceDetByid(servicedet: ServicesDetInterface) {
    if (servicedet) {
      return this.servicedetCollection.doc(servicedet.id).update(servicedet);
    }
  }


  public addServiceDet(servicedet: ServicesDetInterface) {
    this.saveSpecial(servicedet);
  }

  private saveSpecial(servicedet: ServicesDetInterface) {
    const specialObj = {
      taskId: servicedet.taskId,
      familyId: servicedet.familyId,
      subfamilyId: servicedet.subfamilyId,
      name: servicedet.name,
      value: false,
      observation: servicedet.observation,
      userUid: servicedet.userUid
    };

    if (servicedet.id) {
      return this.servicedetCollection.doc(servicedet.id).update(specialObj);
    } else {
      return this.servicedetCollection.add(specialObj);
    }

  }
}
