import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SubFamilyInterface } from 'src/app/models/subfamily';
import { AuthService } from 'src/app/services/auth.service';
import { MensajesService } from 'src/app/services/mensajes.service';
import { SubfamilyService } from 'src/app/services/subfamily.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-new-subservice',
  templateUrl: './new-subservice.component.html',
  styleUrls: ['./new-subservice.component.css']
})
export class NewSubserviceComponent implements OnInit {
  public userUid: string;
  public Validar: boolean = true;

  @Input() FamilyId: string;
  @Input() FamilyDes: string;

  constructor(
    private serviceDet: SubfamilyService,
    private authService: AuthService,
    private msj: MensajesService,
  ) { }

  get name() { return this.newSpecialForm.get('name'); }

  public newSpecialForm = new FormGroup({
    name: new FormControl('', [ Validators.required]),
  });

  ngOnInit() {
    this.getCurrentUser();
  }

  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
      }
    });
  }

  addNewSpecial(data: SubFamilyInterface) {

    if (data.name === '' ) {
      this.Validar = false;
      return this.msj.mensajeError('ERROR', 'Inválido el campo sub servicio estaba vacío  !!');
    }
    data.familyId = this.FamilyId;
    data.familyDes = this.FamilyDes;
    data.userUid = this.userUid;
    //console.log('DATA', data);
    if (this.Validar) {
      this.serviceDet.addSubService(data);
      this.mensaje();
    }

  }

  mensaje() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Tú trabajo ha sido guardado',
      showConfirmButton: false,
      timer: 1500
    })
  }

}
