import { Component, OnInit } from '@angular/core';
import { DataUserInterface } from '../../../models/dataUser';
import { TaskInterface } from '../../../models/task';
import { AuthService } from '../../../services/auth.service';
import { TaskDetailsInterface } from '../../../models/task_details';
import * as printJS from 'print-js';
import { DataApiService } from '../../../services/data-api.service';


@Component({
  selector: 'app-list-supervisor',
  templateUrl: './list-supervisor.component.html',
  styleUrls: ['./list-supervisor.component.css']
})
export class ListSupervisorComponent implements OnInit {

  constructor(private dataApi: DataApiService, private authService: AuthService) { }
  public tasks: TaskInterface[];
  public taskdetails: TaskDetailsInterface[];
  public isUpdate: boolean = false;
  public isSupervisor: Boolean =  false;
  public isAadmin: any = null;
  public isAdmin: any = null;
  public isEditor: any = null;
  public userUid: string;
  public mostrar: Boolean =  false;

  public serviceDet: string = '';
  public titutoDet: string = '';
  public customerDet: string = '';
  public userDet: string = '';
  public estadoDet: string = '';
  public evaluacionDet: string;
  public iniDet: string;
  public finDet: string;
  public descripcionDet: string = '';

  filterpost = '';
  pageActual: number = 1;
  public isError = false;

  ngOnInit() {
    this.getCurrentUser();
    this.getListTasks();
  }

  getListTasks() {
    this.dataApi.getAllTasksFinalizado().subscribe(tasks => {
      this.tasks = tasks;
    });
  }

  getCurrentUser() {
    this.isAadmin = false;
    this.isAdmin = false;
    this.isEditor = false;
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
            this.isAadmin = Object.assign({}, userRole.roles).hasOwnProperty('Aadmin');
            this.isAdmin = Object.assign({}, userRole.roles).hasOwnProperty('admin');
            this.isEditor = Object.assign({}, userRole.roles).hasOwnProperty('editor');
            if (this.isAadmin === true) {
              this.getAllDataUser(this.userUid); // todos los clientes
            }
            if (this.isEditor === true) {
              this.getAllDataUser(this.userUid);
            }
        });
      }
    });
  }

  // Busca si tiene sus datos registrado del usuario encargado o administrador
  getAllDataUser(idDataUser: string) {
    this.dataApi.getAllDataUserUid(idDataUser).subscribe( dataUser => {
       if (dataUser.length > 0) {
         this.mostrar = false;
       } else {
         this.mostrar = true;
       }
    });
  }

  // carga la informacion al modal para el update
  onPreUpdateTask(task: TaskInterface) {
    this.isUpdate = true;
    this.dataApi.selectTask = Object.assign({}, task);
  }


  onPrintDetail(idTaskDet: string) {
    if (idTaskDet != null) {
      this.dataApi.getOneTask(idTaskDet).subscribe( taskDet => {
        this.serviceDet = taskDet.service;
        this.titutoDet = taskDet.titulo;
        this.customerDet = taskDet.razon;
        this.userDet = taskDet.userUidName;
        this.estadoDet = taskDet.estado;
        this.evaluacionDet = taskDet.evaluacion;
        this.iniDet = taskDet.fechainicial;
        this.finDet = taskDet.fechafinal;
        this.descripcionDet = taskDet.descripcion;
      });
    }
    if (idTaskDet != null) {
      this.dataApi.getAllTaskDetailTaskid(idTaskDet).subscribe( taskdetails => {
          this.taskdetails = taskdetails;
          this.downloadDetPdf();
      });
    }
  }

  downloadDetPdf() {
    printJS({printable: this.taskdetails, properties: [
      {field: 'date', displayName: 'Fecha'},
      {field: 'description', displayName: 'Descripción'}
    ], type: 'json',
       documentTitle: 'Control de Trámites de ServiContable13 Ltda.',
       header: '<h4 class="custom-h4">Trámite del Encargado Pendientes: </h4>'
      + '<b>Encargado:</b> ' + ' ' + this.userDet + '<br>' + '<b>Razón:</b> '
      + this.customerDet + '<br>' + '<b>Titulo:</b> ' + this.titutoDet + '<br><hr><br>' + '<b>Servicio: </b> '
      + this.serviceDet + '<br>' + '<b>Fecha Inicio: </b>' + this.iniDet + '<br>'
      + '<b>Fecha Final: </b>' + this.finDet + '<br>' + '<b>Descripción: </b>' +  this.descripcionDet + '<br><br>',
       headerStyle: 'font-weight: 300;',
       font: 'Century Gothic',
       style: '.custom-h4 { color: blue; }',
       gridHeaderStyle: 'font-weight: bold; padding: 5px; border: 1px solid #dddddd;'});
  }

   downloadPdf() {
    printJS({printable: this.tasks, properties: [
      {field: 'userUidName', displayName: 'Funcionario'},
      {field: 'razon', displayName: 'Razón'},
      {field: 'titulo', displayName: 'Título'},
      {field: 'service', displayName: 'Servicio'},
      {field: 'fechainicial', displayName: 'Inicio'},
      {field: 'fechafinal', displayName: 'Final'},
      {field: 'estado', displayName: 'Estado'},
      {field: 'evaluacion', displayName: 'Evaluación'}
    ], type: 'json',
       documentTitle: 'Control de Trámites de ServiContable13 Ltda.',
       header: '<h4 class="custom-h4">Trámite de Tareas: </h4>' + '<br><br>',
       headerStyle: 'font-weight: 300;',
       font: 'Century Gothic',
       style: '.custom-h4 { color: blue; }',
       gridHeaderStyle: 'font-weight: bold; padding: 5px; border: 1px solid #dddddd;'});
  }


}
