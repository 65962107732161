import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { DataApiService } from '../../../services/data-api.service';
import { TaskInterface } from '../../../models/task';
import { AuthService } from '../../../services/auth.service';
import { DataUserInterface } from '../../../models/dataUser';


@Component({
  selector: 'app-line',
  templateUrl: './line.component.html',
  styleUrls: ['./line.component.css']
})
export class LineComponent implements OnInit {

  constructor(public dataApi: DataApiService, private authService: AuthService) { }
  public tasks: TaskInterface[];
  public dataUsers: DataUserInterface[];
  public isAdmin: any = null;
  public userUid: string;
  public usersId: string;
  public name: string = '';
  public address: string = '';
  public phone: string = '';
  contTotal: number = 0;
  contAtiempo: number = 0;
  total: number = 0;
  chart = [];

  ngOnInit() {
    this.getListUsers();
    this.getCurrentUser();
  }

  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
            this.isAdmin = Object.assign({}, userRole.roles).hasOwnProperty('admin');

        });
      }
    });
  }

  getListUsers() {
    this.dataApi.getAllDataUser().subscribe( dataUsers => {
      if (dataUsers.length > 0 ) {
        this.dataUsers = dataUsers;
      }
    });
  }

  getOneDataUser(iduserUid: string) {
    this.usersId = iduserUid;
    if (this.usersId != null) {
      this.dataApi.getAllDataUserUid(this.usersId).subscribe(dataUsers => {
        dataUsers.forEach(data => {
          this.name = data.name;
          this.address = data.address;
          this.phone = data.phone;
          this.getOneUser(this.usersId);
        });
      });
    }
  }

  getOneUser(idUsers: string) {
    this.dataApi.getAllTasksUser(idUsers).subscribe(tasks => {
      if (tasks.length > 0) {
        this.tasks = tasks;
        const allDays =  tasks.map(res => res.fechafinal);
        let i = 0;
        let x = 0;
        tasks.forEach(conta => {
          if (conta) {
            i = i + 1;
          }
          if (conta.evaluationId === 'vaKGfwXYAQbkyRlGc3aV' || conta.evaluationId === '71U4A72QmvjK4bbAA9rl' ) {
            x = x + 1;
          }
        });
        this.contTotal = i;
        this.contAtiempo = x;
        this.total = (this.contAtiempo / this.contTotal) * 100;

        const weahterDays = [];
        allDays.forEach(result => {
          let jsDate = new Date(result);
          let options = {
            weekday: 'long',
            month: 'short',
            day: 'numeric'
          };
          console.log(allDays);
          weahterDays.push(jsDate.toLocaleTimeString('es', options));


        });

      } else {
        this.tasks = tasks;
      }
    });
  }

}
