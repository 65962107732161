import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[passwordValidation]',
  providers: [{provide: NG_VALIDATORS, useExisting: PasswordValidationDirective, multi: true}]
})
export class PasswordValidationDirective implements Validator {

  passwordProhibidos = ['123456', 'querty', '123456789'];

  validate(control: import('@angular/forms').AbstractControl): import('@angular/forms').ValidationErrors {
    const password = <string>control.value;

    if (!password) {return;}
    if (password.length < 6) {return;}

    if (this.passwordProhibidos.indexOf(password) !== -1) {
      return {'passwordValidation': {'message': 'Escoge un mejor password.'}};
    }

    if (password === password.toLowerCase()) {
      return {'passwordValidation': {'message': 'Tu password debe contener mayúculas.'}};
    }

    if (password === password.toUpperCase()) {
      return {'passwordValidation': {'message': 'Tu password debe contener minúsculas.'}};
    }

    if (!/\d/.test(password)) {
      return {'passwordValidation': {'message': 'Tu password debe incluir un caracter numérico.'}};
    }

    return null;
  }
  // registerOnValidatorChange?(fn: () => void): void {
  //  throw new Error("Method not implemented.");
  // }

  constructor() { }

}
