import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { DataApiService } from '../../../services/data-api.service';
import { StateInterface } from '../../../models/state';
import { NgForm, FormGroup, Validator } from '@angular/forms';

@Component({
  selector: 'app-modal-state',
  templateUrl: './modal-state.component.html',
  styleUrls: ['./modal-state.component.css']
})
export class ModalStateComponent implements OnInit {

  constructor(public dataApi: DataApiService) { }
  @ViewChild('btnClose', { static: false}) btnClose: ElementRef;
  @Input() userUid: string; // para pasar al modal el userid logueado

  ngOnInit() {
  }

  resetForm(formState: NgForm) {
    formState.resetForm(); // para limpiar el formulario
  }

  onSaveState(stateForm: NgForm): void {
    // new
    if (stateForm.value.id == null) {
      stateForm.value.userUid = this.userUid; // guarda en la coleecion de firebase
      this.dataApi.addState(stateForm.value);
    } else {
    // update
    this.dataApi.updateState(stateForm.value);
    }
    stateForm.resetForm();
    this.btnClose.nativeElement.click(); // cierra el modal
  }

}
