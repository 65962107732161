import { Component, OnInit } from '@angular/core';
import { DataApiService } from '../../../services/data-api.service';
import { ActivityInterface } from '../../../models/activity';
import { NgForm } from '@angular/forms';
// Para ver los roles de ususarioa
import { AuthService } from '../../../services/auth.service';
import * as printJS from 'print-js';

@Component({
  selector: 'app-list-activity',
  templateUrl: './list-activity.component.html',
  styleUrls: ['./list-activity.component.css']
})
export class ListActivityComponent implements OnInit {

  constructor(private dataApi: DataApiService, private authService: AuthService) { }
  public activitys: ActivityInterface[];
  public isAdmin: any = null;
  public userUid: string;
  public isUpdate: boolean = false;
  filterpost = '';
  public isError = false;
  pageActual: number = 1;
  ngOnInit() {
    this.getListActivity();
    this.getCurrentUser();
  }

  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
            this.isAdmin = Object.assign({}, userRole.roles).hasOwnProperty('admin');
        });
      }
    });
  }

  getListActivity() {
    this.dataApi.getAllActivity().subscribe( activitys => {
      this.activitys = activitys;
    });
  }

  onDeleteActivity(idActivity: string) {
    const confirmacion = confirm('Are you sure ?');
    if (confirmacion){
     this.dataApi.deleteActivity(idActivity);
    }
  }

   // carga el update al modal
   onPreUpdateActivity(activity: ActivityInterface) {
    this.isUpdate = true;
    this.dataApi.selectActivity = Object.assign({}, activity);
  }

  downloadPdf() {
    printJS({printable: this.activitys, properties: [
      {field: 'service', displayName: 'Servicio'},
      {field: 'date', displayName: 'Fecha'},
      {field: 'description', displayName: 'descripción'}
    ], type: 'json',
       documentTitle: 'Control de Trámites de ServiContable13 Srl.',
       header: '<h4 class="custom-h4">Trámite de Actividades: </h4>' + '<br><br>',
       headerStyle: 'font-weight: 300;',
       font: 'Century Gothic',
       style: '.custom-h4 { color: blue; }',
       gridHeaderStyle: 'font-weight: bold; padding: 5px; border: 1px solid #dddddd;'});
  }

}
