import { Component, OnInit } from '@angular/core';
import { DataApiService } from '../../services/data-api.service';
import { TaskInterface } from '../../models/task';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-details-task',
  templateUrl: './details-task.component.html',
  styleUrls: ['./details-task.component.css']
})
export class DetailsTaskComponent implements OnInit {

  constructor(private dataApi: DataApiService, private route: ActivatedRoute) { }
  public task: TaskInterface = {}; // lo ponemos como un objeto vacio

  ngOnInit() {
    const idTask = this.route.snapshot.params['id'];
    this.getDetails(idTask);
  }

  getDetails(idTask: string): void {
    this.dataApi.getOneTask(idTask).subscribe( task => {
      // console.log('TASK-DETAIL:', task);
      this.task = task;
    });
  }

}
