import { Component, OnInit, Input } from '@angular/core';
import { SubFamilyInterface } from 'src/app/models/subfamily';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { MensajesService } from 'src/app/services/mensajes.service';
import { AuthService } from 'src/app/services/auth.service';
import { SubfamilyService } from 'src/app/services/subfamily.service';
import { ServicesdetService } from 'src/app/services/servicesdet.service';
import { ServicesDetInterface } from 'src/app/models/servicesdet';

@Component({
  selector: 'app-edit-subservice',
  templateUrl: './edit-subservice.component.html',
  styleUrls: ['./edit-subservice.component.css']
})
export class EditSubserviceComponent implements OnInit {
  public Validar: boolean = true;
  public userUid: string;

  @Input() servicedet: SubFamilyInterface;
  public ServiceDets: ServicesDetInterface = {};

  constructor(
    private msj: MensajesService,
    private serviceSub: SubfamilyService,
    private serviceDet: ServicesdetService,
    private authService: AuthService
  ) { }

  get name() { return this.editSpecialForm.get('name'); }

  public editSpecialForm = new FormGroup({
    id: new FormControl('', [ Validators.required]),
    name: new FormControl('', [ Validators.required, Validators.minLength(5)]),
  });

  ngOnInit() {
    this.initValuesForm();
    this.getCurrentUser();
  }

  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
      }
    });
  }

  // cargar los valores
  private initValuesForm(): void {
    this.editSpecialForm.patchValue({
      id: this.servicedet.id,
      name: this.servicedet.name,

    });
  }

  editNewSpecial(data: SubFamilyInterface) {

    if (data.name === '' ) {
      this.Validar = false;
      return this.msj.mensajeError('ERROR', 'Inválido el campo sub servicio estaba vacío  !!');
    }
    data.userUid = this.userUid;
    if (this.Validar) {
      //console.log('DATA: ', data);
      this.serviceSub.editSubServiceDetByid(data);
      this.updateServiceDet(data.id, data.name);
      this.mensaje();
    }

}

updateServiceDet(id: string, name: string) {

  this.serviceDet.getAllSubFamily(id).subscribe( subservice => {
    if (subservice.length > 0) {
      subservice.forEach(item => {
        if(id === item.subfamilyId) {
          this.ServiceDets.id = item.id;
          this.ServiceDets.name = name;
          this.serviceDet.editServiceDetByid(this.ServiceDets);

         }
      });
    }
  });

}

mensaje() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Tú trabajo ha sido guardado',
      showConfirmButton: false,
      timer: 1500
    })
}

}
