import { Component, OnInit } from '@angular/core';
import { DataApiService } from '../../../services/data-api.service';
import { TaskDetailsInterface } from '../../../models/task_details';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute, Params } from '@angular/router';


@Component({
  selector: 'app-list-slopes',
  templateUrl: './list-slopes.component.html',
  styleUrls: ['./list-slopes.component.css']
})
export class ListSlopesComponent implements OnInit {

  constructor(private dataApi: DataApiService, private authService: AuthService, private route: ActivatedRoute) { }
  public taskdetails: TaskDetailsInterface[];

  ngOnInit() {
    const idTask = this.route.snapshot.params['id'];
    this.getOneUser(idTask);
  }

  getOneUser(idUser: string) {
    this.dataApi.getAllTaskDetailDelete(idUser).subscribe(taskdetails => {
      if (taskdetails) {
        this.taskdetails = taskdetails;
      }
    });
  }

}
