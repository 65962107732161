import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { FamilyInterface } from 'src/app/models/family';
import { ServicesInterface } from 'src/app/models/services';
import { ServicesDetInterface } from 'src/app/models/servicesdet';
import { SubFamilyInterface } from 'src/app/models/subfamily';
import { AuthService } from 'src/app/services/auth.service';
import { DataApiService } from 'src/app/services/data-api.service';
import { MensajesService } from 'src/app/services/mensajes.service';
import { ServicesdetService } from 'src/app/services/servicesdet.service';
import { SubfamilyService } from 'src/app/services/subfamily.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-new-servicedet',
  templateUrl: './new-servicedet.component.html',
  styleUrls: ['./new-servicedet.component.css']
})
export class NewServicedetComponent implements OnInit {
  public userUid: string;
  public familyId: string = null;
  public Validar: boolean = true;
  public name: string;
  public servicesRoles: ServicesInterface[];
  public subservices: SubFamilyInterface[];

  @Input() TaskId: string;
  @Input() Service: string;

  constructor(
    public dataApi: DataApiService,
    private serviceDet: ServicesdetService,
    private authService: AuthService,
    private serviceSub: SubfamilyService,
    private msj: MensajesService, ) { }

  get subfamilyId() { return this.newSpecialForm.get('subfamilyId'); }

  public newSpecialForm = new FormGroup({
    subfamilyId: new FormControl('',[ Validators.required]),
  });


  ngOnInit() {
    this.getCurrentUser();
    this.getAllSubServiceId();
    //this.onService();

  }


  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
      }
    });
  }

  getAllSubServiceId() {
    this.serviceSub.getAllServiceFamily(this.Service).subscribe( subfamily => {
      this.subservices = subfamily;
    });
  }

  nameService(name: string) {
    this.name = name;
  }


  nameBranch(option: FamilyInterface) {
    this.familyId = option.id;
  }

  addNewSpecial(data: ServicesDetInterface) {

    if (data.name === '' ) {
      this.Validar = false;
      return this.msj.mensajeError('ERROR', 'Inválido el campo sub servicio estaba vacío  !!');
    }
    data.name = this.name
    data.familyId = this.Service;
    data.taskId = this.TaskId;
    data.value = false;
    data.observation = '';
    data.userUid = this.userUid;
    if (this.Validar) {
      this.serviceDet.addServiceDet(data);
      this.mensaje();
    }

  }


  mensaje() {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Tú trabajo ha sido guardado',
      showConfirmButton: false,
      timer: 1500
    })
  }

}
