import { Component, OnInit } from '@angular/core';
import { DataApiService } from '../../services/data-api.service';
import { AuthService } from '../../services/auth.service';
import { TaskDetailsInterface } from '../../models/task_details';
import { CustomersInterface } from '../../models/customers';
import * as moment from 'moment';
moment.locale('es');
import 'moment/locale/bo';


@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.css']
})
export class OffersComponent implements OnInit {

  constructor(private dataApi: DataApiService, private authService: AuthService) { }
  public taskdetails: TaskDetailsInterface[];
  public taskCustomers: TaskDetailsInterface[];
  public tasks: CustomersInterface[];
  public isAadmin: any = null;
  public isAdmin: any = null;
  public isEditor: any = null;
  public isCliente: any = null;
  public userUid: string;
  public customer: string;
  public customerId: string;
  filterpost = '';
  filtertodo = '';

  pageActual: number = 1;
  public codigo_control: number = 0;

  // Task Details

  public adminId: string;
  public task: string;
  public customers: string;
  public customersId: string;
  public familyId: string;
  public service: string;
  public state: string;
  public emailCust: string;
  public titulo: string;
  public descripcion: string;
  public Observacion: string;
  public userUidName: string;
  public userUidEmail: string;
  public nameCC: string;
  public emailCC: string;
  public razon: string;
  public fechainicial: string;
  public fechafinal: string;
  public fullService: string;
  public evaluacion: string;
  public cod: string;


  ngOnInit() {
    this.getCurrentUser();
  }

  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
        this.getOneUserCustomer(auth.uid);
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
          this.isAadmin = Object.assign({}, userRole.roles).hasOwnProperty('Aadmin');
          this.isAdmin = Object.assign({}, userRole.roles).hasOwnProperty('admin');
          this.isEditor = Object.assign({}, userRole.roles).hasOwnProperty('editor');
          this.isCliente = Object.assign({}, userRole.roles).hasOwnProperty('cliente');
          if (this.isAadmin === true) {
            this.getListDetails();
          }
          if (this.isAdmin === true) {
            this.getAadminList(this.userUid);
          }
          if (this.isEditor === true) {
            // console.log('EDITOR: ', this.userUid);
            this.getOneUser(this.userUid);
          }
          if (this.isCliente === true) {
            this.getCustomer(this.userUid);
          }
        });
      }
    });
  }

  // TODO: BUSCA POR EL ID DEL CLIENTE
  getListDetails() {
    this.dataApi.getAllTaskDetail().subscribe( taskdetails => {
      this.taskdetails = taskdetails;
    });
  }

    // BUSCA POR USUARIO LOGUEADO JEFE FUNCIONARIO
  getAadminList(idUser: string) {
      this.dataApi.getAdminDetailUserUid(idUser).subscribe(taskdetails => {
          this.taskdetails = taskdetails;
      });
  }

   // BUSCA POR USUARIO LOGUEADO FUNCIONARIO
  getOneUser(idUser: string) {

    this.dataApi.getAllTaskDetailUserUid(idUser).subscribe(taskdetails => {
        this.taskdetails = taskdetails;

    });
  }

  // BUSCAR CLIENTES LOGUEADO
  getCustomer(id: string) {
    this.dataApi.getOneCustomerUser(id).subscribe(task => {
      if (task.length > 0) {
        task.forEach(element => {
          this.customerId = element.id;
          this.getListDetCustomer(this.customerId);
        });
      }
    });
  }

   // busca al cliente segun el id del user
   getListDetCustomer(idCustomer: string) {
    this.dataApi.getAllTaskDetailUser(idCustomer).subscribe( taskCustomers => {
      this.taskCustomers = taskCustomers;
    });
  }

  getOneUserCustomer(idUser: string) {
    this.dataApi.getOneCustomerUser(idUser).subscribe(tasks => {
        this.tasks = tasks;
    });
  }


  // TODO: AUN FALTA VER SOBRE QUE SOLO SEA DE UN SOLO CLIENTE SEGUN EL REGISTRO Y EL LOGIN
  getOneCustomer(IdCustormer: string): void {
    this.dataApi.getOneCustomer(IdCustormer).subscribe( customer => {
      this.customer = customer.name;

    });
  }


  onOneTask(taskid: string) {
    this.dataApi.getOneTask(taskid).subscribe( task => {
         this.task = task.id;
         this.codigo_control = task.codigo_control;
         this.adminId = task.adminId;
         this.customers = task.customer;
         this.customersId = task.customersId;
         this.familyId = task.familyId;
         this.service = task.service;
         this.state = task.estado;
         this.emailCust = task.emailCust;
         this.titulo = task.titulo;
         this.descripcion = task.descripcion;
         this.Observacion = task.observacion;
         this.userUidName = task.userUidName;
         this.userUidEmail = task.userUidEmail;
         this.nameCC = task.nameCC;
         this.emailCC = task.emailCC;
         this.razon = task.razon;
         this.fechainicial = task.fechainicial;
         this.fechafinal = task.fechafinal;
         this.fullService = task.group + ' - ' + task.subgroup + ' - ' + task.service;
         if (task.evaluationId == '71U4A72QmvjK4bbAA9rl') {
          this.evaluacion = this.CalcularEvaluacion(task.fechafinal);
         } else {
          this.evaluacion = task.evaluacion;
         }
         this.cod = task.cod;
    });
  }

  // calcula la evaluacion de un tarea aun en proceso si esta fuera o noi de rang0
  CalcularEvaluacion(Fecha: string) {
    if (
      new Date(Fecha) >= new Date()
    ) {
     return "Dentro del Rango"
    } else {
      return "Fuera del Rango"
    }

  }

  FechaFormato(fecha: Date) {
  var fecha = new Date(fecha)

  return moment(fecha).format('DD-MM-YYYY');
  }
}
