import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { DataApiService } from '../../../services/data-api.service';
import { SubgroupInterface } from '../../../models/subgroup';
import { NgForm } from '@angular/forms';
import { groupInterface } from '../../../models/group';
import Swal from 'sweetalert2';
import { MensajesService } from '../../../services/mensajes.service';
import { TaskInterface } from 'src/app/models/task';
import { TaskDetailsInterface } from 'src/app/models/task_details';
import { DosageInterface } from 'src/app/models/dosage';
import { FamilyInterface } from 'src/app/models/family';


@Component({
  selector: 'app-modal-subgroup',
  templateUrl: './modal-subgroup.component.html',
  styleUrls: ['./modal-subgroup.component.css']
})
export class ModalSubgroupComponent implements OnInit {

  constructor(public dataApi: DataApiService, private msj: MensajesService) { }
  public groups: groupInterface[];
  public group: string = '';
  public subgroups: SubgroupInterface[];
  public confirmar: boolean = false;
  public idGroup: string = '';
  public idSubGroup: string = '';
  contador: number;
  cod: string = '';
  codA: string;

  public task: TaskInterface = {};
  public taskOb: TaskDetailsInterface = {};
  public dosage: DosageInterface = {};
  public family: FamilyInterface = {};

  @ViewChild('btnClose', { static: false}) btnClose: ElementRef;
  @Input() userUid: string; // para pasar al modal el userid logueado
  @Input() isUpdate: boolean;
  @Input() isMovimiento: boolean;

  ngOnInit() {
    this.getListGroup();
  }

  getListGroup() {
    this.dataApi.getSelectGroup().subscribe( groups => {
      if (groups) {
        this.groups = groups;
      }
    });
  }

  onSelectGroup(idGroup: string) {
    this.cod = '';
    this.idGroup = idGroup;
    if (this.idGroup != null) {
      this.dataApi.getOneGroup(idGroup).subscribe( group => {
        if (group) {
          this.confirmar = true;
          this.codA = group.cod;
          this.group = group.name;
        }
      });
    }
    if (this.idGroup != null) {
      this.onSelectSubGroup(this.idGroup);
    }
  }

  onSelectSubGroup(idGroup: string) {
    this.idGroup = idGroup;
    if (this.idSubGroup != null) {
      this.dataApi.getSelectSubGroupOne(this.idGroup).subscribe( subgroups => {
        if (subgroups.length > 0) {
          this.subgroups = subgroups;
        }

        let i = 0;
        subgroups.forEach(element => {
          if (element) {
            i = i + 1;
          } else {
            i = 1;
          }
        });
        this.contador = i + 1;
        if (this.contador < 10) {
          this.cod = this.codA + "-" + "0" + this.contador;
        } else {
          this.cod = this.codA + "-" + this.contador;
        }
        console.log('CONTADOR: ', this.contador);
      });
    }
  }

  resetForm(formSubgroup: NgForm) {
    formSubgroup.resetForm(); // para limpiar el formulario
  }

  onSaveSubgroup(subgroupForm: NgForm): void {
    // new
    if (subgroupForm.value.id == null) {
      subgroupForm.value.userUid = this.userUid; // guarda en la coleecion de firebase
      subgroupForm.value.group = this.group;
      subgroupForm.value.cod = this.cod;
      this.dataApi.addSubgroup(subgroupForm.value);
      this.msj.mensajeCorrecto('Agrego', 'Se agrego correctamente');
    } else {
    // update
   // if (this.confirmar) {
    //  subgroupForm.value.group = this.group;
    //  subgroupForm.value.cod = this.cod;
   // }
    this.dataApi.updateSubgroup(subgroupForm.value);

    this.getFamily(subgroupForm.value.id, subgroupForm.value.name);
    this.getTask(subgroupForm.value.id, subgroupForm.value.name);
    this.getDosage(subgroupForm.value.id, subgroupForm.value.name);
    this.msj.mensajeAdvertencia('Edito', 'Se edito correctamente');
    }
    subgroupForm.resetForm();
    this.btnClose.nativeElement.click(); // cierra el modal
  }

  getFamily(id: string, name: string) {
    this.dataApi.getSelectCboFamily(id).subscribe( family => {
      if (family.length > 0) {
        family.forEach(items => {
          if (id === items.subgroupId) {
            this.family.id = items.id;
            this.family.subgroup =  name
            this.dataApi.updateSubgroup(this.family);
          }
        });
      }
     });
  }

  getTask(id: string, name: string) {
    this.dataApi.getAllTaskSubGroup(id).subscribe( family => {
      if (family.length > 0) {
        family.forEach(items => {
          if (id === items.subgroupId) {
            this.task.id = items.id;
            this.task.subgroup =  name
            this.dataApi.updateTask(this.task);
          }
        });
      }
     });
  }

  getDosage(id: string, name: string) {
    this.dataApi.getAllDosageSubGroup(id).subscribe( task => {
      if (task.length > 0) {
        task.forEach(items => {
          if (id === items.subgroupId) {
            this.dosage.id = items.id;
            this.dosage.subgroup = name;
            this.dataApi.updateDosage(this.dosage);
          }
        });
      }
     });
  }

}
