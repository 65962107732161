import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-mnucatalogo',
  templateUrl: './mnucatalogo.component.html',
  styleUrls: ['./mnucatalogo.component.css']
})
export class MnucatalogoComponent implements OnInit {

  public isAadmin: any = null;
  public isAdmin: any = null;
  public isEditor: any = null;
  public isCliente: any = null;
  public userUid: string;

  constructor(private authService: AuthService, private afsAuth: AngularFireAuth) { }

  ngOnInit() {
    this.getCurrentUser();
  }

  getCurrentUser() {
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
         this.userUid = auth.uid;
         this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
             this.isAadmin = Object.assign({}, userRole.roles).hasOwnProperty('Aadmin');
             this.isAdmin = Object.assign({}, userRole.roles).hasOwnProperty('admin');
             this.isEditor = Object.assign({}, userRole.roles).hasOwnProperty('editor');
             this.isCliente = Object.assign({}, userRole.roles).hasOwnProperty('cliente');

         });
      }
    });
  }

}
