import { Component, OnInit } from '@angular/core';
import { DataApiService } from '../../../services/data-api.service';
import { DosageInterface } from '../../../models/dosage';
// Para ver los roles de ususarioa
import { AuthService } from '../../../services/auth.service';
import * as printJS from 'print-js';

@Component({
  selector: 'app-list-dosage',
  templateUrl: './list-dosage.component.html',
  styleUrls: ['./list-dosage.component.css']
})
export class ListDosageComponent implements OnInit {

  constructor(private dataApi: DataApiService, private authService: AuthService) { }
  public dosages: DosageInterface[];
  public isAadmin: any = null;
  public isAdmin: any = null;
  public isEditor: any = null;
  public userUid: string;
  public dosage: string;
  public dosageId: string;
  public customer: string;
  public customersId: string;
  public serviceId: string;
  public cod: string;
  public service: string;
  public groupId: string;
  public group: string;
  public subgroupId: string;
  public subgroup: string;
  public dateStart: string;
  public dateEnd: string;
  public emailCust: string;
  public isError = false;

  public userUidName: string = '';
  public userUidEmail: string = '';
  public emailCC: string = '';
  public nameCC: string = '';
  public razon: string = '';

  public isUpdate: boolean = false;
  public isMovimiento: boolean = false;
  public idDosage: string = '';

  filterpost = '';

  pageActual: number = 1;

  ngOnInit() {

    this.getCurrentUser();
  }

  validarUpdate(id: string) {
    // this.dataApi.getMessageDosageId(id).subscribe( message => {
    this.dataApi.getAllTasksDosage(id).subscribe( tasks => {
      if (tasks.length > 0) {
        this.isMovimiento = true;
      } else {
        this.isMovimiento = false;
      }
    });
  }

  nuevo() {
    this.isUpdate = false;
    this.isMovimiento = false;
  }

  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
        this.userUidEmail = auth.email;
        this.getDataUser(this.userUid);
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
            this.isAadmin = Object.assign({}, userRole.roles).hasOwnProperty('Aadmin');
            this.isAdmin = Object.assign({}, userRole.roles).hasOwnProperty('admin');
            this.isEditor = Object.assign({}, userRole.roles).hasOwnProperty('Editor');
            if (this.isAdmin === true) {
              this.getListDosageUid(this.userUid);
            }
            if (this.isAadmin === true) {
              this.getListDosage();
            }
        });
      }
    });
  }

  getListDosage() {
    this.dataApi.getAllDosage().subscribe( dosages => {
      this.dosages = dosages;
      dosages.forEach(dat => {
        this.validarNew(dat.id);
      });
    });
  }

  getListDosageUid(id: string) {
    this.dataApi.getAllDosageUid(id).subscribe( dosages => {
      this.dosages = dosages;
      dosages.forEach(dat => {
        this.validarNew(dat.id);
      });
    });
  }

  validarNew(id: string) {
    this.dataApi.getAllTasksDosage(id).subscribe(task => {
       task.forEach(dat => {
         this.idDosage = dat.dosageId;
       });
    });
  }



  getDataUser(id: string) {
    this.dataApi.getAllDataUserUid(id).subscribe( datauser => {
      datauser.forEach(dat => {
        this.userUidName = dat.name;
      });
    });
  }

  onOneDosage(id: string) {
    this.dataApi.getOneDosage(id).subscribe( dosage => {
         this.dosage = dosage.id;
         this.dosageId = dosage.id;
         this.customer = dosage.customer;
         this.customersId = dosage.customersId;
         this.getOneCustomer(this.customersId);
         this.service = dosage.service;
         this.serviceId = dosage.familyId;
         this.dateStart = dosage.dateStart;
         this.dateEnd = dosage.dateFinish;
         this.groupId = dosage.groupId;
         this.group = dosage.group;
         this.subgroupId = dosage.subgroupId;
         this.subgroup = dosage.subgroup;
         this.emailCust = dosage.emailCust;
         this.cod = dosage.cod;

    });
  }

  getOneCustomer(idCustomers: string) {
    if (idCustomers != null) {
      this.dataApi.getOneCustomer(idCustomers).subscribe( customer => {
        if (customer) {
          this.emailCC = customer.emailCC;
          this.nameCC = customer.nameCC;
          this.razon = customer.company;
        }
      });
    }
  }


  // no se elimina porque ya se mando un mensaje a su correo del cliente
  onDeleteDosage(idDosage: string) {
    const confirmacion = confirm('Esta seguro ?');
    if (confirmacion) {
      this.dataApi.getMessageDosageId(idDosage).subscribe( message => {
        if (message.length > 0) {
          this.onisError();
        } else {
          this.dataApi.deleteDosage(idDosage);
        }
      });
    }
  }

  onisError(): void {
    this.isError = true;
    setTimeout(() => {
      this.isError = false;
    }, 3000);
  }

   // carga el update al modal
   onPreUpdateDosage(dosage: DosageInterface) {
    this.isUpdate = true;
    this.dataApi.selectDosage = Object.assign({}, dosage);
  }

  downloadPdf() {
    printJS({printable: this.dosages, properties: [
      {field: 'company', displayName: 'Razón'},
      {field: 'nit', displayName: 'CI/NIT'},
      {field: 'service', displayName: 'Servicio'},
      {field: 'num_service', displayName: 'Nro. Servicio'},
      {field: 'dateFinish', displayName: 'Fecha Limite'}
    ], type: 'json',
       documentTitle: 'Control de Trámites de ServiContable13 Srl.',
       header: '<h4 class="custom-h4">Trámite de Nro. Documentación: </h4>' + '<br><br>',
       headerStyle: 'font-weight: 300;',
       font: 'Century Gothic',
       style: '.custom-h4 { color: blue; }',
       gridHeaderStyle: 'font-weight: bold; padding: 5px; border: 1px solid #dddddd;'});
  }


}
