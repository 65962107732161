import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { DataApiService } from '../../../services/data-api.service';
import { DosageInterface } from '../../../models/dosage';
import { NgForm } from '@angular/forms';
import { CustomersInterface } from '../../../models/customers';
import * as moment from 'moment';
moment.locale('es');
import 'moment/locale/bo';
import { AuthService } from '../../../services/auth.service';
import { ServicesInterface } from '../../../models/services';
import { DataUserInterface } from '../../../models/dataUser';
import Swal from 'sweetalert2';
import { MensajesService } from '../../../services/mensajes.service';



@Component({
  selector: 'app-modal-dosage',
  templateUrl: './modal-dosage.component.html',
  styleUrls: ['./modal-dosage.component.css']
})
export class ModalDosageComponent implements OnInit {
  constructor(public dataApi: DataApiService,
              public authService: AuthService,
              private msj: MensajesService) {}

  @ViewChild('btnClose', { static: false }) btnClose: ElementRef;
  @Input() userUid: string; // para pasar al modal el userid logueado
  @Input() isUpdate: boolean;
  @Input() isMovimiento: boolean;
  @Input() userUidEmail: string;
  @Input() userUidName: string;


  public customers: CustomersInterface[];
  public servicesRoles: ServicesInterface[];
  public dataUsers: DataUserInterface[];

  public customer: string = '';
  public company: string = '';
  public nit: string = '';
  public service: string = '';

  public confCustomer: boolean = false;
  public confService: boolean = false;

  public cod: string = '';
  public groupId: string = '';
  public subgroupId: string = '';
  public group: string = '';
  public subgroup: string = '';
  public emailCust: string;
  public emailCC: string;

  public isAdmin: any = null;

  public confirepeat: boolean = false;
  public isError = false;
  public isErrorMsg = false;
  public isErrorNum = false;


  ngOnInit() {
    this.getCurrentUser();
    this.getListCustomers();
  }

  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
          this.isAdmin = Object.assign({}, userRole.roles).hasOwnProperty('admin');
          if (this.isAdmin === true) {
            this.getSelectUser(this.userUid); // carga el listado de los servicios que le corresponde
          }
        });
      }
    });
  }

  // Busca o carga el listad de todo los servicios que se le fueron asigando
  getSelectUser(id: string) {
          this.dataApi.getServicesSearch(id).subscribe(services => {
            this.servicesRoles = services;
          });
  }

    // busca por el id todo los servicios asignado por el jefe funcionario
  onSelectServices(idService: string) {
        this.dataApi.getOneServices(idService).subscribe(service => {
            this.groupId = service.groupId;
            this.group = service.group;
            this.subgroupId = service.subgroupId;
            this.subgroup = service.subgroup;
            this.service = service.name;
            this.cod = service.cod;
            this.confService = true;
        });
  }


  onisError(): void {
    this.isError = true;
    setTimeout(() => {
      this.isError = false;
    }, 3000);
  }

  repeat() {
    this.confirepeat = true;
  }

  getListCustomers() {
    this.dataApi.getSelectCustomer().subscribe(customers => {
      this.customers = customers;
    });
  }

  getOneCustomer(idCustomers: string) {
    if (idCustomers !== null || idCustomers !== ''){
      this.dataApi.getOneCustomer(idCustomers).subscribe(customer => {
        this.confCustomer = true;
        this.customer = customer.name;
        this.company = customer.company;
        this.nit = customer.nit;
        this.emailCust = customer.email;
        this.emailCC = customer.emailCC;
      });
    }

  }

  resetForm(formDosage: NgForm) {
    formDosage.resetForm(); // para limpiar el formulario
  }

  onSaveDosage(dosageForm: NgForm): void {
    // new
    this.isErrorMsg = false;
    this.isErrorNum = false;
    if (dosageForm.value.id == null) {
      // valida quelas feha inicial no sea mayor que fecha limite
      //  new Date(this.fechafinal) > new Date()
     //if (moment(dosageForm.value.dateStart).format('dd-MM-yyyy') > moment(dosageForm.value.dateFinish).format('dd-MM-yyyy')) {
        //this.onisError();
        //this.isErrorMsg = true;
       // return;
      //}
      // valida que el ultimo aviso de notificacion no sea mayor que el primer aviso
      if (dosageForm.value.max > dosageForm.value.min) {
        this.onisError();
        this.isErrorNum = true;
        return;
      }
      dosageForm.value.userUid = this.userUid; // guarda en la coleecion de firebase
      dosageForm.value.nit = this.nit;
      dosageForm.value.emailCust = this.emailCust;
      dosageForm.value.emailCC = this.emailCC;
      dosageForm.value.userUidEmail = this.userUidEmail;
      dosageForm.value.userUidName = this.userUidName;
      dosageForm.value.customer = this.customer;
      dosageForm.value.company = this.company;
      dosageForm.value.groupId = this.groupId;
      dosageForm.value.group = this.group;
      dosageForm.value.subgroupId = this.subgroupId;
      dosageForm.value.subgroup = this.subgroup;
      dosageForm.value.cod = this.cod;
      dosageForm.value.service = this.service;
      if (this.confirepeat === false) {
        dosageForm.value.repeat = this.confirepeat;
      }
      this.dataApi.addDosage(dosageForm.value);
      this.msj.mensajeCorrecto('Taracoe' , 'Se agrego correctamente');
    } else {
      // update
      if (this.confCustomer) {
        dosageForm.value.customer = this.customer;
        dosageForm.value.company = this.company;
        dosageForm.value.nit = this.nit;
        dosageForm.value.emailCust = this.emailCust;
        dosageForm.value.emailCC = this.emailCC;
      }
      if (this.confService) {
        dosageForm.value.groupId = this.groupId;
        dosageForm.value.group = this.group;
        dosageForm.value.subgroupId = this.subgroupId;
        dosageForm.value.subgroup = this.subgroup;
        dosageForm.value.cod = this.cod;
        dosageForm.value.service = this.service;
      }
      this.dataApi.updateDosage(dosageForm.value);
      this.msj.mensajeAdvertencia('Taracoe', 'Se edito correctamente');
    }
    dosageForm.resetForm();
    this.btnClose.nativeElement.click(); // cierra el modal
  }
}
