import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { DataApiService } from '../../../services/data-api.service';
import { NgForm } from '@angular/forms';
import { PositionInterface } from '../../../models/position';
import { AuthService } from '../../../services/auth.service';
import Swal from 'sweetalert2';
import { MensajesService } from 'src/app/services/mensajes.service';
import { Roles } from '../../../models/user';


@Component({
  selector: 'app-modal-data-user',
  templateUrl: './modal-data-user.component.html',
  styleUrls: ['./modal-data-user.component.css']
})
export class ModalDataUserComponent implements OnInit {

  constructor(public dataApi: DataApiService,
              private authService: AuthService,
              private msj: MensajesService) { }
  @ViewChild('btnClose', { static: false}) btnClose: ElementRef;
  @Input() userUid: string;
  @Input() email: string;
  @Input() isUpdate: boolean;
  @Input() isMovimiento: boolean;


  public positions: PositionInterface[];
  public positionId: string = '';
  public position: string = '';
  public confirposition: boolean = false;

  public userId: string;
  public nivel: string;
  public isAdmin: any = null;
  public isAadmin: any = null;
  public isEditor: any = null;

  ngOnInit() {
    this.getCurrentUser();
    this.getListPositionAll();
    if (this.isUpdate === true) {
     this.getListPositionAll();
    }
  }

  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userId = auth.uid;
        this.authService.isUserAdmin(this.userId).subscribe(userRole => {
          this.isAadmin = Object.assign({}, userRole.roles).hasOwnProperty('Aadmin');
          this.isAdmin = Object.assign({}, userRole.roles).hasOwnProperty('admin');
          this.isEditor = Object.assign({}, userRole.roles).hasOwnProperty('editor');
          this.nivel = userRole.nivel;
          if (this.nivel != null) {
            if (this.isUpdate === false) {
              this.getListPosition(this.nivel);
            }
          }
        });
      }
    });
  }

  // carga al registrar el propio usuario sus datos
  getListPosition(id: string) {
    this.dataApi.getPositionNivel(id).subscribe( positions => {
      positions.forEach(position => {
        this.position = position.name;
        this.positionId = position.id;
      });

    });
  }

  // carga al actualizar su rol
  getListPositionAll() {
    this.dataApi.getAllPosition().subscribe( positions => {
      this.positions = positions;
    });
  }


  onePosition(id: string) {
    this.dataApi.getOnePosition(id).subscribe( position => {
      this.position = position.name;
      this.nivel = position.nivel;
      this.confirposition = true;
    });
  }


  onSaveDataUser(formDataUser: NgForm): void {
    if (formDataUser.value.id == null) {
      // new
      formDataUser.value.nivel = this.nivel;
      formDataUser.value.userUid = this.userUid;
      formDataUser.value.positionId = this.positionId;
      formDataUser.value.position = this.position;
      this.dataApi.addDataUser(formDataUser.value);
      this.msj.mensajeCorrecto('Agrego', 'Se agrego correctamente');
    } else {
      // update
      if (this.confirposition === true) {
        formDataUser.value.nivel = this.nivel;
        formDataUser.value.position = this.position;
      }
      this.dataApi.updateDataUser(formDataUser.value);
      if (this.confirposition === true) {
        this.dataApi.getOneDataUser(formDataUser.value.id).subscribe(dataUser => {
          console.log('AQUI', dataUser.userUid);
          // update los roles si cambia de cargo
          this.authService.UpdateRoles(dataUser.userUid, this.nivel);
        });
      }
      this.msj.mensajeAdvertencia('Edito', 'Se edito correctamente');
    }
    formDataUser.resetForm(); // limpiar el formulario
    this.btnClose.nativeElement.click(); // cierra el modal
  }

  resetForm(formDataUser: NgForm) {
    formDataUser.resetForm(); // para limpiar el formulario
  }


}
