import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import { SubFamilyInterface } from '../models/subfamily';


@Injectable({
  providedIn: 'root'
})
export class SubfamilyService {
  private subserviceCollection: AngularFirestoreCollection<SubFamilyInterface>;
  private subservices: Observable<SubFamilyInterface[]>;
  private subserviceDoc: AngularFirestoreDocument<SubFamilyInterface>;
  private subservice: Observable<SubFamilyInterface>;

  constructor(
     // llama a firebase
     private afs: AngularFirestore
  ) {
    this.subserviceCollection = this.afs.collection<SubFamilyInterface>('subfamily');
  }

  public getAllSubService(): Observable<SubFamilyInterface[]> {
    this.subserviceCollection = this.afs.collection<SubFamilyInterface>('subfamily');
    return this.subserviceCollection
      .snapshotChanges()
      .pipe(map(actions => actions.map(a => {
        const data = a.payload.doc.data() as SubFamilyInterface;
        const id = a.payload.doc.id;
        return { id, ...data};
      })));
  }

  getAllServiceFamily(id: string) {
    this.subserviceCollection = this.afs.collection<SubFamilyInterface>('subfamily', ref => ref.where('familyId', '==', id));
    return this.subservices = this.subserviceCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as SubFamilyInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }

  getOneSpeciaDet(id: string) {
    this.subserviceDoc = this.afs.doc<SubFamilyInterface>(`subfamily/${id}`);
    return this.subservice  = this.subserviceDoc.snapshotChanges().pipe(map(action => {
      if (action.payload.exists === false) {
        return null;
      } else {
        const data = action.payload.data() as SubFamilyInterface;
        data.id = action.payload.id;
        return data;
      }
    }));
  }

  public deleteSubFamily(subservice: SubFamilyInterface) {
     return this.subserviceCollection.doc(subservice.id).delete();
  }

  public editSubServiceDetByid(subservice: SubFamilyInterface) {
    if (subservice) {
      return this.subserviceCollection.doc(subservice.id).update(subservice);
    }
  }


  public addSubService(subservice: SubFamilyInterface) {
    this.saveSpecial(subservice);
  }

  private saveSpecial(subservice: SubFamilyInterface) {
    const specialObj = {
      familyId: subservice.familyId,
      familyDes: subservice.familyDes,
      name: subservice.name,
      userUid: subservice.userUid
    };

    if (subservice.id) {
      return this.subserviceCollection.doc(subservice.id).update(specialObj);
    } else {
      return this.subserviceCollection.add(specialObj);
    }

  }
}
