import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserInterface } from '../../models/user';
import { DataApiService } from '../../services/data-api.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(private authService: AuthService, private afsAuth: AngularFireAuth, private dataApi: DataApiService) { }
    public app_name: string = 'ServiContable13';
    public isLogged: boolean = false;
    public isLoggedCliente: boolean = false;
    public isAadmin: any = null;
    public isAdmin: any = null;
    public isEditor: any = null;
    public isCliente: any = null;
    public userUid: string;
    public datauser: string = '';
    public verification: boolean;

    user: UserInterface = {
      name: '',
      email: '',
      photoUrl: '',
      roles: {}
    };

    public providerId: string = 'null'; // probar si esta logueado con redes sociales
  ngOnInit() {
    this.verification = false;
    this.onLogout();
    this.getCurrentUser();
    this.onProfile();
  }

  getCurrentUser() {
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
         this.isLogged = true;
         this.userUid = auth.uid;
         this.onDataUser(this.userUid);
         this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
             this.isAadmin = Object.assign({}, userRole.roles).hasOwnProperty('Aadmin');
             this.isAdmin = Object.assign({}, userRole.roles).hasOwnProperty('admin');
             this.isEditor = Object.assign({}, userRole.roles).hasOwnProperty('editor');
             this.isCliente = Object.assign({}, userRole.roles).hasOwnProperty('cliente');
             if ( this.isCliente === true) {
              this.isLoggedCliente = true;
             }
         });

      } else {
        this.isLogged = false;
      }
    });
  }

  onDataUser(id: string) {
   this.dataApi.getAllDataUserUid(id).subscribe( datauser => {
     if (datauser) {
      datauser.forEach(dat => {
        this.datauser = dat.position;
      });
     } else {
      this.datauser = '';
     }

   });
  }

  onLogout() {
    this.afsAuth.auth.signOut();
  }

  onProfile() {
    this.authService.isAuth().subscribe(user => {
      if (user && user.emailVerified) {
        this.user.email = user.email;
        this.user.photoUrl = user.photoURL;
        this.providerId = user.providerData[0].providerId;
        this.verification = true;
      } else {
        this.verification = false;
      }
    });
  }
}
