import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataApiService } from '../../../services/data-api.service';
import { BlogInterface } from '../../../models/blog';
import {formatDate} from '@angular/common';
import Swal from 'sweetalert2';
import { MensajesService } from 'src/app/services/mensajes.service';


@Component({
  selector: 'app-modal-blog',
  templateUrl: './modal-blog.component.html',
  styleUrls: ['./modal-blog.component.css']
})
export class ModalBlogComponent implements OnInit {

  constructor(public dataApi: DataApiService, private msj: MensajesService) { }
  @ViewChild('btnClose', { static: false}) btnClose: ElementRef;
  @Input() userUid: string; // para pasar al modal el userid logueado
  public finalizado: string = formatDate(new Date(), 'yyyy/MM/dd, h:mm:ss a', 'en');

  ngOnInit() {
  }

  onSaveBlog(blogForm: NgForm): void {
    if (blogForm.value.id == null) {
      // new
      blogForm.value.userUid = this.userUid;
      blogForm.value.date = this.finalizado;
      this.dataApi.addBlog(blogForm.value);
      this.msj.mensajeCorrecto('Agrego', 'Se agrego correctamente');
    } else {
      // update
      this.dataApi.updateBlog(blogForm.value);
      this.msj.mensajeAdvertencia('Edito', 'Se edito correctamente');
    }
    blogForm.resetForm(); // limpiar el formulario
    this.btnClose.nativeElement.click(); // cierra el modal
  }

  resetForm(blogForm: NgForm) {
    blogForm.resetForm(); // para limpiar el formulario
  }

}
