import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { DataApiService } from '../../../services/data-api.service';
import { ActivityInterface } from '../../../models/activity';
import { NgForm } from '@angular/forms';
import { FamilyInterface } from '../../../models/family';
import Swal from 'sweetalert2';
import { MensajesService } from 'src/app/services/mensajes.service';


@Component({
  selector: 'app-modal-activity',
  templateUrl: './modal-activity.component.html',
  styleUrls: ['./modal-activity.component.css']
})
export class ModalActivityComponent implements OnInit {

  constructor(public dataApi: DataApiService, private msj: MensajesService) { }
  public familys: FamilyInterface[];
  @ViewChild('btnClose', { static: false}) btnClose: ElementRef;
  @Input() userUid: string; // para pasar al modal el userid logueado
  @Input() isUpdate: boolean;
  public confirmar: boolean = false;
  public service: string = '';

  ngOnInit() {
    this.getListFamily();
  }

  getOneService(idFamily: string) {
    this.dataApi.getOneFamily(idFamily).subscribe( service => {
      if (service) {
        this.confirmar = true;
        this.service = service.cod + " " + service.name;
      }
    });
}

  getListFamily() {
    this.dataApi.getSelectFamily().subscribe( familys => {
      this.familys = familys;
    });
  }

  resetForm(formActivity: NgForm) {
    formActivity.resetForm(); // para limpiar el formulario
  }

  onSaveActivity(activityForm: NgForm): void {
    // new
    if (activityForm.value.id == null) {
      activityForm.value.userUid = this.userUid; // guarda en la coleecion de firebase
      activityForm.value.service = this.service; // nombre del servicio
      this.dataApi.addActivity(activityForm.value);
      this.msj.mensajeCorrecto('Agrego', 'Se agrego correctamente');
    } else {
    // update
    if (this.confirmar) {
      activityForm.value.service = this.service; // nombre del servicio
    }

    this.dataApi.updateActivity(activityForm.value);
    this.msj.mensajeCorrecto('Edito', 'Se edito correctamente');
    }
    activityForm.resetForm();
    this.btnClose.nativeElement.click(); // cierra el modal
  }

}
