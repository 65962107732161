import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { DataApiService } from '../../../services/data-api.service';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { MensajesService } from 'src/app/services/mensajes.service';
import { TaskInterface } from 'src/app/models/task';
import { TaskDetailsInterface } from 'src/app/models/task_details';
import { DosageInterface } from 'src/app/models/dosage';


@Component({
  selector: 'app-modal-customer',
  templateUrl: './modal-customer.component.html',
  styleUrls: ['./modal-customer.component.css']
})
export class ModalCustomerComponent implements OnInit {
  public task: TaskInterface = {};
  public taskOb: TaskDetailsInterface = {};
  public dosage: DosageInterface = {};
  message;
  token: string;
  public ID: string = null;

  public name: string;
  public company: string;
  public nit: string;
  public address: string;
  public phone: string;
  public mobil: string;
  public emailC: string;
  public emailCC: string;
  public nameCC: string;

  constructor(public dataApi: DataApiService, private msj: MensajesService) { }
  @ViewChild('btnClose', { static: false}) btnClose: ElementRef;
  @Input() userUid: string; // para pasar al modal el userid logueado
  @Input() email: string;
  @Input() isMovimiento: boolean;

  ngOnInit() {
  }

  onSaveCustomer(customerForm: NgForm): void {
    Swal.fire({
      title: 'TARACOE?',
      text: `Desea guardar!`,
      imageUrl: 'https://firebasestorage.googleapis.com/v0/b/taracoe-3f6ba.appspot.com/o/FIAMImages%2Fpush.png?alt=media&token=01ee26c5-6e61-451e-9740-f3f2bd76acba',
      imageHeight: 50,
      imageWidth: 70,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#FF0000',
      confirmButtonText: 'Guardar!',
     }).then ( result => {
       if (result.value) {
        this.ID = customerForm.value.id;
        if (customerForm.value.id == null) {
          customerForm.value.userUid = this.userUid;
          customerForm.value.email = this.email;
          customerForm.value.activo = true;
          this.dataApi.addCustomer(customerForm.value);
          this.msj.mensajeCorrecto('Taracoe', 'Se agrego correctamente');

        } else {
          // update
          this.dataApi.updateCustomer(customerForm.value);
          this.getOneTask(this.ID);
          this.getOneTaskOb(this.ID);
          this.getOneDosage(this.ID);
          this.msj.mensajeAdvertencia('Taracoe', 'Se edito correctamente');
        }
        customerForm.resetForm(); // limpiar el formulario
        this.btnClose.nativeElement.click(); // cierra el modal

       }
     });

  }

  // Actualiza la tabla tarea de los datos del cliente
  getOneTask(id: string) {
      this.dataApi.getOneCustomer(id).subscribe(customer => {

           this.name = customer.name;
           this.address = customer.address;
           this.company = customer.company;
           this.nit = customer.nit;
           this.phone = customer.phone;
           this.nameCC = customer.nameCC;
           this.emailC = customer.email;
           this.emailCC = customer.emailCC;

           this.dataApi.getAllTasksCustomerId(id).subscribe( task => {
            if (task.length > 0) {
              task.forEach(items => {
                if (id === items.customersId) {
                  this.task.id = items.id;
                  this.task.customer = this.name;
                  this.task.razon = this.company;
                  this.task.emailCust = this.emailC;
                  this.task.emailCC = this.emailCC;
                  this.task.nameCC = this.nameCC;
                 this.dataApi.updateTask(this.task);
                }
              });
            }
           });

      });

  }

  // Actualia la tabla de obsevaciones de los datos de los clientes
  getOneTaskOb(id: string) {
    this.dataApi.getOneCustomer(id).subscribe(customer => {

         this.name = customer.name;
         this.address = customer.address;
         this.company = customer.company;
         this.nit = customer.nit;
         this.phone = customer.phone;
         this.nameCC = customer.nameCC;
         this.emailC = customer.email;
         this.emailCC = customer.emailCC;

         this.dataApi.getAllTaskDetailUser(id).subscribe( taskOb => {

          if (taskOb.length > 0) {
            taskOb.forEach(items => {
              if (id === items.customersId) {
                this.taskOb.id = items.id;
                this.taskOb.customer = this.name;
                this.taskOb.razon = this.company;
                this.taskOb.emailCC = this.emailCC;
                this.taskOb.nameCC = this.nameCC;
                this.dataApi.updateTaskOb(this.taskOb);
              }
            });
          }
         });

    });
  }

  // Actualización la tabla dosage de los datos del cliente
  getOneDosage(id: string) {

    this.dataApi.getOneCustomer(id).subscribe(customer => {

         this.name = customer.name;
         this.address = customer.address;
         this.company = customer.company;
         this.nit = customer.nit;
         this.phone = customer.phone;
         this.nameCC = customer.nameCC;
         this.emailC = customer.email;
         this.emailCC = customer.emailCC;

         this.dataApi.getAllDosageCliente(id).subscribe( dosage => {
          if (dosage.length > 0) {
            dosage.forEach(items => {
              if (id === items.customersId) {
                this.dosage.id = items.id;
                this.dosage.customer = this.name;
                this.dosage.company = this.company;
                this.dosage.emailCC = this.emailCC;
                this.dosage.nit = this.nit;
                this.dataApi.updateDosage(this.dosage);
              }
            });
          }
         });

    });
  }

  updatetaskCustomer(task) {
    console.log('UPDATE: ',  task);
  }

  resetForm(formCustomer: NgForm) {
    formCustomer.resetForm(); // para limpiar el formulario
  }

}
