import { Component, OnInit } from '@angular/core';
import { DataApiService } from '../../../services/data-api.service';
import { AuthService } from '../../../services/auth.service';
import { DataUserInterface } from '../../../models/dataUser';
import * as printJS from 'print-js';

@Component({
  selector: 'app-list-data-user',
  templateUrl: './list-data-user.component.html',
  styleUrls: ['./list-data-user.component.css']
})
export class ListDataUserComponent implements OnInit {

  constructor(private dataApi: DataApiService, private authService: AuthService) { }
  public dataUsers: DataUserInterface[];
  public tasks = [];
  public task: '';
  public isAdmin: any = null;
  public isAadmin: any = null;
  public isEditor: any = null;
  public dataUserId: string;
  public userUid: string;
  public email: string;
  public mostrar: Boolean = false;
  public isUpdate: boolean = false;
  public isError = false;
  public isMovimiento: boolean = false;

  pageActual: number = 1;

  ngOnInit() {
    this.getCurrentUser();
  }

  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
        this.email = auth.email;
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
          this.isAadmin = Object.assign({}, userRole.roles).hasOwnProperty('Aadmin');
          this.isAdmin = Object.assign({}, userRole.roles).hasOwnProperty('admin');
          this.isEditor = Object.assign({}, userRole.roles).hasOwnProperty('editor');
          if (this.isAadmin === true) {
            this.getListDataUser(); // todos los clientes
          }
          if (this.isAdmin === true) {
            this.getListDataUser(); // todos los clientes segun el jefe funcionario FALTA
          }
          if (this.isEditor === true) {
            this.getDataUser(this.userUid);
          }

        });
      }
    });
  }


  validarUpdate(id: string) {
    this.dataApi.getOneDataUser(id).subscribe(dataUser => {
      if (dataUser.userUid !== null) {
        // SI EL CARGO ES ADMINISTRADOR
        if (dataUser.positionId === 'razoAIzQJ25v4z7BNzFr') {
          this.dataApi.getAllTasksUserAdm(dataUser.userUid).subscribe(task => {
            if (task.length > 0) {
               this.isMovimiento = true;
            } else {
               this.isMovimiento = false;
            }
          });
        } else {
          // SI EL CARGO ES SPERVISOR O FUNCIONARIO
          this.dataApi.getAllTasksUser(dataUser.userUid).subscribe(task => {
            if (task.length > 0) {
               this.isMovimiento = true;
            } else {
               this.isMovimiento = false;
            }
          });

        }
      }
    });
  }

  getListDataUser() {
    this.dataApi.getAllDataUser().subscribe(dataUsers => {
      if (dataUsers) {
        this.dataUsers = dataUsers;
        this.getDataUserAll(this.userUid);
      }
    });
  }

  getDataUserAll(id: string) {
    this.dataApi.getAllDataUserUid(id).subscribe(dataUsers => {
      if (dataUsers.length > 0) {
        this.mostrar = true;
      } else {
        this.mostrar = false;
      }
    });
  }

  // este es para cuando el usuario ya registre su dato no lo vuelva hacer
  getDataUser(id: string) {
    this.dataApi.getAllDataUserUid(id).subscribe(dataUsers => {
      if (dataUsers.length > 0) {
        this.dataUsers = dataUsers;
        this.mostrar = true;
      } else {
        this.mostrar = false;
      }
    });
  }

  onDeleteDataUser(idUserData: string) {
    const confirmacion = confirm('Estás seguro ?');
    if (confirmacion) {
      this.dataApi.getOneDataUser(idUserData).subscribe(dataUser => {
        if (dataUser.userUid !== null) {
          // SI EL CARGO ES ADMINISTRADOR
          if (dataUser.positionId === '2FwWUrhOn8iuiHZykxr9') {
            this.dataApi.getAllTasksUserAdm(dataUser.userUid).subscribe(task => {
              if (task.length > 0) {
                 this.onisError();
              } else {
                 this.dataApi.deleteDataUser(idUserData);
              }
            });
          } else {
            // SI EL CARGO ES SPERVISOR O FUNCIONARIO
            this.dataApi.getAllTasksUser(dataUser.userUid).subscribe(task => {
              if (task.length > 0) {
                 this.onisError();
              } else {
                 this.dataApi.deleteDataUser(idUserData);
              }
            });

          }
        }
      });
    }
  }

  onisError(): void {
    this.isError = true;
    setTimeout(() => {
      this.isError = false;
    }, 3000);
  }

  // carga la informacion al modal para el update
  onPreUpdateDataUser(dataUser: DataUserInterface) {
    this.isUpdate = true;
    this.dataApi.selectDataUser = Object.assign({}, dataUser);

  }

  downloadPdf() {
    printJS({printable: this.dataUsers, properties: [
      {field: 'position', displayName: 'Cargo'},
      {field: 'name', displayName: 'Funcionario'},
      {field: 'address', displayName: 'Dirección'},
      {field: 'identity', displayName: 'Identidad'},
      {field: 'phone', displayName: 'Teléfono'},
      {field: 'datenac', displayName: 'Nacimiento'},
      {field: 'datestart', displayName: 'Fecha'}
    ], type: 'json',
       documentTitle: 'Control de Trámites de ServiContable13 Ltda.',
       header: '<h4 class="custom-h4">Funcionario Detalle: </h4>' + '<br><br>',
       headerStyle: 'font-weight: 300;',
       font: 'Century Gothic',
       style: '.custom-h4 { color: blue; }',
       gridHeaderStyle: 'font-weight: bold; padding: 5px; border: 1px solid #dddddd;'});
  }

}
