import { Component, OnInit } from '@angular/core';
import { DataApiService } from '../../services/data-api.service';
import { AuthService } from '../../services/auth.service';
import * as moment from 'moment';
import 'moment/locale/bo';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, DocumentReference } from '@angular/fire/firestore';
import {formatDate} from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.css']
})
export class TaskComponent implements OnInit {
  message;
  constructor(private dataApi: DataApiService, private authService: AuthService, private afs: AngularFirestore) { }
  public tasks = [];
  public dosages = [];
  public task = '';
  public isAdmin: any = null;
  public isAadmin: any = null;
  public isEditor: any = null;
  public isCliente: any = null;
  public isNivel: boolean = false;
  public userUid: string;

  public customerId: string;
  public customer: string;
  public familyId: string;
  public service: string;
  public groupId: string;
  public group: string;
  public subgroupId: string;
  public subgroup: string;
  public num_service: string;
  public userUidUser: string;
  public repeat: boolean;

  public isNew: boolean = false;
  public istasks: boolean = false;

  // VALIDACION DE NOTIFICACIONES DE VENCIMIENTOS
  public fechafinal: string;
  public fechainicial: string;
  public dia_formato: string;
  // mensaje de notificacion de fecha limite
  public day: number;
  public name: string;
  public nameUser: string;
  public emailCust: string;
  public emailCC: string;
  public emailFun: string;
  public tasksId: string;
  public dosageId: string;
  public id: string = null;
  public min: number = 0;
  public max: number = 0;

  ngOnInit() {
    this.getVencTask();
    this.getVencDosage();
    this.getCurrentUser();

  }


  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
            this.isAadmin = Object.assign({}, userRole.roles).hasOwnProperty('Aadmin');
            this.isAdmin = Object.assign({}, userRole.roles).hasOwnProperty('admin');
            this.isEditor = Object.assign({}, userRole.roles).hasOwnProperty('editor');
            this.isCliente = Object.assign({}, userRole.roles).hasOwnProperty('cliente');
            if (this.isAadmin === true) {
              this.isNivel = true;
              this.getAllTaks();
              return;
            }
            if (this.isAdmin === true) {
              this.isNivel = true;
              this.getAllTaks();
              this.getListTasksAdmin(this.userUid);
              return;
            }
            if (this.isEditor === true) {
              this.isNivel = true;
              this.getOneUser(this.userUid);
              return;
            }
            if (this.isCliente === true) {
              this.getCustomer(this.userUid);
              return;
            }
        });
      }
    });
  }
  // jefe encargado que es admin
  getListTasksAdmin(id: string) {
    this.dataApi.getAllTasksUserAdm(id).subscribe(tasks => {
      if (tasks) {
        this.tasks = tasks;
      }
    });
  }

  // BUSCA POR USUARIO LOGUEADO encargado
  getOneUser(idUser: string) {
    this.dataApi.getAllTasksUser(idUser).subscribe(tasks => {
      if (tasks) {
        this.tasks = tasks;
      }
    });
  }

  // BUSCA POR LOGUEADO DEL CLIENTE
  getCustomer(id: string) {
    this.dataApi.getOneCustomerUser(id).subscribe(task => {
      if (task.length > 0) {
        task.forEach(element => {
          this.customerId = element.id;
          this.getOneUserCustomer(this.customerId);
        });
      } else {
        this.isNew = true;
      }
    });
  }
  getOneUserCustomer(idUser: string) {
    this.dataApi.getAllTasksCustomer(idUser).subscribe(tasks => {
      if (tasks.length > 0) {
        this.tasks = tasks;
      } else {
        this.isNew = true;
        this.istasks = true;
      }
    });
  }

  // ME CARGA TODO LAS TAREAS
  getAllTaks() {
    this.dataApi.getAllTasks().subscribe(tasks => {
      this.tasks = tasks;
    });
  }

  // MANDAR CORREOS 5 DIAS ANTES DEL VENCIMIENTO DE LAS TAREA
  getVencTask() {
    this.dataApi.getAllTasks().subscribe(tasks => {
       tasks.forEach( element => {
            this.fechafinal = element.fechafinal;
            if (element.activo === true) {
              if (moment(this.fechafinal).format('L') >= moment().format('L')) {
                this.dia_formato = moment(this.fechafinal).format('dddd'); // muestar el dia de la semana en cadena de esa fecha
                var vencimiento = moment(this.fechafinal);

                var hoy = moment();
                var resultado = vencimiento.diff(hoy, 'd');
                var resultadoB = resultado + 1;

                this.day = resultadoB;
                this.name = element.customer;
                this.nameUser = element.userUidName;
                this.emailCust = element.emailCust;
                this.emailCC = element.emailCC;
                this.emailFun = element.userUidEmail;
                this.service = element.service;
                this.id = null;
                // console.log('DÍAS: ', resultadoB);
                let id = element.id + '' + resultadoB;
                if (resultadoB >= 0 && resultadoB <= 5) {
                    const messageRef: AngularFirestoreDocument<any> = this.afs.doc(`message/${id}`);
                    let dat: Object = {
                      name: this.name,
                      nameUser: this.nameUser,
                      emailCust: this.emailCust,
                      emailCC: this.emailCC,
                      emailFun: this.emailFun,
                      dateEnd: this.fechafinal,
                      day: this.day,
                      service: this.service,
                      dosageId: '0',
                      type: 'Tarea en Vencimiento',
                      date: formatDate(new Date(), 'yyyy/MM/dd, h:mm:ss a', 'en'),
                    };
                    return messageRef.set(dat, { merge: true });
                    // console.log('CUANTOS DIAS: ', dat);
                    // this.dataApi.addMessage(dat);
                }

              }
            }
       });
    });
  }

  getVencDosage() {
    this.dataApi.getAllDosage().subscribe(dosages => {
       let fech = new Date();
       const dia = (fech.getDate());
       dosages.forEach( element => {
              this.fechafinal = element.dateFinish;
              if (dia === 1 && element.active === true) {
                if (this.repeat === true) {
                  this.dosageId = element.id;
                  this.day = dia;
                  this.name = element.customer;
                  this.nameUser = element.userUidName;

                  this.emailCust = element.emailCust;
                  this.emailCC = element.emailCC;
                  this.emailFun = element.userUidEmail;
                  this.service = element.service;
                  this.userUidUser = element.userUid;
                  this.customer = element.customer;
                  this.familyId = element.familyId;
                  this.groupId = element.groupId;
                  this.group = element.group;
                  this.subgroupId = element.subgroupId;
                  this.subgroup = element.subgroup;
                  this.num_service = element.num_service;
                  this.repeat = element.repeat;
                  let id = element.id + '' + dia;
                  const messageRef: AngularFirestoreDocument<any> = this.afs.doc(`messagemonth/${id}`);
                  let dat: Object = {
                    name: this.name,
                    nameUser: this.nameUser,
                    emailCust: this.emailCust,
                    emailCC: this.emailCC,
                    emailFun: this.emailFun,
                    dateEnd: this.fechafinal,
                    day: this.day,
                    service: this.service,
                    dosageId: this.dosageId,
                    type: 'Notificación del 1 día de Mes',
                    date: formatDate(new Date(), 'yyyy/MM/dd, h:mm:ss a', 'en'),
                  };
                  return messageRef.set(dat, { merge: true });
                }
              }
              if (moment(this.fechafinal).format('L') >= moment().format('L')) {
                this.min = element.min;
                this.max = element.max;
                this.dosageId = element.id;
                this.dia_formato = moment(this.fechafinal).format('dddd'); // muestar el dia de la semana en cadena de esa fecha
                var vencimiento = moment(this.fechafinal);

                var hoy = moment();
                var resultado = vencimiento.diff(hoy, 'd');
                var resultadoB = resultado + 1;

                this.day = resultadoB;
                this.name = element.customer;
                this.nameUser = element.userUidName;

                this.emailCust = element.emailCust;
                this.emailCC = element.emailCC;
                this.emailFun = element.userUidEmail;
                this.service = element.service;
                this.userUidUser = element.userUid;
                this.customer = element.customer;
                this.familyId = element.familyId;
                this.groupId = element.groupId;
                this.group = element.group;
                this.subgroupId = element.subgroupId;
                this.subgroup = element.subgroup;
                this.num_service = element.num_service;
                this.repeat = element.repeat;
                let id = element.id + '' + resultadoB;
                // resultadoB >= 0 && resultadoB <= 5
                if (resultadoB === this.min) {
                    // console.log('DIAS MIN: ', resultadoB, this.name);
                    const messageRef: AngularFirestoreDocument<any> = this.afs.doc(`message/${id}`);
                    let dat: Object = {
                      name: this.name,
                      nameUser: this.nameUser,
                      emailCust: this.emailCust,
                      emailCC: this.emailCC,
                      emailFun: this.emailFun,
                      dateEnd: this.fechafinal,
                      day: this.day,
                      service: this.service,
                      dosageId: this.dosageId,
                      type: 'Documentacion',
                      date: formatDate(new Date(), 'yyyy/MM/dd, h:mm:ss a', 'en'),
                    };
                    return messageRef.set(dat, { merge: true });
                }
                if (resultadoB === this.max) {
                  // console.log('DIAS MAX: ', resultadoB, this.name);
                  const messageRef: AngularFirestoreDocument<any> = this.afs.doc(`message/${id}`);
                  let dat: Object = {
                    name: this.name,
                    nameUser: this.nameUser,
                    emailCust: this.emailCust,
                    dateEnd: this.fechafinal,
                    day: this.day,
                    service: this.service,
                    dosageId: this.dosageId,
                    type: 'Documentacion',
                    date: formatDate(new Date(), 'yyyy/MM/dd, h:mm:ss a', 'en'),
                  };
                  return messageRef.set(dat, { merge: true });
                }

                if (resultadoB === 0) {
                  // pone en falso los datos cuando se pasa su fecha limite
                  element.active = false;
                  element.repeat = false;
                  this.dataApi.updateDosage(element);
                }

                if (resultadoB === 0) {
                  // Si el registro esta en repetir la tarea en true hace una copia con la
                  // fecha del mes siguiente
                  if (this.repeat === true) {
                     // calcula un mes de la fecha final
                     let fecha = new Date(element.dateFinish);
                     fecha.setMonth(fecha.getMonth() + 1);
                     fecha.setDate(fecha.getDate() + 1);
                     this.fechainicial = moment(fecha, 'dd.mm.yyyy').format('YYYY-MM-DD');
                     // console.log('fecha final:', this.fechainicial,  this.name, element.dateFinish);
                     element.emailCust =  this.emailCust;
                     element.emailCC = this.emailCC;
                     element.userUidEmail = this.emailFun;
                     element.userUidName = this.nameUser;
                     element.service = this.service;
                     element.userUid = this.userUidUser;
                     element.customer = this.customer;
                     element.familyId = this.familyId;
                     element.groupId = this.groupId;
                     element.group =  this.group;
                     element.subgroupId = this.subgroupId;
                     element.subgroup = this.subgroup;
                     element.num_service = this.num_service;
                     element.key = '0';
                     element.active = true;
                     element.repeat = true;
                     element.userUid = this.userUidUser;
                     element.dateStart = formatDate(new Date(), 'yyyy/MM/dd', 'en'),
                     element.dateFinish = this.fechainicial;
                     this.dataApi.addDosage(element);
                  }
                }
              }
       });
    });
  }

}
