import { Component, OnInit } from "@angular/core";
import { DataApiService } from '../../services/data-api.service';
import { AuthService } from '../../services/auth.service';
import { DataUserInterface } from '../../models/dataUser';
import { FamilyInterface } from '../../models/family';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { ServicesInterface } from '../../models/services';
import { AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';

@Component({
  selector: 'app-asign-roles',
  templateUrl: './asign-roles.component.html',
  styleUrls: ['./asign-roles.component.css']
})
export class AsignRolesComponent implements OnInit {
  public dataUsers: DataUserInterface[];
  public servicesRoles: ServicesInterface[];
  public userUid: string;
  public isAadmin: any = null;
  public isAdmin: any = null;
  public isEditor: any = null;
  public userId: string;
  public habilitado: boolean = false;
  public servicesId: string = '';
  public isError = false;
  public isErrorDel = false;
  public nivel: string = '1'; // nivel de jefe funcionario

  filterpost = '';
  filterpostrol = '';
  pageActual: number = 1;
  pageActualServ: number = 1;

  public myForm: FormGroup;

  // Declarar variables
  disabled = false;
  showFilter = true;
  limitSelection = false;
  public services: FamilyInterface[];
  public selectedItem: any = [];
  public dropdownSettings: any = {};

  marked = false;
  public selectAll: boolean = false;
  isChecbox: boolean = true;
  public selectd = [];

  constructor(
    public dataApi: DataApiService,
    public authService: AuthService,
    private fb: FormBuilder,
    private afs: AngularFirestore
  ) {}

  ngOnInit() {
    this.getCurrentUser();
    this.getListUsers(this.nivel);
    this.getListFamily();
    // empieza aqui
    this.selectedItem = [this.services];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: this.showFilter,
      // mas servicios
      limitSelection: -1,
      clearSearchFilter: true,
      searchPlaceholderText: 'Buscar...',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };

    this.myForm = this.fb.group({
      service: [this.selectedItem]
    });

    this.setForm();
  }

  public setForm() {
    this.myForm = new FormGroup({
      service: new FormControl(this.services)
    });
  }

  public save() {
    // console.log("SAVE: ", this.myForm.value);
  }

  onItemSelect(item: any) {
    // console.log("onItemSelect: ", item);
  }

  onSelectAll(items: any) {
    // console.log("onSelectAll: ", items);
  }

  onDeSelect(items: any) {
   // console.log("onDeSelect: ", items);
  }

  onDropDownClose(items: any) {
    // console.log("onDropDownClose: ", items);
  }

  onFilterChange(item: any) {
    // console.log("onFilterChange: ", item);
  }

  toggleVisibility(e) {
    console.log((this.marked = e.target.checked));
  }

  toggleSelection(item: FamilyInterface) {
    if (this.isChecbox) {
      var idx = this.selectd.indexOf(item);
      if (idx > -1) {
        this.selectd.splice(idx, 1);
        // elimina el servicio seleccionado
        if (!this.isErrorDel) {
          // this.dataApi.deleteServices(item.id);
        }
      } else {
        this.selectd.push(item);
        this.servicesId = item.id;
        // this.getSearchServices(this.servicesId);
        let id = item.id;
        if (this.userId) {
          try {
            const messageRef: AngularFirestoreDocument<any> = this.afs.doc(
              `services/${id}`
            );
            let dat: Object = {
              groupId: item.groupId,
              group: item.group,
              subgroupId: item.subgroupId,
              subgroup: item.subgroup,
              cod: item.cod,
              name: item.name,
              userUid: this.userId
            };
            return messageRef.set(dat, { merge: true });
          } catch (error) {
            console.log(error);
          }
        }

      }
    }
   // quita del array que se muestra
    if (!this.isChecbox) {
      return this.selectd.indexOf(item) > -1;
    }
  }

  checkAll() {
    if (!this.selectAll) {
      this.services.forEach(item => {
        var idx = this.selectd.indexOf(item);
        if (idx >= 0) {
          return true;
        } else {
          this.selectd.push(item);
        }
      });
    } else {
      this.selectd = [];
    }
  }

  // Los datos a carga siempre
  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
          this.isAadmin = Object.assign({}, userRole.roles).hasOwnProperty('Aadmin');
          this.isAdmin = Object.assign({}, userRole.roles).hasOwnProperty('admin');
          this.isEditor = Object.assign({}, userRole.roles).hasOwnProperty('editor');
        });
      }
    });
  }

  // carga el listado de los servicios
  getListFamily() {
    this.dataApi.getAllFamilyGroup().subscribe(services => {
      this.services = services;
    });
  }


  onDeleteRoles(id: string) {
    const confirmacion = confirm('Estás seguro ?');
    if (confirmacion) {
      this.dataApi.deleteServices(id);
    }
  }

  // carga el listado de funcionarios
  getListUsers(id: string) {
    this.dataApi.getNivelDataUserUid(id).subscribe(dataUsers => {
      if (dataUsers.length > 0) {
        this.dataUsers = dataUsers;
      }
    });
  }

  // Busca o carga el listad de todo los servicios que se le fueron asigando
  getSelectUser(id: string) {
    if (id != null) {
      this.userId = id;
      this.dataApi.getServicesSearch(id).subscribe(services => {
        this.servicesRoles = services;
        this.habilitado = true;
      });
    }
  }

  getSearchServices(id: string) {
    if (id != null) {
      this.dataApi.getOneServices(id).subscribe(services => {
        if (services) {
          this.onisError();
          return  this.isErrorDel = true;
        }
      });
    }
  }

  onisError(): void {
    this.isError = true;
    setTimeout(() => {
      this.isError = false;
    }, 3000);
  }
}
