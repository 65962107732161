import { Component, OnInit, Input } from '@angular/core';
import { DataApiService } from '../../services/data-api.service';
import { AuthService } from '../../services/auth.service';
import { RequestInterface } from '../../models/request';


@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  constructor(private dataApi: DataApiService, private authService: AuthService) { }
  public requests: RequestInterface[];
  @Input() istasks: boolean = false;
  public isAdmin: any = null;
  public isCliente: any = null;
  public userUid: string;
  public email: string;
  public customerId: string;
  public name: string;
  public isMovimiento: boolean = false;


  ngOnInit() {
    this.getCurrentUser();
  }

  nuevo() {
    this.isMovimiento = false;
  }

  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
        this.email = auth.email;
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
          this.isAdmin = Object.assign({}, userRole.roles).hasOwnProperty('admin');
          this.isCliente = Object.assign({}, userRole.roles).hasOwnProperty('cliente');
          if (this.isCliente === true) {
            this.getCustomer(this.userUid);
          }
        });
      }
    });
  }

  // BUSCA POR LOGUEADO DEL CLIENTE
  getCustomer(id: string) {
    this.dataApi.getOneCustomerUser(id).subscribe(task => {
      if (task.length > 0) {
        task.forEach(element => {
          this.name = element.name + " Telf: " + element.phone + " Móvil: " + element.mobil;
          this.customerId = element.id;
          this.getOneUserCustomer(this.customerId);
        });
      }
    });
  }

  getOneUserCustomer(idUser: string) {
    this.dataApi.getAllRequestCustomer(idUser).subscribe(requests => {
      if (requests.length > 0) {
        this.requests = requests;
      }
    });
  }

}
