import { Component, OnInit } from '@angular/core';
import { DataApiService } from '../../../services/data-api.service';
import { NgForm } from '@angular/forms';
import { FamilyInterface } from '../../../models/family';
import { TaskInterface } from '../../../models/task';
import { AuthService } from '../../../services/auth.service';
import * as moment from 'moment';
import 'moment/locale/bo';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-list-task-group',
  templateUrl: './list-task-group.component.html',
  styleUrls: ['./list-task-group.component.css']
})
export class ListTaskGroupComponent implements OnInit {

  constructor(private dataApi: DataApiService,  private authService: AuthService) { }
  public tasks: TaskInterface[];
  public familys: FamilyInterface[];
  public isAdmin: any = null;
  public userUid: string;

  filterpost = '';
  pageActual: number = 1;
  ngOnInit() {
    this.getAllService();
  }

  getAllService() {
    this.dataApi.getAllFamilyGroup().subscribe( familys => {
       this.familys = familys;
    });
  }

}
