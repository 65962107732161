import { Component, OnInit } from '@angular/core';
import { DataApiService } from '../../../services/data-api.service';
import { CustomersInterface } from '../../../models/customers';

// importamos para el userid
import { AuthService } from '../../../services/auth.service';

import * as printJS from 'print-js';
import { MensajesService } from 'src/app/services/mensajes.service';


@Component({
  selector: 'app-list-customers',
  templateUrl: './list-customers.component.html',
  styleUrls: ['./list-customers.component.css']
})
export class ListCustomersComponent implements OnInit {

  constructor(private dataApi: DataApiService, private authService: AuthService, private msj: MensajesService) { }
  public customers: CustomersInterface[];
  public cust: CustomersInterface = {};
  public tasks = [];
  public task = '';
  public isaAdmin: any = null;
  public isAdmin: any = null;
  public isCliente: any = null;
  public customerId: string;
  public userUid: string;
  public email: string;
  public mostrar: Boolean =  false;
  public isError = false;
  public isMovimiento: boolean = false;

  pageActual: number = 1;

  ngOnInit() {
    this.getCurrentUser();
  }

  nuevo() {
    this.isMovimiento = false;
  }

  validarUpdate(id: string) {
    this.dataApi.getAllTasksCustomer(id).subscribe( tasks => {
      if (tasks.length > 0 ) {
         this.isMovimiento = true;
      } else {
        this.isMovimiento = false;
      }
   });

  }

  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
        this.email = auth.email;
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
          this.isaAdmin = Object.assign({}, userRole.roles).hasOwnProperty('Aadmin');
          this.isAdmin = Object.assign({}, userRole.roles).hasOwnProperty('admin');
          this.isCliente = Object.assign({}, userRole.roles).hasOwnProperty('cliente');
          if (this.isaAdmin === true) {
            this.getListCustomerAdm(); // todos los clientes
          }
          if (this.isAdmin === true) {
            this.getListCustomer(); // todos los clientes
          }
          if (this.isCliente === true) {
            this.getCustomer(this.userUid);
          }

        });
      }
    });
  }
  getListCustomerAdm() {
    this.dataApi.getAllCustomers().subscribe(customers => {
      if (customers) {
        this.customers = customers;

        this.mostrar = true;
      }
    });
  }

  getListCustomer() {
    this.dataApi.getAllCustomers().subscribe(customers => {
      if (customers) {
        this.customers = customers;
        this.getCustomerAll(this.userUid);
      }
    });
  }

  getCustomerAll(id: string) {
    this.dataApi.getOneCustomerUser(id).subscribe(customers => {
      if (customers.length > 0) {
        this.mostrar = true;
      } else {
        this.mostrar = false;
      }
    });
}

    // BUSCA POR LOGUEADO DEL CLIENTE
  getCustomer(id: string) {
      this.dataApi.getOneCustomerUser(id).subscribe(customers => {
        if (customers.length > 0) {
          this.customers = customers;
          this.mostrar = true;
        } else {
          this.mostrar = false;
        }
      });
  }

  onisError(): void {
    this.isError = true;
    setTimeout(() => {
      this.isError = false;
    }, 3000);
  }


  onInactivo(Customer: CustomersInterface) {
    const confirmacion = confirm('Estás seguro ?');
    if (confirmacion) {
     Customer.activo = false;
     this.dataApi.updateCustomer(Customer);
     this.msj.mensajeAdvertencia('Taracoe', 'Cliente está inactivo correctamente');
    }
  }

  onActivo(Customer: CustomersInterface) {
    const confirmacion = confirm('Estás seguro ?');
    if (confirmacion) {
      Customer.activo = true;
     this.dataApi.updateCustomer(Customer);
     this.msj.mensajeAdvertencia('Taracoe', 'Cliente se activo correctamente');
    }
  }

  onDeleteCust(idCustomer: string) {
    const confirmacion = confirm('Estás seguro ?');
    if (confirmacion) {
      this.dataApi.getAllTasksCustomer(idCustomer).subscribe( tasks => {
        if (tasks.length > 0 ) {
          this.onisError();
        } else {
          this.dataApi.deleteCustomer(idCustomer);
          console.log('eliminado');
        }
     });

    }
  }

  // carga la informacion al modal para el update
  onPreUpdateCustomer(customer: CustomersInterface) {
    this.dataApi.selectCustomer = Object.assign({}, customer);

  }


  downloadPdf() {
    console.log('DATA: ', JSON.stringify(this.customers));
    printJS({printable: this.customers, properties: [
      {field: 'name', displayName: 'Nombre'},
      {field: 'company', displayName: 'Razon Social'},
      {field: 'nit', displayName: 'Nit'},
      {field: 'address', displayName: 'Direccion'},
      {field: 'phone', displayName: 'Telefono'},
      {field: 'mobil', displayName: 'Movil'},
      {field: 'email', displayName: 'Email'}
    ], type: 'json',
       documentTitle: 'Listado de Clientes.',
       header: '<h4 class="custom-h4">Listado de Clientes: </h4>' + '<br><br>',
       headerStyle: 'font-weight: 300;',
       font: 'Century Gothic',
       style: '.custom-h4 { color: blue; }',
       gridHeaderStyle: 'font-weight: bold; padding: 5px; border: 1px solid #dddddd; width:auto;'});
  }


}
