import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { map } from 'rxjs/operators';
import { auth } from 'firebase/app';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { UserInterface } from '../models/user';
import { Observable } from 'rxjs';

import { firebase } from '@firebase/app';
import '@firebase/firestore';
import '@firebase/auth';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
   // obtienes los datos del user logueado
   public userData$: Observable<firebase.User>;

   private userCollection: AngularFirestoreCollection<UserInterface>;
   private users: Observable<UserInterface[]>;
   private userDoc: AngularFirestoreDocument<UserInterface>;
   private user: Observable<UserInterface>;
   public selectUser: UserInterface = {
    roles: {}
   };

  constructor(public afsAuth: AngularFireAuth, private afs: AngularFirestore, private storage: AngularFireStorage ) {
    this.userData$ = afsAuth.authState;
  }

  nivel: string;
  public UserNivel: boolean;
  public UserNivelAd: boolean;
  public UserNivelAadmin: boolean;
  private nivelUpdate: string;

  getAllUsers() {
    this.userCollection = this.afs.collection<UserInterface>('users', ref => ref.orderBy('email', 'desc'));
    return this.users = this.userCollection.snapshotChanges()
     .pipe(map(changes => {
       return changes.map(action => {
         const data = action.payload.doc.data() as UserInterface;
         data.id = action.payload.doc.id;
         return data;
       });
     }));

  }

  // CARGAR SEGUN EL NIVEL DE USUARIO
  getAllUsersEditor(id: string) {
    this.userCollection = this.afs.collection<UserInterface>('users', ref => ref.where('nivel', '==', id));
    return this.users = this.userCollection.snapshotChanges()
     .pipe(map(changes => {
       return changes.map(action => {
         const data = action.payload.doc.data() as UserInterface;
         data.id = action.payload.doc.id;
         return data;
       });
     }));
  }

  nivelUser(idNivel: string) {
    this.UserNivelAd = false;
    this.UserNivelAadmin = false;
    if (idNivel === '2') {
      this.UserNivel = true;
    }
    if (idNivel === '3') {
      this.UserNivel = false;
    }
    if (idNivel === '1') {
      this.UserNivelAd = true;
    }
    if (idNivel === '0') {
      this.UserNivelAadmin = true;
    }
  }

  getUsersAll() {
    this.userCollection = this.afs.collection<UserInterface>('users', ref => ref.where('nivel', '<', '3'));
    return this.users = this.userCollection.snapshotChanges()
     .pipe(map(changes => {
       return changes.map(action => {
         const data = action.payload.doc.data() as UserInterface;
         data.id = action.payload.doc.id;
         return data;
       });
     }));
  }

  getOneUser(idUsers: string) {
    this.userDoc = this.afs.doc<UserInterface>(`users/${idUsers}`);
    return this.user  = this.userDoc.snapshotChanges().pipe(map(action => {
      if (action.payload.exists === false) {
        return null;
      } else {
        const data = action.payload.data() as UserInterface;
        data.id = action.payload.id;
        return data;
      }
    }));
  }

  registerUser(email: string, pass: string) {
    return new Promise((resolve, reject) => {
      this.afsAuth.auth.createUserWithEmailAndPassword(email, pass)
        .then(userData => {
          resolve(userData),
            this.updateUserData(userData.user);
        }).catch(err => console.log(reject(err)));
    });
  }

  loginEmailUser(email: string, pass: string) {
   return new Promise((resolve, reject) => {
      this.afsAuth.auth.signInWithEmailAndPassword(email, pass)
        .then(userData => resolve(userData),
        err => reject(err));
    });
  }

  loginFacebookUser() {
    return this.afsAuth.auth.signInWithPopup( new auth.FacebookAuthProvider());
  }

  loginGoogleUser() {
   return this.afsAuth.auth.signInWithPopup( new auth.GoogleAuthProvider());
  }

  registerFacebookUser() {
    return this.afsAuth.auth.signInWithPopup( new auth.FacebookAuthProvider())
    .then(credential => this.updateUserData(credential.user));
  }

  registerGoogleUser() {
   return this.afsAuth.auth.signInWithPopup( new auth.GoogleAuthProvider())
   .then(credential => this.updateUserData(credential.user));

  }

  logoutUser() {
    return this.afsAuth.auth.signOut();
  }

  isAuth() {
    return this.afsAuth.authState.pipe(map(auth => auth));
  }
  // Verificar correo
  verifyEmail() {
    return this.afsAuth.auth.currentUser.sendEmailVerification();
  }

  // restaurar contraseña
  restaurarPass(email: string) {
    return this.afsAuth.auth.sendPasswordResetEmail(email);
  }

  // metodo - una ves que se cree el usuario asignamos un rol
  private updateUserData(user) {
    // this.verifyEmail();
    this.sendVerificationEmail();

    if (this.UserNivel) {
      const userRefA: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
      const dataA: UserInterface = {
        id: user.uid,
        email: user.email,
        nivel: '2',
        roles: {
            editor: true
        }
      };
      return userRefA.set(dataA, { merge: true });

    }
    if (!this.UserNivelAadmin) {
      if (!this.UserNivelAd) {
        if (!this.UserNivel) {
          const userRefB: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
          const dataB: UserInterface = {
            id: user.uid,
            email: user.email,
            nivel: '3',
            roles: {
                cliente: true
            }
          };
          return userRefB.set(dataB, { merge: true });
        }
      }
    }

    if (this.UserNivelAd) {
      const userRefC: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
      const dataC: UserInterface = {
        id: user.uid,
        email: user.email,
        nivel: '1',
        roles: {
            admin: true
        }
      };
      return userRefC.set(dataC, { merge: true });
    }
    if (this.UserNivelAadmin) {
      const userRefK: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
      const dataK: UserInterface = {
        id: user.uid,
        email: user.email,
        nivel: '0',
        roles: {
          Aadmin: true
        }
      };
      return userRefK.set(dataK, { merge: true });
    }

  }

// esto no va a devolver el documento que corresponda con userID
  isUserAdmin(userUid) {
    return this.afs.doc<UserInterface>(`users/${userUid}`).valueChanges();
  }

  UpdateRoles(id: string, nivel: string) {
    this.nivelUpdate = nivel;
    if (id) {
      // Administrador lo ve todo
      if (this.nivelUpdate === '0') {
        const userRefC: AngularFirestoreDocument<any> = this.afs.doc(`users/${id}`);
        const dataC: UserInterface = {
          nivel: this.nivelUpdate,
          roles: {
              Aadmin: true
          }
        };
        return userRefC.update(dataC);
      }

      // Role de encargado
      if (this.nivelUpdate === '1') {
        const userRefC: AngularFirestoreDocument<any> = this.afs.doc(`users/${id}`);
        const dataC: UserInterface = {
          nivel: this.nivelUpdate,
          roles: {
              admin: true
          }
        };
        return userRefC.update(dataC);
      }

      // Rol de trabajador
      if (this.nivelUpdate === '2') {
        const userRefC: AngularFirestoreDocument<any> = this.afs.doc(`users/${id}`);
        const dataC: UserInterface = {
          nivel: this.nivelUpdate,
          roles: {
              editor: true
          }
        };
        return userRefC.update(dataC);
      }

    }
  }

  // reenciar Verificacion de Email
  async sendVerificationEmail(): Promise<void> {
    return await this.afsAuth.auth.currentUser.sendEmailVerification();
  }

}
