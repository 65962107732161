import { Component, OnInit, Input } from '@angular/core';
import { ServicesDetInterface } from 'src/app/models/servicesdet';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MensajesService } from 'src/app/services/mensajes.service';
import { ServicesdetService } from 'src/app/services/servicesdet.service';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';
import { SubfamilyService } from 'src/app/services/subfamily.service';
import { SubFamilyInterface } from 'src/app/models/subfamily';


@Component({
  selector: 'app-edit-servicedet',
  templateUrl: './edit-servicedet.component.html',
  styleUrls: ['./edit-servicedet.component.css']
})
export class EditServicedetComponent implements OnInit {
  public Validar: boolean = true;
  public userUid: string;
  public name: string;
  @Input() servicedet: ServicesDetInterface;
  public subservices: SubFamilyInterface[];


  constructor(
    private msj: MensajesService,
    private serviceDet: ServicesdetService,
    private serviceSub: SubfamilyService,
    private authService: AuthService) { }

  get subfamilyId() { return this.editSpecialForm.get('subfamilyId'); }


  public editSpecialForm = new FormGroup({
    id: new FormControl('', [ Validators.required]),
    subfamilyId: new FormControl('',[ Validators.required]),
    name: new FormControl('', [ Validators.required]),
    value: new FormControl([ Validators.required]),
    observation: new FormControl('', [ Validators.required]),

  });

  ngOnInit() {
    this.name = null;
    this.initValuesForm();
    this.getCurrentUser();
    this.getAllSubServiceId();
  }

  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
      }
    });
  }

  getAllSubServiceId() {
    this.serviceSub.getAllServiceFamily(this.servicedet.familyId).subscribe( subfamily => {
      this.subservices = subfamily;
    });
  }


  nameService(name: string) {
    console.log('Name: ', name);
    this.name = name;
  }

  // cargar los valores
  private initValuesForm(): void {
      this.editSpecialForm.patchValue({
        id: this.servicedet.id,
        taskId: this.servicedet.taskId,
        familyId: this.servicedet.familyId,
        subfamilyId: this.servicedet.subfamilyId,
        name: this.servicedet.name,
        value: this.servicedet.value,
        observation: this.servicedet.observation
      });
  }

  addNewSpecial(data: ServicesDetInterface) {

      if (this.name !== null ) {
       data.name = this.name;
      }
      data.userUid = this.userUid;
      if (this.Validar) {
        this.serviceDet.editServiceDetByid(data);
        this.mensaje();
      }

  }



  mensaje() {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Tú trabajo ha sido guardado',
        showConfirmButton: false,
        timer: 1500
      })
  }

}
