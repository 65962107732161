import { Component, OnInit } from '@angular/core';
import { DataApiService } from '../../../services/data-api.service';
import { RequestInterface } from '../../../models/request';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-list-request',
  templateUrl: './list-request.component.html',
  styleUrls: ['./list-request.component.css']
})
export class ListRequestComponent implements OnInit {

  constructor(private dataApi: DataApiService, private authService: AuthService) { }
  public requests: RequestInterface[];
  public Aadmin: boolean;
  public isAdmin: boolean;
  public isCliente: boolean;
  public isEditor: boolean;
  public userUid: string;
  public email: string;
  public name: string;
  public mostrar: boolean = false;
  filterpost = '';

  public isUpdate: boolean = false;
  public isMovimiento: boolean = false;
  public isSolicitudTarea: boolean = false; // cuando se crea una nueva tarea desde una solicitud de la misma


  public observacion: string = ''; // pasa los datos de la fecha el nombre del cliente y su solicitud de la nueva tarea
  public requestId: string;


  public customersId: string;

  public isCustomersId: string;
  public isCustomer: string;
  public isEmailCust: string;
  public isEmailCC: string;
  public isNameCC: string;
  public isRazon: string;

  public isError = false;

  public idRequest: string;
  public isValida: boolean = false;

  pageActual: number = 1;
  ngOnInit() {
    this.getCurrentUser();
  }

  nuevo() {
    this.isUpdate = false;
    this.isMovimiento = false;
    this.isSolicitudTarea = true; // para que cargue automaticamente el clienteid y nombre
  }

  // busca los datos de la solicitud para registrar una nueva tarea
  onSearch(id: string) {
    if (id !== null || id !== '') {
      // tslint:disable-next-line:no-shadowed-variable
      this.dataApi.getAllTasksRequest(id). subscribe( request => {
        if (request.length > 0 ) {
         this.onisError();
        } else {
         this.dataApi.getOneRequest(id).subscribe(request => {
           this.isCustomersId = request.customersId;
           this.requestId = request.id;
           this.observacion = request.Date + '' + request.name + '' + request.description;
           if (this.isCustomersId != null) {
             this.getOneCustomer(this.isCustomersId);
           }
         });
        }
     });
    }
  }

   // carga la consulta
  getOneCustomer(idCustomers: string) {
    if (idCustomers != null) {
        this.dataApi.getOneCustomer(idCustomers).subscribe( cust => {
          this.isCustomer = cust.name;
          this.isEmailCust = cust.email;
          this.isEmailCC = cust.emailCC;
          this.isNameCC = cust.nameCC;
          this.isRazon = cust.company;
        });
    }
  }

  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.mostrar = false;
        this.userUid = auth.uid;
        this.email = auth.email;
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
          this.Aadmin = Object.assign({}, userRole.roles).hasOwnProperty('Aadmin');
          this.isAdmin = Object.assign({}, userRole.roles).hasOwnProperty('admin');
          this.isCliente = Object.assign({}, userRole.roles).hasOwnProperty('cliente');
          this.isEditor = Object.assign({}, userRole.roles).hasOwnProperty('editor');
          if (this.Aadmin === true) {
            this.getListRequest();
            this.mostrar = false;
          }
          if (this.isAdmin === true) {
            this.getListRequest();
            this.mostrar = true;
          }
          if (this.isEditor === true) {
            this.getListRequest();
            this.mostrar = false;
          }
          if (this.isCliente === true) {
            this.getCustomer(this.userUid);
            this.mostrar = false;
          }

        });
      }
    });
  }


  // BUSCA POR LOGUEADO DEL CLIENTE
  getCustomer(id: string) {
      this.dataApi.getOneCustomerUser(id).subscribe(task => {
        if (task.length > 0) {
          task.forEach(element => {
            this.name = element.name + ' Telf: ' + element.phone + ' Móvil: ' + element.mobil;
            this.customersId = element.id;
            this.getOneUserCustomer(this.customersId);
          });
        }
      });
  }

  getOneUserCustomer(idUser: string) {
      this.dataApi.getAllRequestCustomer(idUser).subscribe(requests => {
        if (requests.length > 0) {
          this.requests = requests;
        }
      });
  }

  getListRequest() {
    this.dataApi.getAllRequestState().subscribe( requests => {
      this.requests = requests;
    });
  }


  onDeleteRequest(idRequest: string) {
    const confirmacion = confirm('Estas seguro ?');
    if (confirmacion){
      // tslint:disable-next-line:no-shadowed-variable
      this.dataApi.getAllTasksRequest(idRequest). subscribe( request => {
         if (request.length > 0 ) {
          this.onisError();
         } else {
          this.dataApi.deleteRequest(idRequest);
         }
      });
    }
  }

  onisError(): void {
    this.isError = true;
    setTimeout(() => {
      this.isError = false;
    }, 3000);
  }

  // carga el update al modal
  onPreUpdateRequest(request: RequestInterface) {
    // console.log('GROUP: ', group);
    this.dataApi.selectRequest = Object.assign({}, request);
  }


}
