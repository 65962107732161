import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modalservice',
  templateUrl: './modalservice.component.html',
  styleUrls: ['./modalservice.component.css']
})
export class ModalserviceComponent implements OnInit {

  constructor(public dialog: MatDialogRef<ModalserviceComponent>,
    // tslint:disable-next-line: align
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

}
