import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { TaskInterface } from '../models/task';
import { Observable } from 'rxjs';
import { map, distinct } from 'rxjs/operators';
import { groupInterface } from '../models/group';
import { CustomersInterface } from '../models/customers';
import { SubgroupInterface } from '../models/subgroup';
import { FamilyInterface } from '../models/family';
import { StateInterface } from '../models/state';
import { EvaluationInterface } from '../models/evaluation';
import { TaskDetailsInterface } from '../models/task_details';
import { DosageInterface } from '../models/dosage';
import { ActivityInterface } from '../models/activity';
import { RequestInterface } from '../models/request';
import { MessageInterface } from '../models/message';

import { DataUserInterface } from '../models/dataUser';
import { PositionInterface } from '../models/position';
import { DataUserDetInterface } from '../models/datauserdet';
import { MessageMonthInterface } from '../models/messagemonth';
import { BlogInterface } from '../models/blog';
import { ServicesInterface } from '../models/services';


@Injectable({
  providedIn: 'root'
})
export class DataApiService {
  constructor(private afs: AngularFirestore) {
   }
  private taskCollection: AngularFirestoreCollection<TaskInterface>;
  private tasks: Observable<TaskInterface[]>;
  // recuperar una tarea
  private taskDoc: AngularFirestoreDocument<TaskInterface>;
  private task: Observable<TaskInterface>;
  public selectTask: TaskInterface = {
    id: null
  };


  // group componenents
  private groupCollection: AngularFirestoreCollection<groupInterface>;
  private groups: Observable<groupInterface[]>;
  private groupDoc: AngularFirestoreDocument<groupInterface>;
  private group: Observable<groupInterface>;
  public selectGroup: groupInterface = {
    id: null
  };

  // customers componenets
  private customerCollection: AngularFirestoreCollection<CustomersInterface>;
  private customers: Observable<CustomersInterface[]>;
  private customerDoc: AngularFirestoreDocument<CustomersInterface>;
  private customer: Observable<CustomersInterface>;
  public selectCustomer: CustomersInterface = {
    id: null,
    emailCC: 'info@servicontable13.com',
    nameCC: 's/f'
  };

  // subgroup components
  private subgroupCollection: AngularFirestoreCollection<SubgroupInterface>;
  private subgroups: Observable<SubgroupInterface[]>;
  private subgroupDoc: AngularFirestoreDocument<SubgroupInterface>;
  private subgroup: Observable<SubgroupInterface>;
  public selectSubgroup: SubgroupInterface = {
    id: null
  };

  // family components
  private familyCollection: AngularFirestoreCollection<FamilyInterface>;
  private familys: Observable<FamilyInterface[]>;
  private familyDoc: AngularFirestoreDocument<FamilyInterface>;
  private family: Observable<FamilyInterface>;
  public selectFamily: FamilyInterface = {
    id: null
  };

  // state components
  private stateCollection: AngularFirestoreCollection<StateInterface>;
  private states: Observable<StateInterface[]>;
  private stateDoc: AngularFirestoreDocument<StateInterface>;
  private state: Observable<StateInterface>;
  public selectState: StateInterface = {
     id: null,
     nivelState: '1'
  };

  // evaluation components
  private evaluationCollection: AngularFirestoreCollection<EvaluationInterface>;
  private evaluations: Observable<EvaluationInterface[]>;
  private evaluationDoc: AngularFirestoreDocument<EvaluationInterface>;
  private evaluation: Observable<EvaluationInterface>;
  public selectEvaluation: EvaluationInterface = {
     id: null,
     nivel: '1'
  };

  // task details components
  private taskdetailCollection: AngularFirestoreCollection<TaskDetailsInterface>;
  private taskdetails: Observable<TaskDetailsInterface[]>;
  private taskdetailDoc: AngularFirestoreDocument<TaskDetailsInterface>;
  private taskdetail: Observable<TaskDetailsInterface>;
  public selectTaskDetails: TaskDetailsInterface = {
    id: null
  };

  // DOSAGE COMPONENTS
  private dosageCollection: AngularFirestoreCollection<DosageInterface>;
  private dosages: Observable<DosageInterface[]>;
  private dosageDoc: AngularFirestoreDocument<DosageInterface>;
  private dosage: Observable<DosageInterface>;
  public selectDosage: DosageInterface = {
    id: null,
    key: '00000000',
    num_service: 's/f'

  };

  // ACTIVITY COMPONENTS
  private activityCollection: AngularFirestoreCollection<ActivityInterface>;
  private activitys: Observable<ActivityInterface[]>;
  private activityDoc: AngularFirestoreDocument<ActivityInterface>;
  private activity: Observable<ActivityInterface>;
  public selectActivity: ActivityInterface = {
    id: null
  };

  // REQUEST COMPONENTS
  private requestCollection: AngularFirestoreCollection<RequestInterface>;
  private requests: Observable<RequestInterface[]>;
  private requestDoc: AngularFirestoreDocument<RequestInterface>;
  private request: Observable<RequestInterface>;
  public selectRequest: RequestInterface = {
    id: null
  };

  // MESSAGE COMPONENTS
  private messageCollection: AngularFirestoreCollection<MessageInterface>;
  private messages: Observable<MessageInterface[]>;
  private messageDoc: AngularFirestoreDocument<MessageInterface>;
  private message: Observable<MessageInterface>;
  public selectMessage: MessageInterface = {
    id: null
  };

  // MESSAGE COMPONENTS 1 DIA DE CADA MES
  private messagemonthCollection: AngularFirestoreCollection<MessageMonthInterface>;
  private messagemonths: Observable<MessageMonthInterface[]>;
  private messagemonthDoc: AngularFirestoreDocument<MessageMonthInterface>;
  private messagemonth: Observable<MessageMonthInterface>;
  public selectMessageMonth: MessageMonthInterface = {
    id: null
  };


  // DATOS DE USUARIO ENCARGADO
  private dataUserCollection: AngularFirestoreCollection<DataUserInterface>;
  private dataUsers: Observable<DataUserInterface[]>;
  private dataUserDoc: AngularFirestoreDocument<DataUserInterface>;
  private dataUser: Observable<DataUserInterface>;
  public selectDataUser: DataUserInterface = {
    id: null
  };

    // DATOS DE CARGO DEL FUNCIONARIO
  private positionCollection: AngularFirestoreCollection<PositionInterface>;
  private positions: Observable<PositionInterface[]>;
  private positionDoc: AngularFirestoreDocument<PositionInterface>;
  private position: Observable<PositionInterface>;
  public selectPosition: PositionInterface = {
    id: null
  };

  // DATOS DE CARGO DEL FUNCIONARIO
  private dataUserDetCollection: AngularFirestoreCollection<DataUserDetInterface>;
  private dataUserDets: Observable<DataUserDetInterface[]>;
  private dataUserDetDoc: AngularFirestoreDocument<DataUserDetInterface>;
  private dataUserDet: Observable<DataUserDetInterface>;
  public selectDataUserDet: DataUserDetInterface = {
    id: null
  };

  // BLOG DE WIX
  private blogCollection: AngularFirestoreCollection<BlogInterface>;
  private blogs: Observable<BlogInterface[]>;
  private blogDoc: AngularFirestoreDocument<BlogInterface>;
  private blog: Observable<BlogInterface>;
  public selectBlog: BlogInterface = {
    id: null
  };

  // SERVICES PARA LOS ROLES DE LOS JEFES FUNCIONARIOS
  private servicesCollection: AngularFirestoreCollection<ServicesInterface>;
  private services: Observable<ServicesInterface[]>;
  private servicesDoc: AngularFirestoreDocument<ServicesInterface>;
  private service: Observable<ServicesInterface>;
  public selectServices: ServicesInterface = {
    id: null
  };


  // LIST DE TAKS
  getAllTasks() {
    this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.orderBy('fechainicial', 'desc'));
    return this.tasks = this.taskCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }

  getAllTasksID(id: string) {
    this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.where('id', '==', id));
    return this.tasks = this.taskCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }

  // busca para servicedet a que tarea pertenece
  public getOneTaskID(id: TaskInterface): Observable<TaskInterface> {
    return this.afs.doc<TaskInterface>(`tasks/${id}`).valueChanges();
  }

  getAllTasksCount() {
    this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.orderBy('codigo_control', 'asc'));
    return this.tasks = this.taskCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }

  getAllTasksProceso() {
    this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.where('activo', '==', true).orderBy('fechainicial', 'desc'));
    return this.tasks = this.taskCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }

  getAllTasksFinalizado() {
    this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.where('activo', '==', false).orderBy('fechainicial', 'desc'));
    return this.tasks = this.taskCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }

  // LIST DE TAKS por usuario
  getAllTasksUser(id: string) {
    this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.where('userUid', '==', id));
    return this.tasks = this.taskCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }

  getAllTasksUserProceso(id: string) {
    this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.where('userUid', '==', id).where('activo', '==', true).orderBy('fechainicial', 'desc'));
    return this.tasks = this.taskCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }

  getAllTasksUserFin(id: string) {
    this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.where('userUid', '==', id).where('activo', '==', false).orderBy('fechainicial', 'desc'));
    return this.tasks = this.taskCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }

  getAllTasksUserDisct(id: string) {
    this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.where('userUid', '==', id));
    return this.tasks = this.taskCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }

   // LIST DE TAKS por usuario cargo administrador
  getAllTasksUserAdm(id: string) {
    this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.where('adminId', '==', id).orderBy('fechainicial', 'desc'));
    return this.tasks = this.taskCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }

  getAllTasksUserAdmProceso(id: string) {
    this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.where('adminId', '==', id).where('activo', '==', true).orderBy('fechainicial', 'desc'));
    return this.tasks = this.taskCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }

  getAllTasksUserAdmFin(id: string) {
    this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.where('adminId', '==', id).where('activo', '==', false).orderBy('fechainicial', 'desc'));
    return this.tasks = this.taskCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }


  // CLIENTE QUE TIENE TAREAS DEL LINK DE UNA SOLICITUD
  getAllTasksRequest(id: string) {
    this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.where('requestId', '==', id));
    return this.tasks = this.taskCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }

  // TAREAS ASIGANADAS DESDE LA AGENDA TRIBUTARIA
  getAllTasksDosage(id: string) {
    this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.where('dosageId', '==', id));
    return this.tasks = this.taskCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }

  getAllTaskGroup(id: string) {
    this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.where('groupId', '==', id));
    return this.tasks = this.taskCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }

  getAllTaskSubGroup(id: string) {
    this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.where('subgroupId', '==', id));
    return this.tasks = this.taskCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }


  getAllTasksFamilyId(id: string) {
    this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.where('familyId', '==', id));
    return this.tasks = this.taskCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }

  // LIST DE TAKS por cliente y encargado
  getAllTasksUserDat(iduserUid: string, idCustomer: string, fechaini: string, fechafin: string) {
    this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.where('userUid', '==', iduserUid)
    .where('customersId', '==', idCustomer).where('fechainicial', '>=', fechaini).where('fechainicial', '<=', fechafin));
    return this.tasks = this.taskCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }


  getAllTasksUserB(id: string, idCustomer: string, fechaini: string, fechafin: string, valido: boolean) {
    this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.where('userUid', '==', id)
                                                               .where('customersId', '==', idCustomer)
                                                               .where('fechainicial', '>=', fechaini)
                                                               .where('fechainicial', '<=', fechafin)
                                                               .where('activo', '==', valido));
    return this.tasks = this.taskCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }

   // LIST DE TAKS por funcionario con fechas
   getAllTasksUserFunc(iduserUid: string, fechaini: string, fechafin: string) {
    this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.where('userUid', '==', iduserUid)
    .where('fechainicial', '>=', fechaini).where('fechainicial', '<=', fechafin));
    return this.tasks = this.taskCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }

  getAllTasksUserFuncB(id: string, fechaini: string, fechafin: string, valido: boolean) {
    this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.where('userUid', '==', id)
                                                               .where('fechainicial', '>=', fechaini)
                                                               .where('fechainicial', '<=', fechafin)
                                                               .where('activo', '==', valido));
    return this.tasks = this.taskCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }

    // LIST DE TAKS por funcionario por satisfacccion de trabajso realizados
    getAllTasksSatifactorio(fechaini: string, fechafin: string, id: string) {
      this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.where('fechainicial', '>=', fechaini)
                                                                .where('fechainicial', '<=', fechafin).where('userUid', '==', id));
      return this.tasks = this.taskCollection.snapshotChanges()
        .pipe(map(changes => {
          return changes.map(action => {
            const data = action.payload.doc.data() as TaskInterface;
            data.id = action.payload.doc.id;
            return data;
          });
        }));
    }


  // todo los userUid
  getAllTasksUserId(id: string) {
    this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.where('userUid', '==', id));
    return this.tasks = this.taskCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }


  // TODAS LAS TAREAS DE LOS CUSTOMER
  getAllTasksCustomer(id: string) {
    this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.where('customersId', '==', id)
    .orderBy('subgroup'));
    return this.tasks = this.taskCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }

  getAllTasksCustomerId(id: string) {
    this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.where('customersId', '==', id));
    return this.tasks = this.taskCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }


  getAllTasksCustOrd(id: string) {
    this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.where('customersId', '==', id)
                                                               .orderBy('fechafinal', 'desc'));
    return this.tasks = this.taskCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }

  // para el reporte
  getAllTasksCusRep(id: string, fechaini: string, fechafin: string) {
    this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.where('customersId', '==', id)
                                                              .where('fechainicial', '>=', fechaini)
                                                              .where('fechainicial', '<=', fechafin));
    return this.tasks = this.taskCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }

  // para el reporte
  getAllTasksCusB(id: string, fechaini: string, fechafin: string, valido: boolean) {
    this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.where('customersId', '==', id)
                                                                .where('fechainicial', '>=', fechaini)
                                                                .where('fechainicial', '<=', fechafin)
                                                               .where('activo', '==', valido));
    return this.tasks = this.taskCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }



  getAllTasksFamily(id: string) {
    this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.where('familyId', '==', id));
    return this.tasks = this.taskCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }

  getAllTasksState(id: string) {
    this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.where('stateId', '==', id));
    return this.tasks = this.taskCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }

  getAllTasksEva(id: string) {
    this.taskCollection = this.afs.collection<TaskInterface>('tasks', ref => ref.where('evaluationId', '==', id));
    return this.tasks = this.taskCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
  }

  // El detalle de las tareas en component detail-task
  getOneTask(idTaks: string) {
    this.taskDoc = this.afs.doc<TaskInterface>(`tasks/${idTaks}`);
    return this.task  = this.taskDoc.snapshotChanges().pipe(map(action => {
      if (action.payload.exists === false) {
        return null;
      } else {
        const data = action.payload.data() as TaskInterface;
        data.id = action.payload.id;
        return data;
      }
    }));
  }

  addTask1(task: TaskInterface): void {
    console.log('ATA:', task);
    this.taskCollection.add(task);
  }

  updateTask(task: TaskInterface): void {
      let idTasks = task.id;
      this.taskDoc = this.afs.doc<TaskInterface>(`tasks/${idTasks}`);
      this.taskDoc.update(task);
  }

  public addTask(data: TaskInterface) {
    this.saveTask(data);
  }

  private saveTask(data: TaskInterface) {
    const taskObj = {
      titulo: data.titulo,
      descripcion: data.descripcion,
      estado: data.estado,
      stateId: data.stateId,
      evaluacion: data.evaluacion,
      evaluationId: data.evaluationId,
      cod: data.cod,
      service: data.service,
      fechainicial: data.fechainicial,
      fechafinal: data.fechafinal,
      finalizado: data.finalizado,
      observacion: data.observacion,
      customer: data.customer,
      customersId: data.customersId,
      emailCust: data.emailCust,
      familyId: data.familyId,
      groupId: data.groupId,
      group: data.group,
      subgroupId: data.subgroupId,
      subgroup: data.subgroup,
      activo: data.activo,
      adminId: data.adminId,
      userUid: data.userUid,
      userUidName: data.userUidName,
      userUidEmail: data.userUidEmail,
      emailCC: data.emailCC,
      nameCC: data.nameCC,
      razon: data.razon,
      requestId: data.requestId,
      dosageId: data.dosageId,
      validate: data.validate,
      codigo_control: data.codigo_control,
      codigo: data.codigo
    };

    if (data.id) {
      return this.taskCollection.doc(data.id).update(taskObj);
    } else {
      return this.taskCollection.add(taskObj);
    }

  }

  deleteTask(idTask: string): void {
    this.taskDoc = this.afs.doc<TaskInterface>(`tasks/${idTask}`);
    this.taskDoc.delete();
  }

  // GROUP COMPONENTES
  getAllGroup() {
    this.groupCollection = this.afs.collection<groupInterface>('group', ref => ref.orderBy('cod', 'asc'));
    return this.groups = this.groupCollection.snapshotChanges()
    .pipe(map(changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as groupInterface;
        data.id = action.payload.doc.id;
        return data;
      });
    }));
  }

  getOneGroup(idGroup: string) {
    this.groupDoc = this.afs.doc<groupInterface>(`group/${idGroup}`);
    return this.group  = this.groupDoc.snapshotChanges().pipe(map(action => {
      if (action.payload.exists === false) {
        return null;
      } else {
        const data = action.payload.data() as groupInterface;
        data.id = action.payload.id;
        return data;
      }
    }));
  }

  // combox del grupo
  getSelectGroup() {
    this.groupCollection = this.afs.collection<groupInterface>('group', ref => ref.orderBy('name', 'asc'));
    return this.groups = this.groupCollection.snapshotChanges()
    .pipe(map(changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as groupInterface;
        data.id = action.payload.doc.id;
        return data;
      });
    }));
  }

  addGroup(group: groupInterface): void {
    this.groupCollection.add(group);
  }

  updateGroup(group: groupInterface): void {
    let idGroup = group.id;
    this.groupDoc = this.afs.doc<groupInterface>(`group/${idGroup}`); // group es el nombre de la colletion de la tabla
    this.groupDoc.update(group);
  }

  deleteGroup(idGroup: string): void {
    this.groupDoc = this.afs.doc<groupInterface>(`group/${idGroup}`);
    this.groupDoc.delete();
  }

  // SUBGROUP COMPONENTES
  getAllSubgroup() {
    this.subgroupCollection = this.afs.collection<SubgroupInterface>('subgroup', ref => ref.orderBy('group', 'asc'));
    return this.subgroups = this.subgroupCollection.snapshotChanges()
    .pipe(map(changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as SubgroupInterface;
        data.id = action.payload.doc.id;
        return data;
      });
    }));
  }

 // combox Sub grupo
  getSelectSubGroup() {
    this.subgroupCollection = this.afs.collection<SubgroupInterface>('subgroup');
    return this.subgroups = this.subgroupCollection.snapshotChanges()
    .pipe(map(changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as SubgroupInterface;
        data.id = action.payload.doc.id;
        return data;
      });
    }));
  }

  getOneSubGroup(idSubGroup: string) {
    this.subgroupDoc = this.afs.doc<SubgroupInterface>(`subgroup/${idSubGroup}`);
    return this.subgroup  = this.subgroupDoc.snapshotChanges().pipe(map(action => {
      if (action.payload.exists === false) {
        return null;
      } else {
        const data = action.payload.data() as SubgroupInterface;
        data.id = action.payload.id;
        return data;
      }
    }));
  }

  // busca en los query de borrar y contar que codigo le corresponde
  getSelectSubGroupOne(id: string) {
    this.subgroupCollection = this.afs.collection<SubgroupInterface>('subgroup', ref => ref.where('groupId', '==', id));
    return this.subgroups = this.subgroupCollection.snapshotChanges()
    .pipe(map(changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as SubgroupInterface;
        data.id = action.payload.doc.id;
        return data;
      });
    }));
  }

  addSubgroup(subgroup: SubgroupInterface): void {
    this.subgroupCollection.add(subgroup);
  }

  updateSubgroup(subgroup: SubgroupInterface): void {
    let idSubgroup = subgroup.id;
    this.subgroupDoc = this.afs.doc<SubgroupInterface>(`subgroup/${idSubgroup}`); // group es el nombre de la colletion de la tabla
    this.subgroupDoc.update(subgroup);
  }

  deleteSubgroup(idSubgroup: string): void {
    this.subgroupDoc = this.afs.doc<SubgroupInterface>(`subgroup/${idSubgroup}`);
    this.subgroupDoc.delete();
  }

  // FAMILY COMPONENTS
  getAllFamily() {
    this.familyCollection = this.afs.collection<FamilyInterface>('family', ref => ref.orderBy('cod', 'asc'));
    return this.familys = this.familyCollection.snapshotChanges()
    .pipe(map(changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as FamilyInterface;
        data.id = action.payload.doc.id;
        return data;
      });
    }));
  }

  getAllFamilyName() {
    this.familyCollection = this.afs.collection<FamilyInterface>('family', ref => ref.orderBy('name', 'asc'));
    return this.familys = this.familyCollection.snapshotChanges()
    .pipe(map(changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as FamilyInterface;
        data.id = action.payload.doc.id;
        return data;
      });
    }));
  }

  getAllFamilyGroup() {
    this.familyCollection = this.afs.collection<FamilyInterface>('family', ref => ref.orderBy('group', 'desc'));
    return this.familys = this.familyCollection.snapshotChanges()
    .pipe(map(changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as FamilyInterface;
        data.id = action.payload.doc.id;
        return data;
      });
    }));
  }


  // carga la lista de servicios segun el suggrupo en un combox
  getSelectCboFamily(id: string) {
    this.familyCollection = this.afs.collection<FamilyInterface>('family', ref => ref.where('subgroupId', '==', id));
    return this.familys = this.familyCollection.snapshotChanges()
    .pipe(map(changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as FamilyInterface;
        data.id = action.payload.doc.id;
        return data;
      });
    }));
  }

  // Busca por id de group en family para actualizar
  getSelectGroupId(id: string) {
    this.familyCollection = this.afs.collection<FamilyInterface>('family', ref => ref.where('groupId', '==', id));
    return this.familys = this.familyCollection.snapshotChanges()
    .pipe(map(changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as FamilyInterface;
        data.id = action.payload.doc.id;
        return data;
      });
    }));
  }

 // valida si el registro de subgrupo tiene movimiento
  getFamilyDelete(id: string) {
    this.familyCollection = this.afs.collection<FamilyInterface>('family', ref => ref.where('subgroupId', '==', id));
    return this.familys = this.familyCollection.snapshotChanges()
    .pipe(map(changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as FamilyInterface;
        data.id = action.payload.doc.id;
        return data;
      });
    }));
  }

  getSelectCodigo(id: string, idGroup: string, idSubGroup) {
    this.familyCollection = this.afs.collection<FamilyInterface>('family', ref => ref
    .where('id', '==', id)
    .where('groupId', '==', idGroup)
    .where('subgroupId', '==', idSubGroup));
    return this.familys = this.familyCollection.snapshotChanges()
    .pipe(map(changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as FamilyInterface;
        data.id = action.payload.doc.id;
        return data;
      });
    }));
  }

  // consulta de un dato de family
  getOneFamily(idFamily: string) {
    this.familyDoc = this.afs.doc<FamilyInterface>(`family/${idFamily}`);
    return this.family  = this.familyDoc.snapshotChanges().pipe(map(action => {
      if (action.payload.exists === false) {
        return null;
      } else {
        const data = action.payload.data() as FamilyInterface;
        data.id = action.payload.id;
        return data;
      }
    }));
  }

  // xombo de family
  getSelectFamily() {
    this.familyCollection = this.afs.collection<FamilyInterface>('family', ref => ref.orderBy('name', 'asc'));
    return this.familys = this.familyCollection.snapshotChanges()
    .pipe(map(changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as FamilyInterface;
        data.id = action.payload.doc.id;
        return data;
      });
    }));
  }

  addFamily(family: FamilyInterface): void {
    this.familyCollection.add(family);
  }

  updateFamily(family: FamilyInterface): void {
    let idFamily = family.id;
    this.familyDoc = this.afs.doc<FamilyInterface>(`family/${idFamily}`); // group es el nombre de la colletion de la tabla
    this.familyDoc.update(family);
  }

  deleteFamily(idFamily: string): void {
    this.familyDoc = this.afs.doc<FamilyInterface>(`family/${idFamily}`);
    this.familyDoc.delete();
  }

  // STATE COMPONENTS
  getAllState() {
    this.stateCollection = this.afs.collection<StateInterface>('state');
    return this.states = this.stateCollection.snapshotChanges()
    .pipe(map(changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as StateInterface;
        data.id = action.payload.doc.id;
        return data;
      });
    }));
  }

// combox del state
  getSelectState() {
    this.stateCollection = this.afs.collection<StateInterface>('state');
    return this.states = this.stateCollection.snapshotChanges()
    .pipe(map(changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as StateInterface;
        data.id = action.payload.doc.id;
        return data;
      });
    }));
  }

  // busca solo un estado por su id
  getOneState(idState: string) {
    this.stateDoc = this.afs.doc<StateInterface>(`state/${idState}`);
    return this.state  = this.stateDoc.snapshotChanges().pipe(map(action => {
      if (action.payload.exists === false) {
        return null;
      } else {
        const data = action.payload.data() as StateInterface;
        data.id = action.payload.id;
        return data;
      }
    }));
  }

  addState(state: StateInterface): void {
  this.stateCollection.add(state);
  }

  updateState(state: StateInterface): void {
    let idState = state.id;
    this.stateDoc = this.afs.doc<StateInterface>(`state/${idState}`); // group es el nombre de la colletion de la tabla
    this.stateDoc.update(state);
  }

  deleteState(idState: string): void {
    this.stateDoc = this.afs.doc<StateInterface>(`state/${idState}`);
    this.stateDoc.delete();
  }

  // EVALUATION COMPONENTS
  getAllEvaluation() {
    this.evaluationCollection = this.afs.collection<EvaluationInterface>('evaluation');
    return this.evaluations = this.evaluationCollection.snapshotChanges()
    .pipe(map(changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as EvaluationInterface;
        data.id = action.payload.doc.id;
        return data;
      });
    }));
  }

  // combox de evaluacion
  getSelectEvaluation() {
    this.evaluationCollection = this.afs.collection<EvaluationInterface>('evaluation');
    return this.evaluations = this.evaluationCollection.snapshotChanges()
    .pipe(map(changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as EvaluationInterface;
        data.id = action.payload.doc.id;
        return data;
      });
    }));
  }

  // Buscar la evaluacion segun el nivel y la fecha
  getSelectEvaluationTask(id: string) {
    this.evaluationCollection = this.afs.collection<EvaluationInterface>('evaluation', ref => ref.where('nivel', '==', id));
    return this.evaluations = this.evaluationCollection.snapshotChanges()
    .pipe(map(changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as EvaluationInterface;
        data.id = action.payload.doc.id;
        return data;
      });
    }));
  }

  // busca una evaluation por su id
  getOneEvaluation(idEvaluation: string) {
    this.evaluationDoc = this.afs.doc<EvaluationInterface>(`evaluation/${idEvaluation}`);
    return this.evaluation  = this.evaluationDoc.snapshotChanges().pipe(map(action => {
      if (action.payload.exists === false) {
        return null;
      } else {
        const data = action.payload.data() as EvaluationInterface;
        data.id = action.payload.id;
        return data;
      }
    }));
  }

  addEvaluation(evaluation: EvaluationInterface): void {
    this.evaluationCollection.add(evaluation);
  }

  updateEvaluation(evaluation: EvaluationInterface): void {
      let idEva = evaluation.id;
      this.evaluationDoc = this.afs.doc<EvaluationInterface>(`evaluation/${idEva}`); // group es el nombre de la colletion de la tabla
      this.evaluationDoc.update(evaluation);
  }

  deleteEvaluation(idEva: string): void {
      this.evaluationDoc = this.afs.doc<EvaluationInterface>(`evaluation/${idEva}`);
      this.evaluationDoc.delete();
  }

  // CUSTOMERS COMPONENETS
  getAllCustomers() {
    this.customerCollection = this.afs.collection<CustomersInterface>('customers');
    return this.customers = this.customerCollection.snapshotChanges()
    .pipe(map(changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as CustomersInterface;
        data.id = action.payload.doc.id;
        return data;
      });
    }));
  }

  // combox de customer
  getSelectCustomer() {
    this.customerCollection = this.afs.collection<CustomersInterface>('customers', ref => ref.where('activo', '==', true).orderBy('company', 'asc'));
    return this.customers = this.customerCollection.snapshotChanges()
    .pipe(map(changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as CustomersInterface;
        data.id = action.payload.doc.id;
        return data;
      });
    }));
  }

  // saca un dato de un campo de customer
  getOneCustomer(idCustomer: string) {
    this.customerDoc = this.afs.doc<CustomersInterface>(`customers/${idCustomer}`);
    return this.customer  = this.customerDoc.snapshotChanges().pipe(map(action => {
      if (action.payload.exists === false) {
        return null;
      } else {
        const data = action.payload.data() as CustomersInterface;
        data.id = action.payload.id;
        return data;
      }
    }));
  }

   // Busca al cliente por el UserId
  getOneCustomerUser(idUser: string) {
    this.customerCollection = this.afs.collection<CustomersInterface>('customers', ref => ref.where('userUid', '==', idUser));
    return this.customers = this.customerCollection.snapshotChanges()
    .pipe(map(changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as CustomersInterface;
        data.id = action.payload.doc.id;
        return data;
      });
    }));
  }

  addCustomer(customer: CustomersInterface): void {
    this.customerCollection.add(customer);
  }

  addSubmissions() {
    this.customerCollection = this.afs.collection('submissions');
  }

  updateCustomer(customer: CustomersInterface): void {
    let idCustomer = customer.id;
    this.customerDoc = this.afs.doc<CustomersInterface>(`customers/${idCustomer}`);
    this.customerDoc.update(customer);
  }
  deleteCustomer(idCustomer: string): void {
    this.customerDoc = this.afs.doc<CustomersInterface>(`customers/${idCustomer}`);
    this.customerDoc.delete();
  }

    // TASK_DETAILS COMPONENETS
    getAllTaskDetail() {
      this.taskdetailCollection = this.afs.collection<TaskDetailsInterface>('task_details', ref => ref.where('activo', '==', true).orderBy('razon', 'desc'));
      return this.taskdetails = this.taskdetailCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskDetailsInterface;
          data.id = action.payload.doc.id;
          // console.log('CUSTOME: ', data);
          return data;
        });
      }));
    }

    getAdminDetailUserUid(Id: string) {
      this.taskdetailCollection = this.afs.collection<TaskDetailsInterface>('task_details', ref => ref.where('adminId', '==', Id).where('activo', '==', true).orderBy('razon', 'desc'));
      return this.taskdetails = this.taskdetailCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskDetailsInterface;
          data.id = action.payload.doc.id;
          // console.log('CUSTOME: ', data);
          return data;
        });
      }));
    }

    getAllTaskDetailUserUid(Id: string) {
      this.taskdetailCollection = this.afs.collection<TaskDetailsInterface>('task_details', ref => ref.where('userUid', '==', Id).where('activo', '==', true));
      return this.taskdetails = this.taskdetailCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskDetailsInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
    }

    getAllTaskDetailTaskid(Id: string) {
      this.taskdetailCollection = this.afs.collection<TaskDetailsInterface>('task_details', ref => ref.where('taskId', '==', Id)
      .orderBy('date', 'desc'));
      return this.taskdetails = this.taskdetailCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskDetailsInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
    }

  // para lo observable que cargue solo los cliente segun la tabla customer
    getAllTaskDetailUser(Id: string) {
       this.taskdetailCollection = this.afs.collection<TaskDetailsInterface>('task_details', ref => ref.where('customersId', '==', Id).where('activo', '==', true));
       return this.taskdetails = this.taskdetailCollection.snapshotChanges()
       .pipe(map(changes => {
         return changes.map(action => {
           const data = action.payload.doc.data() as TaskDetailsInterface;
           data.id = action.payload.doc.id;
           // console.log('CUSTOME: ', data);
           return data;
         });
       }));
     }

     // para lo observable que cargue solo los cliente segun la tabla customer
    getAllTaskDetServiceId(Id: string) {
       this.taskdetailCollection = this.afs.collection<TaskDetailsInterface>('task_details', ref => ref.where('serviceId', '==', Id).where('activo', '==', true));
       return this.taskdetails = this.taskdetailCollection.snapshotChanges()
       .pipe(map(changes => {
         return changes.map(action => {
           const data = action.payload.doc.data() as TaskDetailsInterface;
           data.id = action.payload.doc.id;
           // console.log('CUSTOME: ', data);
           return data;
         });
       }));
     }

     // valida si tiene la tarea movimiento en observaciones para su elimnacion de dicha tarea.
    getAllTaskDetailDelete(Id: string) {
      this.taskdetailCollection = this.afs.collection<TaskDetailsInterface>('task_details', ref => ref.where('taskId', '==', Id));
      return this.taskdetails = this.taskdetailCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as TaskDetailsInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
    }

    addTaskDet(taskdet: TaskDetailsInterface): void {
      this.taskdetailCollection.add(taskdet);
    }

    updateTaskOb(task: TaskDetailsInterface): void {
      let idTasks = task.id;
      this.taskdetailDoc = this.afs.doc<TaskDetailsInterface>(`task_details/${idTasks}`);
      this.taskdetailDoc.update(task);
    }

    // DOSAGE LISTADO
    getAllDosage() {
      this. dosageCollection = this.afs.collection<DosageInterface>('dosage');
      return this.dosages = this.dosageCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as DosageInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
    }

    getAllDosageUid(id: string) {
      this. dosageCollection = this.afs.collection<DosageInterface>('dosage', ref => ref.where('userUid', '==', id));
      return this.dosages = this.dosageCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as DosageInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
    }

    getAllDosageCliente(id: string) {
      this. dosageCollection = this.afs.collection<DosageInterface>('dosage', ref => ref.where('customersId', '==', id));
      return this.dosages = this.dosageCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as DosageInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
    }

    getAllDosageFamily(id: string) {
      this. dosageCollection = this.afs.collection<DosageInterface>('dosage', ref => ref.where('familyId', '==', id));
      return this.dosages = this.dosageCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as DosageInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
    }

    getAllDosageSubGroup(id: string) {
      this. dosageCollection = this.afs.collection<DosageInterface>('dosage', ref => ref.where('subgroupId', '==', id));
      return this.dosages = this.dosageCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as DosageInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
    }

    getAllDosageGroup(id: string) {
      this. dosageCollection = this.afs.collection<DosageInterface>('dosage', ref => ref.where('groupId', '==', id));
      return this.dosages = this.dosageCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as DosageInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
    }

    getOneDosage(idDosage: string) {
      this.dosageDoc = this.afs.doc<DosageInterface>(`dosage/${idDosage}`);
      return this.dosage  = this.dosageDoc.snapshotChanges().pipe(map(action => {
        if (action.payload.exists === false) {
          return null;
        } else {
          const data = action.payload.data() as DosageInterface;
          data.id = action.payload.id;
          return data;
        }
      }));
    }


    addDosage(dosage: DosageInterface): void {
      this.dosageCollection.add(dosage);
    }

    updateDosage(dosage: DosageInterface): void {
      let idDosage = dosage.id;
      this.dosageDoc = this.afs.doc<DosageInterface>(`dosage/${idDosage}`);
      this.dosageDoc.update(dosage);
    }


    deleteDosage(idDosage: string): void {
        this.dosageDoc = this.afs.doc<DosageInterface>(`dosage/${idDosage}`);
        this.dosageDoc.delete();
    }

     // ACTIVITY LISTADO
     getAllActivity() {
      this. activityCollection = this.afs.collection<ActivityInterface>('activity');
      return this.activitys = this.activityCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as ActivityInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
    }

    addActivity(activity: ActivityInterface): void {
      this.activityCollection.add(activity);
    }
    updateActivity(activity: ActivityInterface): void {
      let idActivity = activity.id;
      this.activityDoc = this.afs.doc<ActivityInterface>(`activity/${idActivity}`);
      this.activityDoc.update(activity);
    }
    deleteActivity(idActivity: string): void {
        this.activityDoc = this.afs.doc<ActivityInterface>(`activity/${idActivity}`);
        this.activityDoc.delete();
    }

     // REQUEST LISTADO
     getAllRequest() {
      this.requestCollection = this.afs.collection<RequestInterface>('request');
      return this.requests = this.requestCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as RequestInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
    }

    getAllRequestUpdate(id: string) {
      this.requestCollection = this.afs.collection<RequestInterface>('request', ref => ref.where('id', '==', id));
      return this.requests = this.requestCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as RequestInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
    }

    getAllRequestState() {
      this.requestCollection = this.afs.collection<RequestInterface>('request', ref => ref.where('state', '==', false));
      return this.requests = this.requestCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as RequestInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
    }

    getOneRequest(idRequest: string) {
      this.requestDoc = this.afs.doc<RequestInterface>(`request/${idRequest}`);
      return this.request  = this.requestDoc.snapshotChanges().pipe(map(action => {
        if (action.payload.exists === false) {
          return null;
        } else {
          const data = action.payload.data() as RequestInterface;
          data.id = action.payload.id;
          return data;
        }
      }));
    }


    getAllRequestCustomer(Id: string) {
      this.requestCollection = this.afs.collection<RequestInterface>('request', ref => ref.where('customersId', '==', Id));
      return this.requests = this.requestCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as RequestInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
    }

    addRequest(request: RequestInterface): void {
      this.requestCollection.add(request);
    }


    updateRequest(request: RequestInterface): void {
      let idRequest = request.id;
      this.requestDoc = this.afs.doc<RequestInterface>(`request/${idRequest}`);
      this.requestDoc.update(request);
    }

    deleteRequest(idRequest: string): void {
        this.requestDoc = this.afs.doc<RequestInterface>(`request/${idRequest}`);
        this.requestDoc.delete();
    }

    // MENSAJES DE 5 DIAS ANTES DE FECHA LIMITE y MENSAJE A SU CORREO AL GRABAR NUEVA AGENDA TRIBUTARIA
    getMessageDosageId(Id: string) {
      this.messageCollection = this.afs.collection<MessageInterface>('message', ref => ref.where('dosageId', '==', Id));
      return this.messages = this.messageCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as MessageInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
    }

    // REGISTRO DE LOS MENSAJES DE LAS TAREAS POR CONCLUIR 5 DIAS ANTES DE LA FECHA LIMITE
    addMessage(message: MessageInterface): void {
      this.messageCollection.add(message);
    }

    // MENSAJES DE 1 DIA DEL MES ANTE DE FECHA LIMITE
    getMessagemONTHDosageId(Id: string) {
      this.messagemonthCollection = this.afs.collection<MessageMonthInterface>('messagemonth', ref => ref.where('dosageId', '==', Id));
      return this.messagemonths = this.messagemonthCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as MessageMonthInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
    }

    // REGISTRO DE LOS MENSAJES 1 DIA DEL MES ANTE DE FECHA LIMITE
    addMessageMonth(message: MessageMonthInterface): void {
      this.messagemonthCollection.add(message);
    }


    // DATOS DE LOS USUARIOS DE LOS ENCARGADOS
    getAllDataUser() {
      this.dataUserCollection = this.afs.collection<DataUserInterface>('datauser');
      return this.dataUsers = this.dataUserCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as DataUserInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
    }

    getOneDataUser(idDataUser: string) {
      this.dataUserDoc = this.afs.doc<DataUserInterface>(`datauser/${idDataUser}`);
      return this.dataUser  = this.dataUserDoc.snapshotChanges().pipe(map(action => {
        if (action.payload.exists === false) {
          return null;
        } else {
          const data = action.payload.data() as DataUserInterface;
          data.id = action.payload.id;
          return data;
        }
      }));
    }

    getAllDataUserUid(Id: string) {
      this.dataUserCollection = this.afs.collection<DataUserInterface>('datauser', ref => ref.where('userUid', '==', Id));
      return this.dataUsers = this.dataUserCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as DataUserInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
    }

    getDataUserNivel(Id: string, nivel: string) {
      this.dataUserCollection = this.afs.collection<DataUserInterface>('datauser', ref => ref.where('userUid', '==', Id)
      .where('nivel', '==', nivel));
      return this.dataUsers = this.dataUserCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as DataUserInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
    }

    getNivelDataUserUid(nivel: string) {
      this.dataUserCollection = this.afs.collection<DataUserInterface>('datauser', ref => ref.where('nivel', '==', nivel));
      return this.dataUsers = this.dataUserCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as DataUserInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
    }

    addDataUser(dataUser: DataUserInterface): void {
      this.dataUserCollection.add(dataUser);
    }

    updateDataUser(dataUser: DataUserInterface): void {
      let idDataUser = dataUser.id;
      this.dataUserDoc = this.afs.doc<DataUserInterface>(`datauser/${idDataUser}`);
      this.dataUserDoc.update(dataUser);
    }

    deleteDataUser(idDataUser: string): void {
        this.dataUserDoc = this.afs.doc<DataUserInterface>(`datauser/${idDataUser}`);
        this.dataUserDoc.delete();
    }

     // DATOS DE CAROS DE FUNCIONARIOS
     getAllPosition() {
      this.positionCollection = this.afs.collection<PositionInterface>('position');
      return this.positions = this.positionCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as PositionInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
    }


     getPositionNivel(Id: string) {
      this.positionCollection = this.afs.collection<PositionInterface>('position', ref => ref.where('nivel', '==', Id));
      return this.positions = this.positionCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as PositionInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
    }

    getOnePosition(idPosition: string) {
      this.positionDoc = this.afs.doc<PositionInterface>(`position/${idPosition}`);
      return this.position  = this.positionDoc.snapshotChanges().pipe(map(action => {
        if (action.payload.exists === false) {
          return null;
        } else {
          const data = action.payload.data() as PositionInterface;
          data.id = action.payload.id;
          return data;
        }
      }));
    }


    // DATOS DE LOS USUARIOS DE LOS ENCARGADOS y SUS FAMILIAREAS
    getAllDataUserDet() {
      this.dataUserDetCollection = this.afs.collection<DataUserDetInterface>('datauserdet');
      return this.dataUserDets = this.dataUserDetCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as DataUserDetInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
    }

    getOneDataUserDet(idDataUserDet: string) {
      this.dataUserDetDoc = this.afs.doc<DataUserDetInterface>(`datauserdet/${idDataUserDet}`);
      return this.dataUserDet  = this.dataUserDetDoc.snapshotChanges().pipe(map(action => {
        if (action.payload.exists === false) {
          return null;
        } else {
          const data = action.payload.data() as DataUserDetInterface;
          data.id = action.payload.id;
          return data;
        }
      }));
    }

    // busca  por funcionario
    getAllDataUserDetUid(Id: string) {
      this.dataUserDetCollection = this.afs.collection<DataUserDetInterface>('datauserdet', ref => ref.where('datauserId', '==', Id));
      return this.dataUserDets = this.dataUserDetCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as DataUserDetInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
    }

    addDataUserDet(dataUserDet: DataUserDetInterface): void {
      this.dataUserDetCollection.add(dataUserDet);
    }

    updateDataUserDet(dataUserDet: DataUserDetInterface): void {
      let idDataUserDet = dataUserDet.id;
      this.dataUserDetDoc = this.afs.doc<DataUserDetInterface>(`datauserdet/${idDataUserDet}`);
      this.dataUserDetDoc.update(dataUserDet);
    }

    deleteDataUserDet(idDataUserDet: string): void {
        this.dataUserDetDoc = this.afs.doc<DataUserDetInterface>(`datauserdet/${idDataUserDet}`);
        this.dataUserDetDoc.delete();
    }


    // DATOS DE BLOG
    getAllBlog() {
      this.blogCollection = this.afs.collection<BlogInterface>('blog', ref => ref.orderBy('id', 'desc'));
      return this.blogs = this.blogCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as BlogInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
    }

    getOneBlog(idBlog: string) {
      this.blogDoc = this.afs.doc<BlogInterface>(`blog/${idBlog}`);
      return this.blog  = this.blogDoc.snapshotChanges().pipe(map(action => {
        if (action.payload.exists === false) {
          return null;
        } else {
          const data = action.payload.data() as BlogInterface;
          data.id = action.payload.id;
          return data;
        }
      }));
    }

    addBlog(blog: BlogInterface): void {
      this.blogCollection.add(blog);
    }

    updateBlog(blog: BlogInterface): void {
      let idBlog = blog.id;
      this.blogDoc = this.afs.doc<BlogInterface>(`blog/${idBlog}`);
      this.blogDoc.update(blog);
    }

    deleteBlog(idBlog: string): void {
        this.blogDoc = this.afs.doc<BlogInterface>(`blog/${idBlog}`);
        this.blogDoc.delete();
    }


    // DATOS DE SERVICES DE ROLES DE LOS JEFES FUNCIONARIOS
    getAllServices() {
      this.servicesCollection = this.afs.collection<ServicesInterface>('services', ref => ref.orderBy('name', 'desc'));
      return this.services = this.servicesCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as ServicesInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
    }

    getServicesSearch(id: string) {
      this.servicesCollection = this.afs.collection<ServicesInterface>('services', ref => ref.where('userUid', '==', id).orderBy('name', 'desc'));
      return this.services = this.servicesCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as ServicesInterface;
          data.id = action.payload.doc.id;
          return data;
        });
      }));
    }

    getOneServices(id: string) {
      this.servicesDoc = this.afs.doc<ServicesInterface>(`services/${id}`);
      return this.service  = this.servicesDoc.snapshotChanges().pipe(map(action => {
        if (action.payload.exists === false) {
          return null;
        } else {
          const data = action.payload.data() as ServicesInterface;
          data.id = action.payload.id;
          return data;
        }
      }));
    }

    addServices(service: ServicesInterface): void {
      this.servicesCollection.add(service);
    }

    updateServices(service: ServicesInterface): void {
      let idService = service.id;
      this.servicesDoc = this.afs.doc<ServicesInterface>(`services/${idService}`);
      this.servicesDoc.update(service);
    }

    deleteServices(id: string): void {
        this.servicesDoc = this.afs.doc<ServicesInterface>(`services/${id}`);
        this.servicesDoc.delete();
    }

}
