import { Component, OnInit  } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'ServiContable Ltda.';
  public isError: boolean = false;
  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
   this.onisError();
   this.getCurrentUser();
  }

  onisError(): void {
    this.isError = true;
    setTimeout(() => {
      this.isError = false;
    }, 2000);
  }

  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.router.navigate(['user/login']);
      } else {
        this.router.navigate(['/']);
      }
    });
  }


}
