import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-outsourcing',
  templateUrl: './page-outsourcing.component.html',
  styleUrls: ['./page-outsourcing.component.css']
})
export class PageOutsourcingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
