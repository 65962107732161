import { Component, OnInit } from '@angular/core';
import { DataApiService } from '../../../services/data-api.service';
import { FamilyInterface } from '../../../models/family';
// Para ver los roles de ususarioa
import { AuthService } from '../../../services/auth.service';
import * as printJS from 'print-js';


@Component({
  selector: 'app-list-family',
  templateUrl: './list-family.component.html',
  styleUrls: ['./list-family.component.css']
})
export class ListFamilyComponent implements OnInit {

  constructor(private dataApi: DataApiService, private authService: AuthService) { }
  public familys: FamilyInterface[];
  public isAadmin: any = null;
  public userUid: string;
  public SubGroupId: string;
  public isUpdate: boolean = false;
  public isError = false;
  public isMovimiento: boolean = false;

  filterpost = '';
  pageActual: number = 1;

  ngOnInit() {
    this.getListFamily();
    this.getCurrentUser();
  }

  nuevo() {
    this.isUpdate = false;
    this.isMovimiento = false;
  }


  validarUpdate(id: string) {
    this.dataApi.getAllTasksFamily(id).subscribe( tasks => {
      if (tasks.length > 0 ) {
        this.isMovimiento = true;
      } else {
        this.isMovimiento = false;
      }
   });

  }

  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
            this.isAadmin = Object.assign({}, userRole.roles).hasOwnProperty('Aadmin');
        });
      }
    });
  }

  getListFamily() {
    this.dataApi.getAllFamily().subscribe( familys => {
      this.familys = familys;
    });
  }

  onDeleteFamily(idFamily: string) {
    const confirmacion = confirm('Estás seguro ?');
    if (confirmacion) {
        this.dataApi.getAllTasksFamily(idFamily).subscribe( tasks => {
          if (tasks.length > 0 ) {
            this.onisError();
          } else {
            this.dataApi.deleteFamily(idFamily);
          }
       });
    }
  }

   // carga el update al modal
   onPreUpdateFamily(family: FamilyInterface) {
    this.isUpdate = true;
    this.SubGroupId = family.subgroupId;
    this.dataApi.selectFamily = Object.assign({}, family);
  }

  onisError(): void {
    this.isError = true;
    setTimeout(() => {
      this.isError = false;
    }, 3000);
  }

  downloadPdf() {
    printJS({printable: this.familys, properties: [
      {field: 'group', displayName: 'Grupo'},
      {field: 'subgroup', displayName: 'Sub Grupo'},
      {field: 'cod', displayName: 'Código'},
      {field: 'name', displayName: 'Servicio'}
    ], type: 'json',
       documentTitle: 'Control de Trámites de ServiContable13 Srl.',
       header: '<h4 class="custom-h4">Listado de Servicios: </h4>' + '<br><br>',
       headerStyle: 'font-weight: 300;',
       font: 'Century Gothic',
       style: '.custom-h4 { color: blue; }',
       gridHeaderStyle: 'font-weight: bold; padding: 5px; border: 1px solid #dddddd; width:auto;'});
  }


}
