import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterObs'
})
export class FilterObsPipe implements PipeTransform {
  transform(value: any, arg: any): any {
    if (arg === '' || arg.length < 3) return value;
    const resultPosts = [];
    for (const post of value) {
      if (
      post.cod.toLowerCase().indexOf(arg.toLowerCase()) > -1 ||
      post.fechainicial.toLowerCase().indexOf(arg.toLowerCase()) > -1 ||
      post.fechafinal.toLowerCase().indexOf(arg.toLowerCase()) > -1 ||
      post.service.toLowerCase().indexOf(arg.toLowerCase()) > -1 ) {
        resultPosts.push(post);
      }
    }
    return resultPosts;
  }
}
