import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataApiService } from '../../../services/data-api.service';
import { AuthService } from '../../../services/auth.service';
import * as moment from 'moment';
moment.locale('es')
import 'moment/locale/bo';
import {formatDate} from '@angular/common';
import Swal from 'sweetalert2';
import { MensajesService } from '../../../services/mensajes.service';
import { DataUserInterface } from 'src/app/models/dataUser';


@Component({
  selector: 'app-modal-supervisor',
  templateUrl: './modal-supervisor.component.html',
  styleUrls: ['./modal-supervisor.component.css']
})
export class ModalSupervisorComponent implements OnInit {

  constructor(public dataApi: DataApiService, public authService: AuthService, private msj: MensajesService) { }
  @ViewChild('btnClose', { static: false}) btnClose: ElementRef;
  @Input() userUid: string; // para pasar al modal el userid logueado
  @Input() isAadmin: boolean;
  @Input() adminId: boolean;
  @Input() isUpdate: boolean;

  public dataUsers: DataUserInterface[];

  public activo: boolean = false;
  public stateId: string;
  public evaluationId: string;
  public estado: string;
  public evaluacion: string;
  public nivel: string = '1'; // nivel de jefe funcionario
  public confirTraspaso: boolean;
  public userId: string = '';

  ngOnInit() {
    this.confirTraspaso = false;
    this.getListUsers(this.nivel);
  }

  // carga el listado de funcionarios
  getListUsers(id: string) {
    this.dataApi.getNivelDataUserUid(id).subscribe(dataUsers => {
      if (dataUsers.length > 0) {
        this.dataUsers = dataUsers;
      }
    });
  }

  // Busca o carga el listad de todo los servicios que se le fueron asigando
  getSelectUser(id: string) {
    if (id != null) {
      this.userId = id;
      this.confirTraspaso = true;
    }
  }

  resetForm(taskForm: NgForm) {
    taskForm.resetForm(); // para limpiar el formulario
  }

  onSaveTask(taskForm: NgForm): void {
    const confir = confirm('Esta seguro de los cambios... ?');
    if (confir) {
      if (this.confirTraspaso === false) {
        this.stateId = 'O0k77hWryKKIbCPHdBn6';
        this.estado = 'En Proceso';
        this.evaluationId = '71U4A72QmvjK4bbAA9rl';
        this.evaluacion = 'Dentro del Rango';
        taskForm.value.stateId = this.stateId; // nombre del estado
        taskForm.value.estado = this.estado; // nombre del estado
        taskForm.value.evaluacion = this.evaluacion; // nombre de la evaluacion
        taskForm.value.evaluationId = this.evaluationId;
      }
      if (this.confirTraspaso === true) {
        taskForm.value.adminId = this.userId;
      }
      this.dataApi.updateTask(taskForm.value);
      if (this.confirTraspaso === false) {
        this.msj.mensajeCorrecto('Habilito', 'Se habilito correctamente la tarea');
      }
      if (this.confirTraspaso === true) {
        this.msj.mensajeCorrecto('Traspaso', 'Se trasapaso correctamente la tarea');
      }

      taskForm.resetForm(); // para limpiar el formulario
      this.btnClose.nativeElement.click(); // cierra el modal
    }

  }

}
