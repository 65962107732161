import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { DataApiService } from '../../services/data-api.service';
import { NgForm } from '@angular/forms';
import { StateInterface } from '../../models/state';
import { EvaluationInterface } from '../../models/evaluation';
import { CustomersInterface } from '../../models/customers';
import { UserInterface } from '../../models/user';
import { AuthService } from '../../services/auth.service';
import * as moment from 'moment';
moment.locale('es');
import 'moment/locale/bo';
import { DataUserInterface } from '../../models/dataUser';
import { ServicesInterface } from '../../models/services';
import {formatDate} from '@angular/common';
import Swal from 'sweetalert2';
import { MensajesService } from 'src/app/services/mensajes.service';
import { RequestInterface } from 'src/app/models/request';
import { TaskInterface } from 'src/app/models/task';
import { TaskDetailsInterface } from 'src/app/models/task_details';


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  constructor(
    public dataApi: DataApiService,
    public authService: AuthService,
    private msj: MensajesService
  ) {}
  @ViewChild('btnClose', { static: false }) btnClose: ElementRef;
  @Input() userUid: string; // para pasar al modal el userid logueado
  @Input() isAdmin: boolean;
  @Input() isUpdate: boolean;
  @Input() isMovimiento: boolean;

  @Input() isSolicitudTarea: boolean;
  @Input() observacion: string;
  @Input() requestId: string;

  @Input() isCustomersId: string;
  @Input() isCustomer: string;
  @Input() isEmailCust: string;
  @Input() isEmailCC: string;
  @Input() isNameCC: string;
  @Input() isRazon: string;

  @Input() isfamilyId: string;
  @Input() CustomersId: string;
  @Input() userName: string;
  @Input() userEmail: string;
  @Input() titulo: string;
  @Input() descripcion: string;
  @Input() Observacion: string;

  public request: RequestInterface = {};

  public servicesRoles: ServicesInterface[];

  public states: StateInterface[];
  public evaluations: EvaluationInterface[];
  public customers: CustomersInterface[];
  public users: UserInterface[];
  public dataUsers: DataUserInterface[];
  public task: TaskInterface = {};
  public taskOb: TaskDetailsInterface = {};


  public customersId: string = '';
  public customer: string = '';
  public service: string = '';
  public userUidName: string = '';
  public userUidEmail: string = '';
  public emailCC: string = '';
  public nameCC: string = '';
  public razon: string = '';

  public stateId: string = '';
  public estado: string = '';
  public nivelState: string;
  public evaluationId: string = '';
  public evaluacion: string = '';
  public userId: string;
  public AadminId: any = null;
  public Aadmin: any = null;
  public adminId: any = null;
  public isEditor: any = null;
  public isError = false;

  public confCustomer: boolean = false;
  public confService: boolean = false;
  public confState: boolean = false;
  public confEvaluation: boolean = false;
  public confirFuncionario: boolean = false;

  public emailCust: string = '';
  public groupId: string = '';
  public subgroupId: string = '';
  public group: string = '';
  public subgroup: string = '';
  public familyId: string = '';

  public nivel: string = '2'; // nivel del funcionario

  public fechainicial: string = '';
  public fechafinal: string = '';
  public finalizado: string = formatDate(new Date(), 'dd-MM-yyyy, h:mm:ss a', 'en');
  public activo: boolean = true;
  public currentDate: any = moment().format('L'); // formato de forma local
  public fechaini: string = '';
  public fechafin: string = '';
  public totaldia: number = 0;
  public fechamostrar: string = '';
  public fechaformato: boolean = false;

  public FechaMes: string;
  duplicar: boolean = false;
  public authId: string = '';
  public dosageId: string = '0';
  public cod: string;
  public contador: number = 0;

  public fechaInitDupli: string = '';
  public fechaEndDupli: string = '';

  public ID: string = null;
  public IdCust: string = null;
  public count: number = 0;
  public num: number = 0;


  ngOnInit() {
    this.contadorCod();
    this.getListState();
    this.getListCustomer();
    this.getListUsers(this.nivel);
    this.getCurrentUser();
    this.fechaformato = false;
    this.num = this.dataApi.selectTask.codigo_control;
  }

  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
          this.Aadmin = Object.assign({}, userRole.roles).hasOwnProperty('Aadmin');
          this.isAdmin = Object.assign({}, userRole.roles).hasOwnProperty('admin');
          this.isEditor = Object.assign({}, userRole.roles).hasOwnProperty('editor');
          if (this.isEditor === true) {
            this.getListTasksFunc(this.userUid);
          }
          if (this.isAdmin === true) {
            this.getSelectUser(this.userUid); // carga el listado de los servicios que le corresponde
          }
          if (this.Aadmin === true) {
            //this.onService();
          }
        });
      }
    });
  }

  onService() {
    this.dataApi.getAllServices().subscribe(services => {
      this.servicesRoles = services;
    });
  }

  // carga todas su treas propias el encargado (editor)
  getListTasksFunc(id: string) {
    this.dataApi.getAllTasksUser(id).subscribe(tasks => {
      tasks.forEach( element => {
        this.getSelectUser(element.adminId);
      });
    });
  }

  getListUsers(id: string) {
    this.dataApi.getNivelDataUserUid(id).subscribe(dataUsers => {
      if (dataUsers.length > 0) {
        this.dataUsers = dataUsers;
      }
    });
  }

  // carga el nombre del funcionario
  getFuncionario(id: string) {
    if (id !== undefined) {
      this.userId = id;
      this.dataApi.getDataUserNivel(id, this.nivel).subscribe(data => {
        data.forEach(dat => {
          this.userUidName = dat.name;
          this.authId = dat.userUid;
          this.confirFuncionario = true;
          this.authService.getOneUser(this.authId).subscribe(users => {
            this.userUidEmail = users.email;
            this.confirFuncionario = true;
          });
        });
      });
    } else {
      this.confirFuncionario = false;
    }
  }

   // Busca o carga el listad de todo los servicios que se le fueron asigando
   getSelectUser(id: string) {
      this.getFuncionario(id);
      this.dataApi.getServicesSearch(id).subscribe(services => {
        if (services) {
          this.servicesRoles = services;
        }
      });
  }


  // busca por el id todo los servicios asignado por el jefe funcionario
  onSelectServices(idService: string) {
      this.dataApi.getOneServices(idService).subscribe(service => {
        if (service) {
          this.duplicar = true; // valida es verdadero no se puede hacer un duplicacion de datos.
          this.groupId = service.groupId;
          this.group = service.group;
          this.subgroupId = service.subgroupId;
          this.subgroup = service.subgroup;
          this.service = service.name;
          this.cod = service.cod;
          this.confService = true;
        }
      });
  }

  getListState() {
    this.dataApi.getSelectState().subscribe(states => {
      this.states = states;
    });
  }

  getListCustomer() {
    this.dataApi.getSelectCustomer().subscribe(customers => {
      this.customers = customers;
    });
  }

  getOneCustomer(idCustomers: string) {
    if (idCustomers != null) {
      this.dataApi.getOneCustomer(idCustomers).subscribe(customer => {
        if (customer) {
          this.duplicar = true; // valida es verdadero no se puede hacer un duplicacion de datos.
          this.confCustomer = true;
          this.customer = customer.name;
          this.emailCust = customer.email;
          this.emailCC = customer.emailCC;
          this.nameCC = customer.nameCC;
          this.razon = customer.company;
        }
      });
    }
  }

  // A : Busco el estado de la tarea mas su nivel
  getOneState(idState: string, bool: boolean) {
    if (bool) {
      if (idState != null) {
        this.dataApi.getOneState(idState).subscribe(state => {
          if (state) {
            this.confState = true;
            this.estado = state.name;
            this.nivelState = state.nivelState;

            if (this.isUpdate === false) {
              if (this.nivelState === '1') {
                this.activo = true;
                this.getEvaluationTask(this.nivelState);
              } else {
                this.onisError();
                this.evaluationId = '';
                this.evaluacion = '';
              }
            } else {
              if (this.nivelState === '1') {
                console.log('Selecciono el mismo Estado');
                this.activo = true;
                this.confEvaluation = false;
                this.evaluationId = '';
                this.evaluacion = '';
              } else {
                if (this.fechafinal !== null) {
                  this.confEvaluation = true;

                  //moment(this.fechafinal).format('L') >= moment().format('L')
                  if (
                    new Date(this.fechafinal) > new Date()
                  ) {
                    this.nivelState = '2'; // DENTRO DE LA FECHA ESTABLECIDA
                    this.activo = false;
                    this.getEvaluationTask(this.nivelState);
                  } else {
                    this.nivelState = '3'; // FUERA DE RANGO DE LA FECHA ESTABLECIDA
                    this.activo = false;
                    this.getEvaluationTask(this.nivelState);
                  }
                }
              }
            }
          }
        });
      }


    } else {
      Swal.fire({
        title: 'Taracoe',
        text: `Tarea sin Validar!`,
        imageUrl: 'https://firebasestorage.googleapis.com/v0/b/taracoe-3f6ba.appspot.com/o/FIAMImages%2Fpush.png?alt=media&token=01ee26c5-6e61-451e-9740-f3f2bd76acba',
        imageHeight: 100,
        imageWidth: 100,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#FF0000',
        confirmButtonText: 'Aceptar!',
       }).then ( result => {
         if (result.value) {

           Swal.fire('Validar!', 'La tarea no ha sido aún validada para cambiar el estado del proceso.', 'success');
         }
       });

    }

  }

  // B : Segun el estado busco en task las fecha de inicio y final de mencionada tarea
  getOneTask(idTask: string) {
    if (idTask != null) {
      this.dataApi.getOneTask(idTask).subscribe(task => {
        this.fechainicial = task.fechainicial;
        this.fechafinal = task.fechafinal;
      });
    } else {
      this.fechainicial = '';
      this.fechafinal = '';
    }
  }

  // C : BUSCA EL SEGUN EL NIVEL DEL ESTADO SU EVALUACION
  getEvaluationTask(nivel: string) {
    this.dataApi.getSelectEvaluationTask(nivel).subscribe(evaluation => {
      evaluation.forEach(eva => {
        this.evaluationId = eva.id;
        this.evaluacion = eva.name;
      });
    });
  }

  onisError(): void {
    this.isError = true;
    setTimeout(() => {
      this.isError = false;
    }, 3000);
  }

  resetForm(taskForm: NgForm) {
    taskForm.resetForm(); // para limpiar el formulario
  }



  fechaInicial(fechaIni: string) {
    if (fechaIni != null) {
      this.fechaini = fechaIni;
    }
  }

  fechaFinal(fechaFin: string) {
    this.fechaformato = false;
    if (fechaFin != null) {
      this.fechafin = fechaFin;
    }
  }

  // calcula la suma de un mes si desea repetir la tarea
  getMes() {
    let fecha = new Date();
    let dias = 31;
    fecha.setDate(fecha.getDate() + dias);
    this.fechainicial = moment(fecha, 'dd.mm.yyyy').format('YYYY-MM-DD');
    console.log('MES: ', this.fechainicial);
  }

  // calcula un mes calendario de la fecha inicial
  getMesLCV() {
    // (f.getDate() + "/" + (f.getMonth() +1) + "/" + f.getFullYear())
    let fecha = new Date();
    fecha.setMonth(fecha.getMonth() + 1);
    this.fechainicial = moment(fecha, 'dd.mm.yyyy').format('YYYY-MM-DD');
  }

  diaHabilesEntreFecha() {
    if (this.fechaini !== null && this.fechafin !== null) {
      var fechaini = new Date(this.fechaini);
      var fechafin = new Date(this.fechafin);
      var diasdif = fechafin.getTime() - fechaini.getTime();
      var contdias = Math.round(diasdif / (1000 * 60 * 60 * 24));
      this.totaldia = contdias;
    }
  }

  probar() {
    if (this.fechaini !== null) {
      var meses = '';
      var dias = '';
      var porId = document.getElementById('day')['value'];
      // console.log('dia: ', porId);
      var hoy = new Date(this.fechaini);
      let i = 0;
      while (i < porId) {
        hoy.setTime(hoy.getTime() + 24 * 60 * 60 * 1000);
        if (hoy.getDay() !== 0 && hoy.getDay() !== 6) {
          i++;
        }
      }
      let mes = hoy.getMonth() + 1;
      // Si el mes es menor a 10 pues lo concatenamos con un cero por delante
      if (mes < 10) {
        mes = 0 + mes;
        meses = 0 + '' + mes;
      }
      else {
        meses = '' + mes;
      }

      let dia = hoy.getDate();
      if (dia < 10) {
        dia = 0 + dia;
        dias = 0 + '' + dia;
      } else {
        dias = '' + dia;
      }

      var fechafin = hoy.getFullYear() + '-' + meses + '-' + dias;

      this.fechamostrar = fechafin;
      if (this.fechamostrar !== null) {
        this.fechaformato = true;
        // console.log('FORMATO; ', this.fechaformato);
      } else {
        this.fechaformato = false;
      }
    }
  }

  diaHabiles() {
    if (this.fechaini !== null) {
      var start = new Date(this.fechaini);
      var dia = document.getElementById('day')['value'];

      if (dia > 0) {
        start.setDate(start.getDate() + 2);
        // fecha contando los dias habiles
        var fechafin = start
          .toISOString()
          .slice(0, 10)
          .replace(/-/g, '-');
        this.fechamostrar = fechafin;
        console.log(
          'FECHA ',
          start
            .toISOString()
            .slice(0, 10)
            .replace(/-/g, '-')
        );
      }
    }
  }

  contadorCod() {
    this.dataApi.getAllTasksCount().subscribe( task => {
      if (task.length > 0 ) {
        task.forEach(item =>{
          if (item) {
            this.count = item.codigo_control;
          }
        });
         this.count = this.count + 1;
      } else {
        this.count = 1;
      }

    });
  }

  onSaveTask(taskForm: NgForm): void {

    if (taskForm.value.id !== null) {
       this.ID = taskForm.value.id;
       this.IdCust = taskForm.value.customersId;
    }
    if (taskForm.value.id == null) {
      Swal.fire({
        title: 'TARACOE?',
        text: `Desea guardar!`,
        imageUrl: 'https://firebasestorage.googleapis.com/v0/b/taracoe-3f6ba.appspot.com/o/FIAMImages%2Fpush.png?alt=media&token=01ee26c5-6e61-451e-9740-f3f2bd76acba',
        imageHeight: 50,
        imageWidth: 70,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#FF0000',
        confirmButtonText: 'Guardar!',
       }).then ( result => {
         if (result.value) {
          if (
            moment(taskForm.value.fechainicial).format('L') >
            moment(taskForm.value.fechafinal).format('L')
          ) {
            this.onisError();
            return;
          }

          if (this.isAdmin === false) {
            taskForm.value.userUid = this.userUid;
            taskForm.value.adminId = this.userUid;
            taskForm.value.userUidName = this.userUidName;
            taskForm.value.userUidEmail = this.userUidEmail;
          } else {
            taskForm.value.userUid = this.userId;
            taskForm.value.adminId = this.userUid;
            taskForm.value.userUidName = this.userUidName;
            taskForm.value.userUidEmail = this.userUidEmail;
          }

          if (this.fechaformato === true) {
            taskForm.value.fechafinal = this.fechamostrar;
          }

          taskForm.value.groupId = this.groupId;
          taskForm.value.group = this.group;
          taskForm.value.subgroupId = this.subgroupId;
          taskForm.value.subgroup = this.subgroup;
          taskForm.value.cod = this.cod;
          taskForm.value.service = this.service; // nombre del servicio
          this.stateId = 'O0k77hWryKKIbCPHdBn6';
          this.estado = 'En Proceso';
          this.evaluationId = '71U4A72QmvjK4bbAA9rl';
          this.evaluacion = 'Dentro del Rango';
          taskForm.value.stateId = this.stateId; // nombre del estado
          taskForm.value.estado = this.estado; // nombre del estado
          taskForm.value.evaluacion = this.evaluacion; // nombre de la evaluacion
          taskForm.value.evaluationId = this.evaluationId;
          taskForm.value.activo = this.activo;
          taskForm.value.finalizado = '';
          taskForm.value.requestId = this.requestId;
          taskForm.value.dosageId = this.dosageId;

          if (this.isSolicitudTarea === true) {
            // si viene de una solicitud
            taskForm.value.observacion = this.observacion;
            taskForm.value.customersId = this.isCustomersId; // nombre del cliente
            taskForm.value.customer = this.isCustomer; // nombre del cliente
            taskForm.value.emailCust = this.isEmailCust;
            taskForm.value.emailCC = this.isEmailCC;
            taskForm.value.nameCC = this.isNameCC;
            taskForm.value.razon = this.isRazon; // nombre de la compañia
          } else {
            taskForm.value.customer = this.customer; // nombre del cliente
            taskForm.value.emailCust = this.emailCust;
            taskForm.value.emailCC = this.emailCC;
            taskForm.value.nameCC = this.nameCC;
            taskForm.value.razon = this.razon; // nombre de la compañia
          }
          taskForm.value.validate = false;
          this.contadorCod();

          taskForm.value.codigo_control = this.count;
          taskForm.value.codigo = this.count + '';
          this.dataApi.addTask(taskForm.value);
          this.msj.mensajeCorrecto('Agregar', 'Se agrego correctamente');
          // actualiza el request de solicitudes
          this.dataApi.getOneRequest(taskForm.value.requestId). subscribe( request => {
            if (request) {
              this.updateRequest(request);
            }

          });
          // Si registra realiza el reset del formulario y lo cierra
          taskForm.resetForm(); // para limpiar el formulario
          this.btnClose.nativeElement.click(); // cierra el modal

         }
       });
    } else {
      // update

      Swal.fire({
        title: 'TARACOE',
        text: `Desea editar la tarea!`,
        imageUrl: 'https://firebasestorage.googleapis.com/v0/b/taracoe-3f6ba.appspot.com/o/FIAMImages%2Fpush.png?alt=media&token=01ee26c5-6e61-451e-9740-f3f2bd76acba',
        imageHeight: 50,
        imageWidth: 60,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#FF0000',
        confirmButtonText: 'Si, Confirmar!',
       }).then ( result => {
         if (result.value) {
          if (this.confirFuncionario) {
            taskForm.value.userUid = this.userId;
            taskForm.value.userUidName =  this.userUidName;
            taskForm.value.userUidEmail = this.userUidEmail;
          }
          if (this.confCustomer) {
            taskForm.value.customer = this.customer; // nombre del cliente
            taskForm.value.emailCust = this.emailCust;
            taskForm.value.emailCC = this.emailCC;
            taskForm.value.nameCC = this.nameCC;
            taskForm.value.razon = this.razon; // nombre de la compañia
          }
          if (this.confService) {
            taskForm.value.groupId = this.groupId;
            taskForm.value.group = this.group;
            taskForm.value.subgroupId = this.subgroupId;
            taskForm.value.subgroup = this.subgroup;
            taskForm.value.cod = this.cod;
            taskForm.value.service = this.service; // nombre del servicio
          }
          if (this.confState) {
            taskForm.value.estado = this.estado; // nombre del estado
          }
          if (this.confEvaluation) {
            taskForm.value.evaluationId = this.evaluationId;
            taskForm.value.evaluacion = this.evaluacion; // nombre de la evaluacion
          }
          taskForm.value.activo = this.activo;
          taskForm.value.finalizado = this.finalizado;
          this.dataApi.updateTask(taskForm.value);
          this.msj.mensajeAdvertencia('Editar', 'Se actualizó correctamente !!');
          // valida si el activo es false para poder repetir una tarea si lo confirma el uusuario
          if (!this.activo) {
            this.getOneOb(this.ID);
            Swal.fire({
              title: 'TARACOE?',
              text: `Desea duplicar la tarea !`,
              imageUrl: 'https://firebasestorage.googleapis.com/v0/b/taracoe-3f6ba.appspot.com/o/FIAMImages%2Fpush.png?alt=media&token=01ee26c5-6e61-451e-9740-f3f2bd76acba',
              imageHeight: 50,
              imageWidth: 60,
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#FF0000',
              confirmButtonText: 'Confirmar!',
             }).then ( result => {
               if (result.value) {
                this.getOneTaskDuplicar(this.ID);
                //let fecha = new Date();
                //let dias = 30;
                //fecha.setDate(fecha.getDate() + dias);
                //this.fechainicial = moment(fecha, 'dd.mm.yyyy').format('YYYY-MM-DD');
                this.msj.mensajeCorrecto('Duplico', 'Se duplico la tarea correctamente');

               }
             });
          }
          // Si actualiza realiza el reset del formulario y lo cierra
          taskForm.resetForm(); // para limpiar el formulario
          this.btnClose.nativeElement.click(); // cierra el modal
         }
       });
    }
  }

  // Dulica la tarea
  getOneTaskDuplicar(idTask: string) {
      const hoy = moment();
      const formato = 'YYYY-MM-DD';

      this.dataApi.getOneTask(idTask).subscribe(task => {

        this.fechainicial = task.fechainicial;
        this.fechafinal = task.fechafinal;
        this.activo = true;
        this.stateId = 'O0k77hWryKKIbCPHdBn6';
        this.estado = 'En Proceso';
        this.evaluationId = '71U4A72QmvjK4bbAA9rl';
        this.evaluacion = 'Dentro del Rango';
        // Valida si hubo cmabio en la actualizacion saca los datos del formulario
        // caso contrario inserta los datos de la busqueda
          this.task.id = null;
          this.task.fechainicial = hoy.format(formato);
          const mes = moment(this.fechafinal).add(1, 'months');
          this.task.fechafinal = mes.format(formato);

          this.task.userUid = task.userUid;
          this.task.adminId = task.adminId;
          this.task.userUidName = task.userUidName;
          this.task.userUidEmail = task.userUidEmail;

          this.task.familyId = task.familyId;
          this.task.customersId = task.customersId;
          this.task.titulo = task.titulo;
          this.task.descripcion = task.descripcion;
          this.task.observacion = task.observacion;

          this.task.customer = task.customer;
          this.task.emailCC = task.emailCC;
          this.task.nameCC = task.nameCC;
          this.task.emailCust = task.emailCust;
          this.task.service = task.service;
          this.task.groupId = task.groupId;
          this.task.group = task.group;
          this.task.subgroupId = task.subgroupId;
          this.task.subgroup = task.subgroup;
          this.task.cod = task.cod;
          this.task.razon =  task.razon;

          this.task.stateId = this.stateId;
          this.task.estado = this.estado; // nombre del estado
          this.task.evaluationId = this.evaluationId;
          this.task.evaluacion = this.evaluacion; // nombre de la evaluacion
          this.task.requestId = task.requestId;
          this.task.dosageId = task.dosageId;
          this.task.activo = this.activo;
          this.task.finalizado = '';
          this.task.validate = false;
          this.dataApi.getAllTasksCount().subscribe( task => {
            let i = 0;
            task.forEach(item =>{
              if (item) {
                i = i + 1;
              } else {
                i = 1;
              }
            });
            this.count = i + 1;
          });
          this.task.codigo_control = this.count;
          this.task.codigo = this.count + '';
          this.dataApi.addTask(this.task);
      });

  }


  // Actualiza el estado en observaciones si ha finalizado la tarea
  getOneOb(id: string) {
    this.dataApi.getAllTaskDetailDelete(id).subscribe( Obs => {
        Obs.forEach(items => {
          if (id === items.taskId) {
            this.taskOb.id = items.id;
            this.taskOb.activo = false;
            this.dataApi.updateTaskOb(this.taskOb);
          }
        });

     });
  }

  updateRequest(request: RequestInterface) {
    request.state =  true;
    this.dataApi.updateRequest(request);
  }
}
