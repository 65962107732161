import { Component, OnInit } from '@angular/core';
import { DataApiService } from '../../../services/data-api.service';
import { SubgroupInterface } from '../../../models/subgroup';
import { NgForm } from '@angular/forms';
// Para ver los roles de ususarioa
import { AuthService } from '../../../services/auth.service';
import { FamilyInterface } from '../../../models/family';
import * as printJS from 'print-js';

@Component({
  selector: 'app-list-subgroup',
  templateUrl: './list-subgroup.component.html',
  styleUrls: ['./list-subgroup.component.css']
})
export class ListSubgroupComponent implements OnInit {

  constructor(private dataApi: DataApiService, private authService: AuthService) { }
  public subgroups: SubgroupInterface[];
  public familys: FamilyInterface[];
  public isAadmin: any = null;
  public userUid: string;
  public isUpdate: boolean = false;
  public isError = false;
  public isMovimiento: boolean = false;

  pageActual: number = 1;
  ngOnInit() {
    this.getListSubgroup();
    this.getCurrentUser();
  }

  nuevo() {
    this.isMovimiento = false;
  }

  validarUpdate(id: string) {
    this.dataApi.getFamilyDelete(id).subscribe( familys => {
      if (familys.length > 0 ) {
        this.isMovimiento = true;
      } else {
        this.isMovimiento = false;
      }
    });
  }

  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
            this.isAadmin = Object.assign({}, userRole.roles).hasOwnProperty('Aadmin');
        });
      }
    });
  }

  getListSubgroup() {
    this.dataApi.getAllSubgroup().subscribe( subgroups => {
      this.subgroups = subgroups;
    });
  }

  onDeleteSubgroup(idSubgroup: string) {
    const confirmacion = confirm('Estás seguro ?');
    if (confirmacion) {
      this.dataApi.getFamilyDelete(idSubgroup).subscribe( familys => {
        if (familys.length > 0 ) {
           this.onisError();
        } else {
          this.dataApi.deleteSubgroup(idSubgroup);
        }
      });
    }
  }

   // carga el update al modal
   onPreUpdateSubroup(subgroup: SubgroupInterface) {
    this.isUpdate = true;
    this.dataApi.selectSubgroup = Object.assign({}, subgroup);
  }

  onisError(): void {
    this.isError = true;
    setTimeout(() => {
      this.isError = false;
    }, 3000);
  }

  downloadPdf() {
    printJS({printable: this.subgroups, properties: [
      {field: 'group', displayName: 'Grupo'},
      {field: 'cod', displayName: 'Código'},
      {field: 'name', displayName: 'Nombre'}
    ], type: 'json',
       documentTitle: 'Control de Trámites de ServiContable13 Srl.',
       header: '<h4 class="custom-h4">Listado de Sub Gruposs: </h4>' + '<br><br>',
       headerStyle: 'font-weight: 300;',
       font: 'Century Gothic',
       style: '.custom-h4 { color: blue; }',
       gridHeaderStyle: 'font-weight: bold; padding: 5px; border: 1px solid #dddddd;'});
  }


}
