import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { OffersComponent } from './components/offers/offers.component';
import { DetailsTaskComponent } from './components/details-task/details-task.component';
import { ListTaskComponent } from './components/admin/list-task/list-task.component';
import { LoginComponent } from './components/users/login/login.component';
import { ProfileComponent } from './components/users/profile/profile.component';
import { RegisterComponent } from './components/users/register/register.component';
import { Page404Component } from './components/page404/page404.component';
import { TaskComponent } from './components/task/task.component';
import { AuthGuard } from './guards/auth.guard';
import { ListGroupComponent } from './components/admin/list-group/list-group.component';
import { ListSubgroupComponent } from './components/admin/list-subgroup/list-subgroup.component';
import { ListFamilyComponent } from './components/admin/list-family/list-family.component';
import { ListCustomersComponent } from './components/admin/list-customers/list-customers.component';
import { ListStateComponent } from './components/admin/list-state/list-state.component';
import { ListEvaluationComponent } from './components/admin/list-evaluation/list-evaluation.component';
import { ObtervationTaskComponent } from './components/obtervation-task/obtervation-task.component';
import { ListDosageComponent } from './components/admin/list-dosage/list-dosage.component';
import { ListActivityComponent } from './components/admin/list-activity/list-activity.component';
import { ListRequestComponent } from './components/admin/list-request/list-request.component';
import { ListTaskGroupComponent } from './components/admin/list-task-group/list-task-group.component';
import { RptTaskCustomersComponent } from './components/reports/rpt-task-customers/rpt-task-customers.component';
import { RptTaskProceduresComponent } from './components/reports/rpt-task-procedures/rpt-task-procedures.component';
import { LineComponent } from './components/graphic/line/line.component';
import { ListDataUserComponent } from './components/admin/list-data-user/list-data-user.component';
import { ListSlopesComponent } from './components/admin/list-slopes/list-slopes.component';
import { ListSupervisorComponent } from './components/admin/list-supervisor/list-supervisor.component';
import { RptTaskListFuncComponent } from './components/reports/rpt-task-list-func/rpt-task-list-func.component';
import { RptEfectividadComponent } from './components/reports/rpt-efectividad/rpt-efectividad.component';
import { ListDataUserdetComponent } from './components/admin/list-data-userdet/list-data-userdet.component';
import { PageOutsourcingComponent } from './components/pages/page-outsourcing/page-outsourcing.component';
import { PageServicioComponent } from './components/pages/page-servicio/page-servicio.component';
import { PagePayrollrhhComponent } from './components/pages/page-payrollrhh/page-payrollrhh.component';
import { PageReingenieriaComponent } from './components/pages/page-reingenieria/page-reingenieria.component';
import { ListBlogComponent } from './components/admin/list-blog/list-blog.component';
import { AsignRolesComponent } from './components/asign-roles/asign-roles.component';
import { LayoutCargandoComponent } from './components/layout-cargando/layout-cargando.component';
import { SendEmailComponent } from './components/users/send-email/send-email.component';
import { MnucatalogoComponent } from './components/mnucatalogo/mnucatalogo.component';
import { MnureporteComponent } from './components/mnureporte/mnureporte.component';
import { PrivacidadComponent } from './components/privacidad/privacidad.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'cargando', component: LayoutCargandoComponent },
  { path: 'pages/page-outsourcing', component: PageOutsourcingComponent },
  { path: 'pages/page-servicio', component: PageServicioComponent },
  { path: 'pages/page-rrhh', component: PagePayrollrhhComponent},
  { path: 'pages/page-reingenieria', component: PageReingenieriaComponent},
  { path: 'task', component: TaskComponent, canActivate: [AuthGuard]}, // TODO: only user auth.
  { path: 'offers', component: OffersComponent, canActivate: [AuthGuard]}, // TODO: only user auth.
  { path: 'task/:id', component: DetailsTaskComponent, canActivate: [AuthGuard]},
  { path: 'admin/list-task', component: ListTaskComponent, canActivate: [AuthGuard]}, // TODO: only user auth.
  { path: 'obtervation-task', component: ObtervationTaskComponent, canActivate: [AuthGuard]}, // TODO: only user auth.
  { path: 'admin/list-group', component: ListGroupComponent, canActivate: [AuthGuard]},
  { path: 'admin/list-subgroup', component: ListSubgroupComponent, canActivate: [AuthGuard]},
  { path: 'admin/list-subgroup', component: ListSubgroupComponent, canActivate: [AuthGuard]},
  { path: 'admin/list-family', component: ListFamilyComponent, canActivate: [AuthGuard]},
  { path: 'admin/list-state', component: ListStateComponent, canActivate: [AuthGuard]},
  { path: 'admin/list-evaluation', component: ListEvaluationComponent, canActivate: [AuthGuard]},
  { path: 'admin/list-customers', component: ListCustomersComponent, canActivate: [AuthGuard]},
  { path: 'admin/list-dosage', component: ListDosageComponent, canActivate: [AuthGuard]},
  { path: 'admin/list-activity', component: ListActivityComponent, canActivate: [AuthGuard]},
  { path: 'admin/list-request', component: ListRequestComponent, canActivate: [AuthGuard]},
  { path: 'admin/list-taskGroup', component: ListTaskGroupComponent, canActivate:[AuthGuard]},
  { path: 'admin/list-dataUser', component: ListDataUserComponent, canActivate:[AuthGuard]},
  { path: 'admin/list-dataUserDet', component: ListDataUserdetComponent, canActivate:[AuthGuard]},
  { path: 'admin/list-slopes/:id', component: ListSlopesComponent, canActivate: [AuthGuard]},
  { path: 'admin/list-supervisor', component: ListSupervisorComponent, canActivate: [AuthGuard]},
  { path: 'admin/list-blog', component: ListBlogComponent, canActivate: [AuthGuard]},
  { path: 'reports/rpt-taskCustomers', component: RptTaskCustomersComponent, canActivate: [AuthGuard]},
  { path: 'reports/rpt-taskProcedures', component: RptTaskProceduresComponent, canActivate: [AuthGuard]},
  { path: 'reports/rpt-taskFuncionario', component: RptTaskListFuncComponent, canActivate: [AuthGuard]},
  { path: 'reports/rpt-Efectividad', component: RptEfectividadComponent, canActivate: [AuthGuard]},
  { path: 'graphic/lineGraphic', component: LineComponent, canActivate:[AuthGuard]},
  { path: 'asignRoles', component: AsignRolesComponent, canActivate:[AuthGuard]},
  { path: 'user/login', component: LoginComponent},
  { path: 'user/profile', component: ProfileComponent, canActivate: [AuthGuard]}, // TODO: only user auth.
  { path: 'user/register', component: RegisterComponent},
  { path: 'verificacionEmail', component: SendEmailComponent},
  { path: 'privacidad', component: PrivacidadComponent},
  { path: 'mnuCatalogo', component: MnucatalogoComponent, canActivate: [AuthGuard]},
  { path: 'mnuReporte', component: MnureporteComponent, canActivate: [AuthGuard]},
  { path: 'servicedet/:id', loadChildren: () => import('./components/servicedet/list-servicedet/list-servicedet.module').then(m => m.ListServicedetModule), canActivate: [AuthGuard] },
  { path: 'subservice/:id', loadChildren: () => import('./components/subservice/list-subservice/list-subservice.module').then(m => m.ListSubserviceModule), canActivate: [AuthGuard]  },
  { path: '**', component: Page404Component},


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
