
export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyB6sNczdUo_PtVAWQfgCWRcjoEQIHtA_sQ",
    authDomain: "taracoe-3f6ba.firebaseapp.com",
    databaseURL: "https://taracoe-3f6ba.firebaseio.com",
    projectId: "taracoe-3f6ba",
    storageBucket: "taracoe-3f6ba.appspot.com",
    messagingSenderId: "176040255581",
    appId: "1:176040255581:web:f2472810c1775b74ba6db4"
  }
};

