import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { DataApiService } from '../../../services/data-api.service';
import { groupInterface } from '../../../models/group';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { MensajesService } from 'src/app/services/mensajes.service';
import { TaskInterface } from 'src/app/models/task';
import { DosageInterface } from 'src/app/models/dosage';
import { FamilyInterface } from 'src/app/models/family';

@Component({
  selector: 'app-modal-group',
  templateUrl: './modal-group.component.html',
  styleUrls: ['./modal-group.component.css']
})
export class ModalGroupComponent implements OnInit {

  constructor(public dataApi: DataApiService, private msj: MensajesService) { }
  @ViewChild('btnClose', { static: false}) btnClose: ElementRef;
  @Input() userUid: string; // para pasar al modal el userid logueado
  @Input() isMovimiento: boolean;
  @Input() isUpdate: boolean;

  public task: TaskInterface = {};
  public dosage: DosageInterface = {};
  public family: FamilyInterface = {};

  ngOnInit() {
   console.log('ESTADo: ', this.isUpdate);
  }

  resetForm(groupForm: NgForm) {
    groupForm.resetForm(); // para limpiar el formulario
  }

  onSaveGroup(groupForm: NgForm): void {
    // new
    if (groupForm.value.id == null) {
      groupForm.value.userUid = this.userUid; // guarda en la coleecion de firebase
      this.dataApi.addGroup(groupForm.value);
      this.msj.mensajeCorrecto('Agregar', 'Se agrego correctamente');
    } else {
       this.dataApi.updateGroup(groupForm.value);
       this.getFamily(groupForm.value.id, groupForm.value.name);
       this.getTask(groupForm.value.id, groupForm.value.name);
       this.getDosage(groupForm.value.id, groupForm.value.name);
      this.msj.mensajeAdvertencia('Editar', 'Se edito correctamente');
    }
    groupForm.resetForm();
    this.btnClose.nativeElement.click(); // cierra el modal
  }

  getFamily(id: string, name: string) {
    this.dataApi.getSelectGroupId(id).subscribe( family => {
      if (family.length > 0) {
        family.forEach(items => {
          if (id === items.groupId) {
            this.family.id = items.id;
            this.family.group =  name
            this.dataApi.updateSubgroup(this.family);
          }
        });
      }
     });
  }

  getTask(id: string, name: string) {
    this.dataApi.getAllTaskGroup(id).subscribe( family => {
      if (family.length > 0) {
        family.forEach(items => {
          if (id === items.groupId) {
            this.task.id = items.id;
            this.task.group =  name
            this.dataApi.updateTask(this.task);
          }
        });
      }
     });
  }

  getDosage(id: string, name: string) {
    this.dataApi.getAllDosageGroup(id).subscribe( task => {
      if (task.length > 0) {
        task.forEach(items => {
          if (id === items.groupId) {
            this.dosage.id = items.id;
            this.dosage.group = name;
            this.dataApi.updateDosage(this.dosage);
          }
        });
      }
     });
  }


}
