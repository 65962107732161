import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { UserInterface } from '../../../models/user';
import { NgForm } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MensajesService } from 'src/app/services/mensajes.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  constructor(private authService: AuthService, private storage: AngularFireStorage, private msj: MensajesService) { }
  @ViewChild('imageUser', { static: false }) inputImageUser: ElementRef;

  urlImage: Observable<string>;

  user: UserInterface = {
    name: '',
    email: '',
    photoUrl: '',
    roles: {}
  };

  public providerId: string = 'null'; // probar si esta logueado con redes sociales
  ngOnInit() {
    // this.authService.userData$.subscribe(user => {
    //  this.initValuesForm(user);
    // });
    this.mostraUser();
  }

  mostraUser() {
    this.authService.isAuth().subscribe(user => {
      if (user) {
        this.user.name = user.displayName;
        this.user.email = user.email;
        this.user.photoUrl = user.photoURL;
        this.providerId = user.providerData[0].providerId;
      }
    });
  }

  onUpload(e) {
    // console.log('subir', e.target.files[0]);
    const id = Math.random().toString(36).substring(2);
    const file = e.target.files[0]; // el archivo que queremos
    const filePath = `uploads/profileUp_${id}`;
    const ref = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    // recuperar el id del fichero;
    task.snapshotChanges().pipe(finalize(() => {ref.getDownloadURL().subscribe(urlImagen => {
      this.authService.isAuth().subscribe(user => {
        if (user) {
          user.updateProfile({
            displayName: '',
            photoURL: urlImagen
          }).then(() => {
            this.msj.mensajeAdvertencia('Editar', 'Se actualizó la imagen correctamente');
            this.mostraUser();
          }).catch((error) => {
            console.log('err', error.message);
            this.msj.mensajeError('ERROR', error.message);
          });
        }
      });

    });
    })).subscribe();
  }


}
