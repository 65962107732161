import { Component, OnInit } from '@angular/core';
import { DataApiService } from '../../../services/data-api.service';
import { AuthService } from '../../../services/auth.service';
import { TaskInterface } from '../../../models/task';
import * as printJS from 'print-js';
import { element } from 'protractor';
import { UserInterface } from '../../../models/user';
import { DataUserInterface } from '../../../models/dataUser';
import * as moment from 'moment';
moment.locale('es')
import 'moment/locale/bo';


@Component({
  selector: 'app-rpt-efectividad',
  templateUrl: './rpt-efectividad.component.html',
  styleUrls: ['./rpt-efectividad.component.css']
})
export class RptEfectividadComponent implements OnInit {

  constructor(public dataApi: DataApiService, private authService: AuthService) { }
  public tasks: TaskInterface[];
  public tasksCus: TaskInterface[];
  public users: UserInterface[];
  public dataUsers: DataUserInterface[];
  public fechainicial: string;
  public fechafinal: string;
  public isAdmin: any = null;
  public userUid: string;
  public usersId: string;
  public user: string;
  public name: string;
  public email: string;

  public nameUser: string;

  contTotal: number = 0;
  contAtiempo: number = 0;
  total: number = 0;

  public dat = [];
  public isActive: boolean = false;

  public isError = false;

  ngOnInit() {
    this.getCurrentUser();
  }

  onisError(): void {
    this.isError = true;
    setTimeout(() => {
      this.isError = false;
    }, 3000);
  }

  dateStart(fechaIni: string) {
    if (fechaIni != null) {
      this.fechainicial = fechaIni;
    }
  }

  dateEnd(fechaFin: string) {
    if (fechaFin != null) {
      this.fechafinal = fechaFin;
    }
  }

  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
            this.isAdmin = Object.assign({}, userRole.roles).hasOwnProperty('admin');
        });
      }
    });
  }

  getOneUser() {
    this.dat = [];
    if (this.fechainicial !== null && this.fechafinal !== null) {
       if (moment(this.fechainicial).format('L') > moment(this.fechafinal).format('L')) {
          this.onisError();
          return;
       }
       this.dataApi.getAllDataUser().subscribe( dataUsers => {
        if (dataUsers.length > 0 ) {
          dataUsers.forEach(item => {
              this.getAll(item.userUid);
          });
        }
      });


    }
 }

 getAll(id: string) {
  this.contTotal = 0;
  this.contAtiempo = 0;
  this.total = 0;
  this.dataApi.getAllTasksSatifactorio(this.fechainicial, this.fechafinal, id).subscribe(tasks => {
    if (tasks.length > 0) {

      this.isActive = true;
      let i = 0;
      let x = 0;
      tasks.forEach(conta => {
         this.name = conta.userUidName;
         if (conta) {
           i = i + 1;
         }
         if (conta.evaluationId === 'vaKGfwXYAQbkyRlGc3aV' || conta.evaluationId === '71U4A72QmvjK4bbAA9rl' ) {
           x = x + 1;
         }
      });
      this.contTotal = i;
      this.contAtiempo = x;
      this.total = (this.contAtiempo / this.contTotal) * 100;
      this.dat.push({name: this.name,
                      tramite: this.contTotal,
                      rango: this.contAtiempo,
                      porcentaje: this.total}
                    );
      this.tasks = tasks;
      console.log('DATA: ', this.dat);
    } else {
      this.isActive = false;
      this.tasks = tasks;
    }
    return this.dat;
  });

 }

 downloadPdf() {
  printJS({printable: this.dat, properties: [
    {field: 'name', displayName: 'Funcionario'},
    {field: 'tramite', displayName: 'Nro. Trámites'},
    {field: 'rango', displayName: 'Fuero de Rango'},
    {field: 'porcentaje', displayName: 'Sastifactorio %'}
  ], type: 'json',
     documentTitle: 'Control de Trámites de ServiContable13 Srl.',
     header: '<h4 class="custom-h4">Trámite del Encargado: </h4>'
      + '<b>Fecha Desde:</b> ' + this.fechainicial + '<b>Hasta: </b>' + this.fechafinal
      + '<br><br>',
     headerStyle: 'font-weight: 300;',
     font: 'Century Gothic',
     style: '.custom-h4 { color: blue; }',
     gridHeaderStyle: 'font-weight: bold; padding: 5px; border: 1px solid #dddddd;'});
}


}
