import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-layout-cargando',
  templateUrl: './layout-cargando.component.html',
  styleUrls: ['./layout-cargando.component.css']
})
export class LayoutCargandoComponent implements OnInit {
  public isError: boolean = false;
  constructor(private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.onisError();
  }

  onisError(): void {
    // this.spinner.show();
    this.isError = true;
    setTimeout(() => {
      // this.spinner.hide();
      this.isError = false;
    }, 2000);
  }

}
