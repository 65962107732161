import { Component } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Observable } from 'rxjs';
import { MensajesService } from 'src/app/services/mensajes.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.css'],
  providers: [AuthService]
})
export class SendEmailComponent {
  public user$: Observable<any> = this.authSvc.afsAuth.user;

  constructor(private authSvc: AuthService, private msj: MensajesService, private afsAuth: AngularFireAuth, private router: Router) { }

  onSendEmail(): void {
   // Service reenciar email
   this.authSvc.sendVerificationEmail();
   this.msj.mensajeCorrecto('Reenvió', 'Se reeenvio correctamente tu verificación de correo electrónico');
   this.afsAuth.auth.signOut();
   this.onLoginRedirect();
  }

  onLoginRedirect(): void {
    this.router.navigate(['login']);
  }

}
