import { Component, OnInit } from '@angular/core';
import { DataApiService } from '../../../services/data-api.service';
import { AuthService } from '../../../services/auth.service';
import { TaskInterface } from '../../../models/task';
import * as printJS from 'print-js';
import { UserInterface } from '../../../models/user';
import { CustomersInterface } from '../../../models/customers';
import { DataUserInterface } from '../../../models/dataUser';
import * as moment from 'moment';
moment.locale('es')
import 'moment/locale/bo';


@Component({
  selector: 'app-rpt-task-procedures',
  templateUrl: './rpt-task-procedures.component.html',
  styleUrls: ['./rpt-task-procedures.component.css']
})
export class RptTaskProceduresComponent implements OnInit {

  constructor(public dataApi: DataApiService, private authService: AuthService) { }
  public tasks: TaskInterface[];
  public tasksCus: TaskInterface[];
  public users: UserInterface[];
  public customers: CustomersInterface[];
  public dataUsers: DataUserInterface[];
  public use: [];
  public dataUser: [];
  public cust: [];
  public isAdmin: any = null;
  public userUid: string;
  public usersId: string;
  public user: string;
  public name: string;
  public email: string;
  public customersId: string;
  public customer: string;
  public fechainicial: string;
  public fechafinal: string;

  public idCustomer: string;

  contTotal: number = 0;
  contAtiempo: number = 0;
  total: number = 0;

  public isActive: boolean = false;
  public isRadioA: boolean = false;
  public isRadioB: boolean = false;
  public isRadioC: boolean = false;
  pageActual: number = 1;
  pageActualCust: number = 1;

  public nivel: string = '2'; // nivel del encargado
  public nivelAdmin: string = '1'; // nivel administrador

  public isError = false;

  ngOnInit() {
    this.getRadioA();
    this.getListUsers();
    this.getCurrentUser();
    this.getListCustomer();
  }

  onisError(): void {
    this.isError = true;
    setTimeout(() => {
      this.isError = false;
    }, 3000);
  }

  getListUsers() {
    this.dataApi.getAllDataUser().subscribe( dataUsers => {
      if (dataUsers.length > 0 ) {
        this.dataUsers = dataUsers;
      }
    });
  }

  getListCustomer() {
    this.dataApi.getSelectCustomer().subscribe( customers => {
      this.customers = customers;
    });
  }

  dateStart(fechaIni: string) {
    if (fechaIni != null) {
      this.fechainicial = fechaIni;
    }
  }

  dateEnd(fechaFin: string) {
    if (fechaFin != null) {
      this.fechafinal = fechaFin;
    }

  }

  // buscar el nombre de funcionario y vcarga el select de cliente si tiene tarea
  getOneDataUser(iduserUid: string) {
    this.usersId = iduserUid;
    if (this.usersId != null) {
      this.dataApi.getAllDataUserUid(this.usersId).subscribe(dataUsers => {
        dataUsers.forEach(data => {
          this.name = data.name;
        });
      });
      this.taskcust(this.usersId);
    }
  }

  taskcust(id: string) {
    if (id !== null) {
      this.dataApi.getAllTasksUserDisct(id).subscribe(taskcust => {
        taskcust.forEach(dat => {
            this.idCustomer = dat.customersId;
        });
        this.tasksCus = taskcust;
      });
    }
  }

  // carga la consulta
  getOneCustomer(idCustomers: string) {
    if (idCustomers != null) {
      this.customersId = idCustomers;
      if (this.customersId != null) {
        this.dataApi.getOneCustomer(idCustomers).subscribe( cust => {
          this.customer = cust.company;
          if (this.usersId != null) {
            if (this.fechainicial != null && this.fechafinal != null) {
              if (moment(this.fechainicial).format('L') > moment(this.fechafinal).format('L')) {
                this.onisError();
                return;
              }
              this.getOneUser(this.usersId, this.customersId, this.fechainicial, this.fechafinal);
            }
          }
      });
      }
    }
  }


  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
            this.isAdmin = Object.assign({}, userRole.roles).hasOwnProperty('admin');
        });
      }
    });
  }

  getRadioA() {
    this.isRadioA =  true;
    this.isRadioB =  false;
    this.isRadioC =  false;
  }

  getRadioB() {
      this.isRadioB =  true;
      this.isRadioA =  false;
      this.isRadioC =  false;
  }

  getRadioC() {
      this.isRadioC =  true;
      this.isRadioB =  false;
      this.isRadioA =  false;
  }

  getOneUser(idUsers: string, idCustomer: string, fechaini: string, fechafin: string) {
    this.contTotal = 0;
    this.contAtiempo = 0;
    this.total = 0;
    if (idUsers != null) {
     if (this.isRadioA) {
       this.dataApi.getAllTasksUserDat(idUsers, idCustomer, fechaini, fechafin).subscribe(tasks => {
         if (tasks.length > 0) {
           this.isActive = true;
           this.tasks = tasks;
           let i = 0;
           let x = 0;
           tasks.forEach(conta => {
             if (conta) {
               i = i + 1;
             }
             if (conta.evaluationId === 'vaKGfwXYAQbkyRlGc3aV' || conta.evaluationId === '71U4A72QmvjK4bbAA9rl' ) {
               x = x + 1;
             }
           });
           this.contTotal = i;
           this.contAtiempo = x;
           this.total = (this.contAtiempo / this.contTotal) * 100;
           this.oneUser(this.usersId);
         } else {
           this.isActive = false;
           this.tasks = tasks;
         }
       });
     }
     if (this.isRadioB) {
       this.dataApi.getAllTasksUserB(idUsers, idCustomer, fechaini, fechafin, this.isRadioB).subscribe(tasks => {
         if (tasks.length > 0) {
           this.isActive = true;
           this.tasks = tasks;
           this.oneUser(this.usersId);
         } else {
           this.isActive = false;
           this.tasks = tasks;
         }
       });
     }
     if (this.isRadioC) {
       this.isRadioC = false;
       this.dataApi.getAllTasksUserB(idUsers, idCustomer, fechaini, fechafin, this.isRadioC).subscribe(tasks => {
         if (tasks.length > 0) {
           this.isActive = true;
           this.tasks = tasks;
           this.oneUser(this.usersId);
         } else {
           this.isActive = false;
           this.tasks = tasks;
         }
       });
     }
    }
 }

 oneUser(id: string) {
  this.authService.getOneUser(id).subscribe(user => {
      this.email = user.email;
  });
}

 downloadPdf() {
  printJS({printable: this.tasks, properties: [
    {field: 'subgroup', displayName: 'Servicio'},
    {field: 'service', displayName: 'Código Descripción'},
    {field: 'fechainicial', displayName: 'Inicio'},
    {field: 'fechafinal', displayName: 'Final'},
    {field: 'finalizado', displayName: 'Finalizado'},
    {field: 'estado', displayName: 'Estado'},
    {field: 'evaluacion', displayName: 'Evaluación'},
    {field: 'observacion', displayName: 'Observación'}
  ], type: 'json',
     documentTitle: 'Control de Trámites de ServiContable13 Srl.',
     header: '<h4 class="custom-h4">Trámite del Encargado: </h4>'
      + '<b>Login:</b> ' + ' ' + this.email + '<br>' + '<b>Razón:</b> '
      + this.customer + '<br>' + '<b>Encargado:</b> ' + this.name + '<br>'
      + '<b>Fecha Desde:</b> ' + this.fechainicial + '<b>Hasta: </b>' + this.fechafinal
      + '<br><hr><br>' + '<b>Número de Tramites: </b> '
      + this.contTotal + '<br>' + '<b>Sastifactoria: </b>' + this.contAtiempo + '<br>'
      + '<b>Efectividad: </b>' + this.total + '<b> %</b><br><br>',
     headerStyle: 'font-weight: 300;',
     font: 'Century Gothic',
     style: '.custom-h4 { color: blue; }',
     gridHeaderStyle: 'font-weight: bold; padding: 5px; border: 1px solid #dddddd;'});
}

}
