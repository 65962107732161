import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { NgForm } from '@angular/forms';
import { FlashMessagesService } from 'angular2-flash-messages';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(public afAuth: AngularFireAuth,
              private router: Router,
              private authService: AuthService) { }

  public email: string = '';
  public password: string = '';

  public isError = false;
  public isPass = false;

  public flashmessages: FlashMessagesService;
  ngOnInit() {
  }

  onLogin(formLogin: NgForm): void {
     if (formLogin.valid) {
      this.authService.loginEmailUser(this.email, this.password)
      .then((resp) => {
        this.authService.isAuth().subscribe(user => {
          if (user && user.emailVerified) {
            this.isError = false;
            this.onLoginRedirect();
          } else if (user) {
            this.onLoginRedirectVerification();
          } else {
            this.onLoginRedirectRegister();
          }
        });
      }).catch(err => {
        console.log('err', err.message);
        this.onisError();
      });
     } else {
       console.log('Form no valid');
     }

  }

  onRestaurarPass(formLogin: NgForm): void {
    if (formLogin.valid) {
      this.authService.restaurarPass(this.email);
      alert('Verifique su correo para restaurar su contraseña !!!')
    } else {
      this.isPass = false;
    }
  }

  onLoginGoogle(): void {
    this.authService.loginGoogleUser()
      .then((res) => {
        this.isError = false;
        this.onLoginRedirect();
    }).catch(err => {
      console.log('err', err.message);
      this.onisError();
    });
  }

  onLoginFacebook(): void {
    this.authService.loginFacebookUser()
      .then((res) => {
        this.isError = false;
        this.onLoginRedirect();
      }).catch(err => {
        console.log('err', err.message);
        this.onisError();
      });
  }

  onLogout() {
    this.authService.logoutUser();
  }

  onLoginRedirectRegister(): void {
    this.router.navigate(['register']);
  }

  onLoginRedirect(): void {
    this.router.navigate(['task']);
  }

  onLoginRedirectVerification(): void {
    this.router.navigate(['verificacionEmail']);
  }


  onisError(): void {
    this.isError = true;
    setTimeout(() => {
      this.isError = false;
    }, 4000);
  }

  onisRestaurar(): void {
    this.isPass = true;
    setTimeout(() => {
      this.isPass = false;
    }, 4000);
  }
}
