import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { DataApiService } from '../../../services/data-api.service';
import { FamilyInterface } from '../../../models/family';
import { NgForm } from '@angular/forms';
import { groupInterface } from '../../../models/group';
import { SubgroupInterface } from '../../../models/subgroup';
import { relativeTimeThreshold } from 'moment';
import Swal from 'sweetalert2';
import { MensajesService } from '../../../services/mensajes.service';
import { TaskInterface } from 'src/app/models/task';
import { TaskDetailsInterface } from 'src/app/models/task_details';
import { DosageInterface } from 'src/app/models/dosage';
import { SubFamilyInterface } from 'src/app/models/subfamily';
import { SubfamilyService } from 'src/app/services/subfamily.service';


@Component({
  selector: 'app-modal-family',
  templateUrl: './modal-family.component.html',
  styleUrls: ['./modal-family.component.css']
})
export class ModalFamilyComponent implements OnInit {

  constructor(
    public dataApi: DataApiService,
    private msj: MensajesService,
    private serviceSub: SubfamilyService)
     { }

  public familys: FamilyInterface[];
  public groups: groupInterface[];
  public subgroups: SubgroupInterface[];

  @ViewChild('btnClose', { static: false}) btnClose: ElementRef;
  @Input() userUid: string; // para pasar al modal el userid logueado
  @Input() isUpdate: boolean;
  @Input() isMovimiento: boolean;
  @Input() issubgroupId: string;

  public validar: boolean = true;
  public idSubGroup: string = null;
  public group: string = '';
  public subgroupId: string = '';
  public subgroup: string = '';
  public confirGroup: boolean = false;
  public confirSubGroup: boolean = false;
  public confirCod: boolean = false;
  public idGroup: string = null;
  contador: number;
  cod: string = '';
  codA: string;
  codB: string;
  public idSub: string;

  public nameFamuly: string;
  public codigoFamily: string;
  public ID: string;
  public task: TaskInterface = {};
  public taskOb: TaskDetailsInterface = {};
  public dosage: DosageInterface = {};
  public subfamily: SubFamilyInterface = {};

  ngOnInit() {
    this.cod = null;
    this.validar = true;
    this.getListGroup();
    this.getListSubGroup();
    this.isMovimiento = false;

  }

  // carga el listado del grupo del combox
  getListGroup() {
    this.dataApi.getSelectGroup().subscribe( groups => {
      if (groups) {
        this.groups = groups;
      }
    });
  }

  getListSubGroup() {
    this.dataApi.getAllSubgroup().subscribe( subgroups => {
      if (subgroups) {
        this.subgroups = subgroups;
      }
    });
  }
  // busca por el id todo los subgrupos que pertenecen al grupo selecionado
  // (focus)="onSelectSubgroup($event.target.value)"
  onSelectSubgroup(idGroup: string): void {
    this.cod = null;
    this.idGroup = idGroup;
    console.log('GROUP ', this.idGroup);
    if (this.idGroup !== undefined || this.idGroup !== null || this.idGroup !== '') {
      // this.subgroups =  this.dataApi.getAllSubgroup().filter(item => item.groupId = this.idGroup);
      this.dataApi.getSelectSubGroupOne(this.idGroup).subscribe( subgroups => {
        if (subgroups.length > 0) {
          this.confirGroup = true;
          this.subgroups = subgroups;
        } else {
          this.confirGroup = false;
        }
      });
    }
    if (this.idGroup !== undefined) {
      this.onSelectGroupBuscar(this.idGroup);
    }
  }

  // Busca el nombre del grupo
  onSelectGroupBuscar(idGroup: string): void {
    this.dataApi.getOneGroup(idGroup).subscribe( group => {
      if (group) {
        this.confirGroup = true;
        this.codA = group.cod;
        this.group = group.name;
      }
    });
  }

  onSelectSubGroupBuscar(idSubGroup: string) {
      this.idSubGroup = idSubGroup;
        console.log('IDEDIT: ', this.idSubGroup);
        if (this.idSubGroup !== undefined) {
          this.dataApi.getOneSubGroup(idSubGroup).subscribe( subgroup => {
            if (subgroup) {
              this.confirSubGroup = true;
              this.codB = subgroup.cod;
              this.subgroup = subgroup.name;
              console.log('CODB: ', this.codB);
            } else {
              this.confirSubGroup = false;
            }
          });
          if (this.idSubGroup !== null) {
            this.onSelectFamily(this.idSubGroup);
          }
        }

  }

  onSelectFamily(idSubGroup: string) {
      this.idSubGroup = idSubGroup;

      if (this.idSubGroup !== null) {
        this.dataApi.getSelectCboFamily(this.idSubGroup).subscribe( familys => {
          this.familys = familys;
          let i = 0;
          familys.forEach(element => {
            if (element) {
              i = i + 1;
            } else {
              i = 1;
            }
          });
          this.contador = i + 1;
          if (this.contador < 10) {
            this.cod = this.codB + '-' + '0' + this.contador;
          } else {
            this.cod = this.codB + '-' + this.contador;
          }
          console.log('COD: ', this.cod);
        });
      }
  }

  onBuscarCodigo(id: string) {
    this.dataApi.getSelectCodigo(id, this.idGroup, this.idSubGroup).subscribe( familys => {
      if (familys.length > 0) {
        familys.forEach(item => {
          this.cod =  item.cod;
        });
        this.confirCod = true;
      } else {
        this.confirCod = false;
      }
    });
  }

  resetForm(formFamily: NgForm) {
    formFamily.resetForm(); // para limpiar el formulario
  }

  onSaveFamily(familyForm: NgForm): void {
    // new
    this.ID = familyForm.value.id;
    if (familyForm.value.id == null) {
      if (!familyForm.value.groupId) {
        this.validar = false;
        this.msj.mensajeAdvertencia('Verificar', 'Seleccionar el grupo');
        return;

      }
      if (!familyForm.value.subgroupId) {
        this.validar = false;
        this.msj.mensajeAdvertencia('Verificar', 'Seleccionar el sub grupo');
        return;
      }
      if (!familyForm.value.name) {
        this.validar = false;
        this.msj.mensajeAdvertencia('Verificar', 'Registre el nombre del servicio');
        return;
      }
      familyForm.value.userUid = this.userUid; // guarda en la coleecion de firebase
      familyForm.value.cod = this.cod;
      familyForm.value.subgroup = this.subgroup;
      familyForm.value.group = this.group;
      if (this.validar) {
        this.dataApi.addFamily(familyForm.value);
        this.msj.mensajeCorrecto('Agrego', 'Se agrego correctamente');
      } else {
        this.msj.mensajeError('ERROR', 'No se pudo guardar el registro!!');
      }

    } else {
    // update
      //this.onBuscarCodigo(familyForm.value.id);

      //console.log('DATA: ', familyForm.value);
      this.dataApi.updateFamily(familyForm.value);
      this.getOneTask(this.ID);
      this.getOneTaskObs(this.ID);
      this.getOneTaskDet(this.ID);
      this.getOneSubFamily(this.ID);
      this.msj.mensajeAdvertencia('Edito', 'Se edito correctamente');
    }
    familyForm.resetForm();
    this.btnClose.nativeElement.click(); // cierra el modal
  }

  getOneTask(id: string) {
    this.dataApi.getOneFamily(id).subscribe(family => {
         this.nameFamuly = family.name;
         this.codigoFamily = family.cod;
         this.dataApi.getAllTasksFamilyId(id).subscribe( task => {
          if (task.length > 0) {
            task.forEach(items => {
              if (id === items.familyId) {
                this.task.id = items.id;
                this.task.cod =  this.codigoFamily;
                this.task.service = this.nameFamuly;
                this.dataApi.updateTask(this.task);
              }
            });
          }
         });
    });
  }

  getOneTaskObs(id: string) {
    this.dataApi.getOneFamily(id).subscribe(family => {
         this.nameFamuly = family.name;
         this.codigoFamily = family.cod;
         this.dataApi.getAllTaskDetServiceId(id).subscribe( task => {
          if (task.length > 0) {
            task.forEach(items => {
              if (id === items.serviceId) {
                this.taskOb.id = items.id;
                this.taskOb.cod =  this.codigoFamily;
                this.taskOb.service = this.nameFamuly;
                this.dataApi.updateTaskOb(this.taskOb);
              }
            });
          }
         });
    });
  }

  getOneTaskDet(id: string) {
    this.dataApi.getOneFamily(id).subscribe(family => {
         this.nameFamuly = family.name;
         this.codigoFamily = family.cod;
         this.dataApi.getAllDosageFamily(id).subscribe( task => {
          if (task.length > 0) {
            task.forEach(items => {
              if (id === items.familyId) {
                this.dosage.id = items.id;
                this.dosage.cod =  this.codigoFamily;
                this.dosage.service = this.nameFamuly;
                this.dataApi.updateDosage(this.dosage);
              }
            });
          }
         });
    });
  }
  // Estado de Actividad
  getOneSubFamily(id: string) {
    this.dataApi.getOneFamily(id).subscribe(family => {
      this.nameFamuly = family.name;
      this.codigoFamily = family.cod;
      this.serviceSub.getAllServiceFamily(id).subscribe( subfamily => {
        if(subfamily.length > 0) {
          subfamily.forEach(items => {
            if (id === items.familyId) {
              this.subfamily.id = items.id;
              this.subfamily.familyDes = this.nameFamuly;
              this.serviceSub.editSubServiceDetByid(this.subfamily);
            }
          });

        }
      });

    });
  }

}
