import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterRol'
})
export class FilterRolPipe implements PipeTransform {

  transform(value: any, arg: any): any {
    if (arg === '' || arg.length < 3) return value;
    const resultPosts = [];
    for (const post of value) {
      if (post.cod.toLowerCase().indexOf(arg.toLowerCase()) > -1 || post.name.toLowerCase().indexOf(arg.toLowerCase()) > -1
      || post.group.toLowerCase().indexOf(arg.toLowerCase()) > -1 || post.subgroup.toLowerCase().indexOf(arg.toLowerCase()) > -1 ) {
        resultPosts.push(post);
      }
    }
    return resultPosts;
  }

}
