import { Component, OnInit } from '@angular/core';
import { DataApiService } from '../../../services/data-api.service';
import { StateInterface } from '../../../models/state';
import { NgForm } from '@angular/forms';
// Para ver los roles de ususarioa
import { AuthService } from '../../../services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserInterface } from '../../../models/user';
import {jsPDF} from 'jspdf';


@Component({
  selector: 'app-list-state',
  templateUrl: './list-state.component.html',
  styleUrls: ['./list-state.component.css']
})
export class ListStateComponent implements OnInit {

  constructor(private dataApi: DataApiService, private authService: AuthService) { }
  public states: StateInterface[];
  public isAdmin: any = null;
  public userUid: string;
  public isError = false;
  pageActual: number = 1;
  index: number = 0;

  ngOnInit() {
    this.getListState();
    this.getCurrentUser();
  }

  downloadPdf() {
    this.dataApi.getAllState().subscribe( states => {

      states.forEach(element => {
        const doc = new jsPDF();
        doc.text('nombre: '+ " " + element.name, 10,15,);
        doc.save('test.pdf');
      });
    });
  }

  getCurrentUser() {
    // comporbar si el usuario esta logeado
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.userUid = auth.uid;
        this.authService.isUserAdmin(this.userUid).subscribe(userRole => {
            this.isAdmin = Object.assign({}, userRole.roles).hasOwnProperty('admin');
        });
      }
    });
  }

  getListState() {
    this.dataApi.getAllState().subscribe( states => {
      if (states) {
      this.states = states;
      }
      let i = this.index;
      states.forEach(element => {
        if (!element) {
          i = 1;
          this.index = 1;
        } else {
          i = i + 1;
        }
      });
      let total = i + 1;
    });
  }

  onDeleteState(idState: string) {
    const confirmacion = confirm('Estás seguro ?');
    if (confirmacion) {
      this.dataApi.getAllTasksState(idState).subscribe( states => {
        if (states.length > 0 ) {
           this.onisError();
        } else {
          this.dataApi.deleteState(idState);
        }
      });
    }
  }

  // carga el update al modal
  onPreUpdateState(state: StateInterface) {
    // console.log('GROUP: ', group);
    this.dataApi.selectState = Object.assign({}, state);
  }


  onisError(): void {
    this.isError = true;
    setTimeout(() => {
      this.isError = false;
    }, 3000);
  }


}
