import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { DataApiService } from '../../../services/data-api.service';
import { AuthService } from '../../../services/auth.service';
import { NgForm } from '@angular/forms';
import { formatDate } from '@angular/common';
import { format } from 'util';
import Swal from 'sweetalert2';
import { MensajesService } from '../../../services/mensajes.service';


@Component({
  selector: 'app-modal-request',
  templateUrl: './modal-request.component.html',
  styleUrls: ['./modal-request.component.css']
})
export class ModalRequestComponent implements OnInit {

  constructor(public dataApi: DataApiService,
              private msj: MensajesService) {  }
  @ViewChild('btnClose', { static: false}) btnClose: ElementRef;
  @Input() userUid: string; // para pasar al modal el userid logueado
  @Input() name: string; // ndatos del solicitante
  @Input() customersId: string;
  @Input() email: string;
  public  Date: string = new Date().toLocaleString(); // hfecha y hora
  // public  Date: string = new Date().toLocaleDateString(); // solo fecha

  ngOnInit() {

  }

  onSaveRequest(requestForm: NgForm): void {
    // new
    requestForm.value.userUid = this.userUid; // guarda en la coleecion de firebase
    requestForm.value.name = this.name;
    requestForm.value.customersId = this.customersId;
    requestForm.value.Date = this.Date;
    requestForm.value.email = this.email;
    requestForm.value.state = false;
    requestForm.value.detail = 'Web';
    requestForm.value.post = 1;
    this.dataApi.addRequest(requestForm.value);
    this.msj.mensajeCorrecto('Agrego', 'Se agrego correctamente');
    requestForm.resetForm();
    this.btnClose.nativeElement.click(); // cierra el modal
  }

}
